import React from 'react'
import { connect } from 'react-redux'
import { showEditExperienceModal } from '../../../actions/modals'
import { updateReservationStatus } from '../../../actions/host'

const UpdateStatus = ({ children, data, updateStatus, className }) => (
  <React.Fragment>
    <span
      className="pause_reservation"
      onClick={() => updateStatus(data)}
      // disabled={item.seatsBooked}
    >
      {children}
    </span>
  </React.Fragment>
)

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = dispatch => ({
  updateStatus: async data => {
    await dispatch(updateReservationStatus(data))
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(UpdateStatus)
