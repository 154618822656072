import React from "react"

const SvgPaypalIcon = props => (
  <svg viewBox="0 0 65.611 40.986" {...props}>
    <defs>
      <style>{`.paypal-2{fill:#369}.paypal-3{fill:#036}`}</style>
    </defs>
    <g id="paypal-icon">
      <path
        id="Path_280"
        data-name="Path 280"
        d="M65.558 151.691a2.209 2.209 0 0 1-2.2 2.2H2.2a2.209 2.209 0 0 1-2.2-2.2V115.1a2.209 2.209 0 0 1 2.2-2.2h61.215a2.209 2.209 0 0 1 2.2 2.2v36.6z"
        transform="translate(0 -112.9)"
        fill="#f9f9f9"
      />
      <g
        id="Group_128"
        data-name="Group 128"
        transform="translate(13.164 16.207)"
      >
        <path
          id="Path_281"
          data-name="Path 281"
          className="paypal-2"
          d="M81.7 147.466a4.353 4.353 0 0 0-1.934-.366 11.007 11.007 0 0 0-1.307.1c-.314.052-.366.052-.575.1a.644.644 0 0 0-.471.523l-.157.523c-.052.366.1.314.209.314.157-.052.261-.1.627-.209a3.9 3.9 0 0 1 1.046-.157 2.457 2.457 0 0 1 .993.157.5.5 0 0 1 .314.47v.157c0 .052-.052.1-.157.1-.627.052-1.046.1-1.621.157a5.577 5.577 0 0 0-1.412.366 2.277 2.277 0 0 0-.941.732A1.988 1.988 0 0 0 76 151.6a1.316 1.316 0 0 0 .471 1.046 1.722 1.722 0 0 0 1.15.418 2.359 2.359 0 0 0 .68-.052c.157-.052.314-.1.523-.157a2.119 2.119 0 0 0 .471-.261c.157-.1.261-.157.418-.261l-.052.157c-.052.209.1.366.314.418h.993a.556.556 0 0 0 .471-.418l.784-3.45c0-.1.052-.157.052-.261v-.261a1.062 1.062 0 0 0-.575-1.052zm-1.934 4.13a1.565 1.565 0 0 1-.418.209 1.226 1.226 0 0 1-.575.1 1.95 1.95 0 0 1-.627-.1c-.157-.052-.209-.209-.209-.418a.781.781 0 0 1 .157-.523.761.761 0 0 1 .471-.314c.209-.052.418-.1.732-.157.261-.052.732-.1.784-.1s.1-.052.052.157c0 .052-.157.68-.209.941-.003.048-.108.153-.16.209z"
          transform="translate(-49.432 -145.427)"
        />
        <path
          id="Path_282"
          data-name="Path 282"
          className="paypal-3"
          d="M55.95 147.252a1.068 1.068 0 0 0-.68.418s-1.568 2.666-1.725 2.928c-.1.157-.157.052-.209 0 0-.052-.471-2.928-.471-2.928a.631.631 0 0 0-.575-.418h-.99a.312.312 0 0 0-.314.418s.732 4.235.889 5.228c.052.575 0 .627 0 .627l-.941 1.673c-.157.209-.052.418.157.418h1.15a1.068 1.068 0 0 0 .68-.418l4.339-7.371s.418-.627.052-.627c-.314.052-1.359.052-1.359.052"
          transform="translate(-37.433 -145.475)"
        />
        <path
          id="Path_283"
          data-name="Path 283"
          className="paypal-3"
          d="M30 147.194a2.1 2.1 0 0 1-1.359.366h-.261a.312.312 0 0 1-.314-.418l.314-1.412a.563.563 0 0 1 .523-.418h.366a1.572 1.572 0 0 1 .941.209.745.745 0 0 1 .314.627 1.555 1.555 0 0 1-.524 1.046m2.353-2.2a1.662 1.662 0 0 0-.575-.627 3.926 3.926 0 0 0-.941-.366 8.814 8.814 0 0 0-1.307-.1h-2.3a.563.563 0 0 0-.523.418l-1.516 6.692a.335.335 0 0 0 .314.418h1.1a.621.621 0 0 0 .523-.418l.366-1.621a.563.563 0 0 1 .523-.418h.314a5.081 5.081 0 0 0 3.137-.836 2.516 2.516 0 0 0 1.1-2.143 1.869 1.869 0 0 0-.209-.993"
          transform="translate(-25.181 -143.9)"
        />
        <path
          id="Path_284"
          data-name="Path 284"
          className="paypal-2"
          d="M67.9 147.194a2.1 2.1 0 0 1-1.359.366h-.261a.312.312 0 0 1-.314-.418l.314-1.412a.563.563 0 0 1 .523-.418h.366a1.572 1.572 0 0 1 .941.209.745.745 0 0 1 .314.627 1.358 1.358 0 0 1-.523 1.046m2.353-2.2a2.229 2.229 0 0 0-.575-.627 3.926 3.926 0 0 0-.941-.366 8.813 8.813 0 0 0-1.307-.1h-2.3a.563.563 0 0 0-.523.418l-1.516 6.692a.335.335 0 0 0 .314.418h1.1a.621.621 0 0 0 .523-.418l.366-1.621a.621.621 0 0 1 .523-.418h.314a5.081 5.081 0 0 0 3.137-.836 2.582 2.582 0 0 0 1.1-2.143 1.99 1.99 0 0 0-.209-.993"
          transform="translate(-43.267 -143.9)"
        />
        <path
          id="Path_285"
          data-name="Path 285"
          className="paypal-3"
          d="M43.8 147.466a4.353 4.353 0 0 0-1.934-.366 11.007 11.007 0 0 0-1.307.1c-.314.052-.366.052-.575.1a.644.644 0 0 0-.471.523l-.157.523c-.052.366.1.314.209.314.157-.052.261-.1.627-.209a3.9 3.9 0 0 1 1.046-.157 2.457 2.457 0 0 1 .993.157.5.5 0 0 1 .314.47v.157c0 .052-.052.1-.157.1-.627.052-1.046.1-1.621.157a5.576 5.576 0 0 0-1.412.366 2.277 2.277 0 0 0-.941.732 1.988 1.988 0 0 0-.314 1.15 1.316 1.316 0 0 0 .471 1.046 1.723 1.723 0 0 0 1.15.418 2.359 2.359 0 0 0 .68-.052c.157-.052.314-.1.523-.157a2.119 2.119 0 0 0 .471-.261c.157-.1.261-.157.418-.261l-.052.157c-.052.209.1.366.314.418h.993a.638.638 0 0 0 .471-.418l.784-3.45c0-.1.052-.157.052-.261v-.261a1.205 1.205 0 0 0-.575-1.035zm-1.934 4.13a1.565 1.565 0 0 1-.418.209 1.226 1.226 0 0 1-.575.1 1.95 1.95 0 0 1-.627-.1c-.157-.052-.209-.209-.209-.418a.781.781 0 0 1 .157-.523.761.761 0 0 1 .471-.314c.209-.052.418-.1.732-.157.261-.052.732-.1.784-.1s.1-.052.052.157c0 .052-.157.68-.209.941-.003.048-.108.153-.16.209z"
          transform="translate(-31.346 -145.427)"
        />
        <g
          id="Group_126"
          data-name="Group 126"
          transform="translate(33.041 .105)"
        >
          <g id="Group_125" data-name="Group 125">
            <path
              id="Path_286"
              data-name="Path 286"
              className="paypal-2"
              d="M91.371 144.1h-.993a.629.629 0 0 0-.523.366v.052a.915.915 0 0 1-.052.261l-1.359 6.064a.915.915 0 0 0-.052.261c-.052.209.1.366.314.418h.994a.629.629 0 0 0 .523-.366v-.052l1.464-6.639a.294.294 0 0 0-.316-.365z"
              transform="translate(-88.381 -144.1)"
            />
          </g>
        </g>
        <g
          id="Group_127"
          data-name="Group 127"
          transform="translate(36.815 .052)"
        >
          <path
            id="Path_287"
            data-name="Path 287"
            className="paypal-2"
            d="M96.28 145.359h-.209a.357.357 0 0 1-.366-.366v-.575h-.1v-.157h.1V144h.157v.314h.366v.157h-.366v.575c0 .052 0 .052.052.1l.052.052h.261v.157z"
            transform="translate(-95.6 -144)"
          />
          <path
            id="Path_288"
            data-name="Path 288"
            className="paypal-2"
            d="M98.916 145.55h-.157v-.784c0-.052 0-.052-.052-.1a.051.051 0 0 0-.052-.052h-.1a.192.192 0 0 0-.157.052c-.052 0-.1.052-.157.1v.784h-.161v-.784c0-.052 0-.052-.052-.1a.051.051 0 0 0-.052-.052h-.1a.192.192 0 0 0-.157.052c-.052 0-.1.052-.157.1v.784H97.4v-1.05h.157v.1c.052-.052.1-.1.157-.1s.1-.052.157-.052a.314.314 0 0 1 .209.052c.052.052.1.052.1.1.052-.052.157-.1.209-.157s.1-.052.209-.052a.4.4 0 0 1 .261.1.442.442 0 0 1 .1.314v.732z"
            transform="translate(-96.459 -144.191)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default SvgPaypalIcon
