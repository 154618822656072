// https://stackoverflow.com/questions/35623656/how-can-i-display-a-modal-dialog-in-redux-that-performs-asynchronous-actions/35641680

import {
  UPDATE_STEP,
  AUTH,
  REG,
  BECOME_HOST,
  EXPERIENCE,
  RESERVATION,
  HOST_LOCATION_FORM,
  ACCOUNT,
  HOST_REVIEW,
  GUEST_REVIEW,
  JUST_REGISTER
} from '../constants/actionTypes'

export function updateHostFormStep(step) {
  return {
    type: UPDATE_STEP,
    formType: BECOME_HOST,
    page: step
  }
}

export function updateExperienceFormStep(step) {
  return {
    type: UPDATE_STEP,
    formType: EXPERIENCE,
    page: step
  }
}

export function updateHostLocationFormStep(step) {
  return {
    type: UPDATE_STEP,
    formType: HOST_LOCATION_FORM,
    page: step
  }
}

export function updateRegFormStep(step) {
  return {
    type: UPDATE_STEP,
    formType: REG,
    page: step
  }
}

export function updateForJustReg(flag) {
  return {
    type: JUST_REGISTER,
    payload: flag
  }
}

export function updateAuthFormStep(step) {
  return {
    type: UPDATE_STEP,
    formType: AUTH,
    page: step
  }
}

export function updateReservationFormStep(step) {
  return {
    type: UPDATE_STEP,
    formType: RESERVATION,
    page: step
  }
}

export function updateAccountFormStep(step) {
  return {
    type: UPDATE_STEP,
    formType: ACCOUNT,
    page: step
  }
}

export function updateHostReviewFormStep(step) {
  return {
    type: UPDATE_STEP,
    formType: HOST_REVIEW,
    page: step
  }
}

export function updateGuestReviewFormStep(step) {
  return {
    type: UPDATE_STEP,
    formType: GUEST_REVIEW,
    page: step
  }
}
