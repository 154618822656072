import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'

import seoMeta from '../../../utils/seo-meta.json'

const defaultTitle = 'Breakbread - Meet. Eat. Connect.'
const defaultDescription = 'Food experiences that feel just like home'
const defaultImage = 'https://static.breakbread.com/bb_social.jpg'

const trackPageView = ({ title, currentUser }) => {
  const url = window.location.href
  const path = window.location.pathname

  if (window && window.dataLayer) {
    const { dataLayer } = window
    const pageViewPayload = {
      // event: 'VirtualPageView',
      virtualPageUrl: url || 'https://breakbread.com',
      virtualPageTitle: title || 'Breakbread - Meet. Eat. Connect.',
      event: 'page_view',
      url,
      path,
      title
    }
    if (currentUser && currentUser.attributes && currentUser.attributes.id) {
      const { attributes } = currentUser
      _.set(pageViewPayload, 'userId', attributes.id)
      _.set(pageViewPayload, 'email', attributes.email)
      _.set(pageViewPayload, 'is_login', currentUser.isSignedIn)
      _.set(pageViewPayload, 'language', _.get(attributes, 'languages[0].code', 'en'))
      _.set(pageViewPayload, 'type', attributes.role)
    }

    dataLayer.push(pageViewPayload)
  } else {
    console.log('window or dataLater does not exist, cannot push pageview event.')
  }
}

class SEO extends React.Component {
  componentDidMount() {
    const { title, currentUser } = this.props
    // setTimeout(() => {
    trackPageView({ title, currentUser })
    // }, 2000)
  }

  render() {
    const { title, description, image } = this.props
    const {
      og_site_name: siteName, og_locale: locale, og_type: type
    } = seoMeta.generic

    let pageTitle = title
    if (title !== defaultTitle) {
      pageTitle = `${title} | Breakbread`
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={description} />

          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={window.location} />

          <meta property="og:site_name" content={siteName} />
          <meta property="og:locale" content={locale} />
          <meta property="og:type" content={type} />
          <meta property="og:image" content={image} />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content="@breakbreaddubai" />
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={image} />
        </Helmet>
      </React.Fragment>
    )
  }
}

SEO.defaultProps = {
  title: defaultTitle,
  description: defaultDescription,
  image: defaultImage
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  currentUser: PropTypes.shape({}).isRequired,
  image: PropTypes.string
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser
})

export default connect(mapStateToProps)(SEO)
