import {
  validateStringValues,
  validateSelect,
  nestedPresent,
  photoPresent
} from '../../../utils/validation'

import {requiredExperience} from '../../../constants'

const validate = values => {
  const errors = {}
  if (typeof values === 'undefined') {
    return errors
  }

  if (!values.firstName) {
    errors.firstName = 'Required'
  }
  if (!values.lastName) {
    errors.lastName = 'Required'
  }
  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }

  if (!values.gender) {
    errors.gender = 'Required'
  }
  if (typeof values.gender === 'object' && values.gender !== null) {
    if (values.gender.length === 0) {
      errors.gender = 'Required'
    }
  }
  if (!values.languages) {
    errors.languages = 'Required'
  }
  if (typeof values.languages === 'object' && values.languages !== null) {
    if (values.languages.length === 0) {
      errors.languages = 'Required'
    }
  }

  if (!values.preferred_currency) {
    errors.preferred_currency = 'Required'
  }
  /*
  if (!values.phone_number) {
    errors.phone_number = 'Required';
  }*/

  if (!values.Currency) {
    errors.Currency = 'Required'   
  }

  if (!values.streetAddress) {
    errors.streetAddress = 'Required'
  }

  if (!values.city) {
    errors.city = 'Required'
  }

  /* if (!values.postalCode) {
    errors.postalCode = 'Required';
  } */

  if (!values.countryCode) {
    errors.countryCode = 'Required'
  }

  // if (!values.destinationLink) {
  //   errors.destinationLink = 'Required'
  // }
  /*
  if (!values.phoneNumber) {
    errors.phoneNumber = 'Required';
  }*/

  if (!values.description) {
    errors.description = 'Required'
  }
  if (!values.summary) {
    errors.summary = 'Required'
  }

  if (!values.specialties) {
    errors.specialties = 'Required'
  }
  if (typeof values.specialties === 'object' && values.specialties !== null) {
    if (values.specialties.length === 0) {
      errors.specialties = 'Select Cuisine / Specialties !'
    }
  }

  if (!values.culinaryJourney || values.culinaryJourney.length < 500) {
    errors.culinaryJourney = 'Give us a brief background of your culinary journey and past experience and it should be have more than 500 character!'
  }

  // Social Links
  
  if (typeof values.socialLinks === "undefined" || values.socialLinks == null ||( values.socialLinks && Object.keys(values.socialLinks).length === 0)) {
    
    errors.socialLinks = 'Required'
  }

  if (typeof values.socialLinks === "undefined" || values.socialLinks == null ||(
    values.socialLinks &&
    (!values.socialLinks.facebook &&
      !values.socialLinks.twitter &&
      !values.socialLinks.instagram))
  ) {
    
    errors.socialLinks = {
      ...errors.socialLinks,
      facebook: 'At least one social network link is required',
      instagram: 'At least one social network link is required',
      twitter: 'At least one social network link is required'
    }
  }

  if (!values.location) {
    const locationErrors = {}
    // locationErrors.postalCode = 'Required';
    locationErrors.countryCode = 'Required'
    locationErrors.streetAddress = 'Required'
    locationErrors.city = 'Required'
  } else {
    const locationErrors = {}

    /* if (!values.location.postalCode) {
      locationErrors.postalCode = 'Postal Code is Required';
    } */

    if (!values.location.streetAddress) {
      locationErrors.streetAddress = 'Street Address is Required'
    }

    if (!values.location.city) {
      locationErrors.city = 'City is Required'
    }

    if (locationErrors) {
      errors.location = locationErrors
    }
  }

  if (!values.birthday) {
    errors.birthday = 'Required'
  }

  if (!values.hostingLocationStreetAddress) {
    errors.hostingLocationStreetAddress = 'Required'
  }

  if (!values.hostAddressFieldsCity) {
    errors.hostAddressFieldsCity = 'Required'
  }

  /* if (!values.hostAddressFieldsPostalCode) {
    errors.hostAddressFieldsPostalCode = 'Required';
  } */

  if (!values.hostAddressFieldsCountryCode) {
    errors.hostAddressFieldsCountryCode = 'Required'
  }

  if (!values.locationPhotos || !values.locationPhotos.length) {
    errors.locationPhotos = 'Required'
  }

  // if (!values.menuAttributes.menuItemsAttributes || !values.menuAttributes.menuItemsAttributes.length > 3) {
  //   errors.menuItemsAttributes = 'Required';
  // }

  return errors
}



const validateProfessional = values => {
  const errors = {}

  if (typeof values === 'undefined') {
    return errors
  }

  if (!values.specialties) {
    errors.specialties = 'Required'
  }
  if (typeof values.specialties === 'object' && values.specialties !== null) {
    if (values.specialties.length === 0) {
      errors.specialties = 'Select Cuisine / Specialties !'
    }
  }

  if (!values.culinaryJourney || !values.culinaryJourney.length > 0) {
    errors.culinaryJourney = 'Give us a brief background of your culinary journey and past experience!'
  }

  // Social Links
  
  if (typeof values.socialLinks === "undefined" || values.socialLinks == null ||( values.socialLinks && Object.keys(values.socialLinks).length === 0)) {
    
    errors.socialLinks = 'Required'
  }

  if (typeof values.socialLinks === "undefined" || values.socialLinks == null ||(
    values.socialLinks &&
    (!values.socialLinks.facebook &&
      !values.socialLinks.twitter &&
      !values.socialLinks.instagram))
  ) {
    
    errors.socialLinks = {
      ...errors.socialLinks,
      facebook: 'At least one social network link is required',
      instagram: 'At least one social network link is required',
      twitter: 'At least one social network link is required'
    }
  }
 
  if (values.socialLinks && (values.socialLinks.facebook ||
      values.socialLinks.twitter ||
      values.socialLinks.instagram)
  ) {
    if (values.socialLinks.facebook && !validURL(values.socialLinks.facebook)) {
      errors.socialLinks = {
        ...errors.socialLinks,
        facebook: 'Please enter valid url'
      }
    }
    if (values.socialLinks.instagram && !validURL(values.socialLinks.instagram)) {
      errors.socialLinks = {
        ...errors.socialLinks,
        instagram: 'Please enter valid url'
      }
    }
    if (values.socialLinks.twitter && !validURL(values.socialLinks.twitter)) {
      errors.socialLinks = {
        ...errors.socialLinks,
        twitter: 'Please enter valid url'
      }
    }
  }
  
  return errors
}

const validURL = (str) => {
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
  return !!pattern.test(str)
}

const validateExperience = values => {
  let errors = {}
  let menuErrors = {}


  // const stringValues = ['title', 'date', 'price', 'description', 'summary'];
  // errors = validateStringValues(stringValues, values, errors);
  //
  // const singleSelects = ['time', 'currency', 'seats'];
  // errors = validateSelect(singleSelects, values, errors);

  const multiSelects = ['cuisineTypesAttributes']
  errors = validateSelect(multiSelects, values, errors)

  /* On Nested Attributes */
  if (nestedPresent(values.menuAttributes, 'menuAllergensAttributes')) {
    if (!values.menuAttributes.menuAllergensAttributes.length) {
      menuErrors = validateSelect(
        ['menuAllergensAttributes'],
        values,
        menuErrors
      )
    }
  }

  /* On Nested Attributes */
  // if (!nestedPresent(values.menuAttributes, 'menuItemsAttributes')) {
  //   menuErrors = {
  //     ...menuErrors,
  //     menuItemsAttributes: 'At least three menu item is required',
  //   };

  // }

  if (menuErrors) {
    errors.menuAttributes = menuErrors
  }

  if (nestedPresent(values.menuAttributes, 'menuItemsAttributes')) {
    const menuItemsArrayErrors = []
    values.menuAttributes.menuItemsAttributes.forEach(
      (menuItem, menuItemIndex) => {
        let menuItemErrors = {}

        // Validate all strings Fields
        menuItemErrors = validateStringValues(
          ['title', 'description'],
          menuItem,
          menuItemErrors
        )

        // Validate all select Fields
        menuItemErrors = validateSelect(['course'], menuItem, menuItemErrors)

        /* if (!photoPresent(menuItem.menu_item_photo)) {
          const message = {
            _error: 'Dish Image is missing'
          }
          menuItemErrors.menu_item_photo = message
        } */

        menuItemsArrayErrors[menuItemIndex] = menuItemErrors
      }
    )
    if (
      menuItemsArrayErrors.length &&
      typeof errors.menuAttributes !== 'undefined'
    ) {
      errors.menuAttributes.menuItemsAttributes = menuItemsArrayErrors
    }
  }

  if (values && values.menuAttributes && (!values.menuAttributes.menuItemsAttributes || values.menuAttributes.menuItemsAttributes.length < requiredExperience)) {
    menuErrors = {
      ...menuErrors,
      menuItemsAttributes: `At least ${requiredExperience} menu item${requiredExperience>1?'s':''} is required`
    }
  }
 
  if (menuErrors) {
    errors.menuAttributes = menuErrors
  }
  return errors
 
}

export { validate, validateExperience,validateProfessional }
