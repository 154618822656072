const validate = values => {
  const errors = {}

  if (!values.firstName) {
    errors.firstName = 'Please enter first/given name!'
  }
  if (!values.lastName) {
    errors.lastName = 'Please enter last/family name!'
  }
  if (!values.phoneCountryCode) {
    errors.phoneCountryCode = 'Please enter country code!'
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = 'Please enter phone number!'
  }

  if (!values.email) {
    errors.email = 'Please enter a valid email address!'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Please enter a valid email address!'
  }

  if (values.password != undefined) {
    const length = values.password.length >= 8
    let isCapitalcase = false
    let isLowercase = false
    let isNumber = false

    Object.keys(values.password).forEach(key => {
      const ch = values.password[key]
      if (ch >= 0 && ch <= 9) {
        isNumber = true
      } else if (ch.charCodeAt() >= 65 && ch.charCodeAt() <= 90) {
        isCapitalcase = true
      } else if (ch.charCodeAt() >= 97 && ch.charCodeAt() <= 122) {
        isLowercase = true
      }
    })

    if (!(isNumber && isLowercase && isCapitalcase && length)) {
      errors.password =
        'Your password must be at least 8 characters long, contain at least one number, one lowercase & uppercase letter!'
    }
  } else {
    errors.password =
      'Your password must be at least 8 characters long, contain at least one number, one lowercase & uppercase letter!'
  }

  // if (!values.passwordConfirmation) {
  //   errors.passwordConfirmation = 'Required';
  // } else if (values.passwordConfirmation !== values.password) {
  //   errors.passwordConfirmation = 'Passwords need to match';
  // }

  return errors
}

export default validate
