import TweenLite from 'gsap/TweenLite'

export function calcNavHeight({ $body, $navbar, $announcementBar }) {
  const navfixed = $body.hasClass('navbar-is-fixed')
  const nh = $navbar.outerHeight()
  const ah = $announcementBar.outerHeight()
  let calcHeight = nh
  if ($announcementBar.length > 0 && !navfixed) {
    calcHeight = nh + ah
  }
  return calcHeight
}

function initNavbarScroll() {
  const $body = $('body')
  const $navbar = $('#mainNav')
  const $announcementBar = $('[data-banner-location=top]')
  let topOffset = $navbar.outerHeight()
  let navResizeTime
  /* eslint-disable-next-line no-unused-vars */
  let scrollPos
  let scrollTime

  let scrollToClear = calcNavHeight({ $body, $navbar, $announcementBar }) * 2

  // Navbar
  $(window).resize(() => {
    clearTimeout(navResizeTime)
    navResizeTime = setTimeout(() => {
      scrollToClear = calcNavHeight({ $body, $navbar, $announcementBar }) * 2
      topOffset = $navbar.outerHeight()
    }, 100)
  })

  // NOTE:  Simple Navbar Scroll
  $(window).scroll(() => {
    clearTimeout(scrollTime)
    /* eslint-disable-next-line */
    const currentScroll = $(window).scrollTop();

    if ($(window).scrollTop() > scrollToClear) {
      $navbar.addClass('navbar-fixed-top')
      if ($navbar.hasClass('-transparent')) {
        $navbar
          .addClass('-default -was-transparent')
          .removeClass('-transparent')
      }
      TweenLite.to(document.getElementById('mainNav'), 0.5, {
        y: topOffset - 5
      })
      $body.addClass('navbar-is-fixed')
    }

    if ($(window).scrollTop() < scrollToClear) {
      $navbar.removeClass('navbar-fixed-top')
      if ($navbar.hasClass('-was-transparent')) {
        $navbar
          .removeClass('-default -was-transparent')
          .addClass('-transparent')
      }
      TweenLite.to(document.getElementById('mainNav'), 0.5, {
        y: 0
      })
      $body.removeClass('navbar-is-fixed')
    }
    scrollTime = setTimeout(() => {
      scrollPos = $(window).scrollTop()
    }, 100)
  })
}
export default function navbarScroll() {
  $(document).ready(() => {
    setTimeout(() => {
      initNavbarScroll()
    }, 3000)
  })
}
