import PropTypes from 'prop-types'

const inputPropTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    autoComplete: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool
  }),
  input: PropTypes.shape({}).isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  displayLabel: PropTypes.bool,
  meta: PropTypes.shape({}).isRequired
}

export { inputPropTypes as default }
