import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ChefAvtarHost from '../Chef/ChefAvtarHost'
import ChefInfo from '../Chef/ChefInfo'
import moment from 'moment'
// import ChefAvatarImage from '../../images/placeholders/chef-avatar@2x.png'

export default class ExperienceCard extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const {
      experience,
      featured,
      hideAvatar,
      forceHoverState
      // displaySoldStatus
    } = this.props
    // const now = new Date()
    // const hasPassed = experience.date < now.toISOString()
    let extraClassNames = ''
    extraClassNames += featured ? ' experience-card--featured' : ''
    extraClassNames += forceHoverState ? ' grid-view-item--force-hover' : ''
    extraClassNames += hideAvatar ? ' experience-card--avatar-hidden' : ''
    experience.readableTime = experience.time
      ? experience.time
      : experience.readableTime

    // let statusClass = 'badge '

    // switch (experience.status) {
    // case 'approved':
    //   statusClass = 'badge badge-success'
    //   break
    // case 'paused':
    //   statusClass = 'badge badge-error'
    //   break
    // case 'rejected':
    //   statusClass = 'badge badge-rejected'
    //   break
    // case 'submitted':
    //   statusClass = 'badge badge-pending'
    //   break

    // default:
    //   statusClass = 'badge badge-pending'
    //   break
    // }

    let experienceStatusForRender = ''
    if (experience.status === 'paused') {
      experienceStatusForRender = 'Paused'
    } else if (experience.featured) {
      experienceStatusForRender = 'Featured'
    }
    // ) : experience.featured ? (
    //   'Featured'
    // ) : (
    //   ''
    // )
    let inPast = false
    if(experience.dateWithTime) {
      if (moment(experience.dateWithTime).isBefore(moment(new Date()).utc())) {
        inPast = true
      }
    } else {
      inPast = true
    }

    let startedDate = moment.utc(new Date())

    let schedules = experience.schedules.filter((el) => {
      if (moment(`${el.date}T${experience.time}Z`).isBefore(startedDate) || el.status !== 'active') {
        return false
      }
      return true
    })

    let scheduleTime = '';
    if (schedules && schedules.length > 0) {
      const upCommingSchedules = schedules.sort((a, b) => new Date(a.scheduleTime) - new Date(b.scheduleTime))

      if (upCommingSchedules.length < 1) {
        scheduleTime = 'Comming Soon';
      } else if(upCommingSchedules.length > 1) {
        scheduleTime = `${moment(upCommingSchedules[0].date).format('ddd Do MMM')} & more dates`;
      } else {
        scheduleTime = `${moment(upCommingSchedules[0].date).format('ddd Do MMM')}`;
      }
    }
    return (
      <React.Fragment>
        <div className={`experience-card grid-view-item ${extraClassNames}`}>
          <Link to={`/experiences/${experience.slug}`} style={{ textDecoration: 'none' }}>
            <div className="experience-card__image--container grid-view-item__link grid-view-item__image-container">
              <div className="grid-view-item__image-wrapper js">
                <div id="" className="grid-view-item__image-wrapper js">
                  <div className="grid-view-item__image-container">
                    <div
                      className="grid-view-item__overlay box ratio-container lazyload js"
                      // NOTE: NEED TO ADD LAZY lOAD SUPPORT
                      data-sizes="auto"
                      data-parent-fit="cover"
                      style={{
                        backgroundImage: `url("${experience.image}")`
                      }}
                    />

                    <div className="experience-card__hover-meta grid-view-item_hover-el">
                      <span>{experience.summary}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/experiences/${experience.slug}`} style={{ textDecoration: 'none' }}>
            <div className="experience-card__meta grid-view-item__meta">
              <div className="experience-card__high-level">
                <div className="experience-card__location">
                  {experience.location ? experience.location.city : ''}
                </div>
                <div className="experience-card__tools text-uppercase">
                  <b>
                    {experienceStatusForRender}
                  </b>
                  {experience.status === 'submitted' ? (
                    <span className="experience-card__location" style={{width: '100%', padding:'3px 0px'}}>
                          Coming Soon
                    </span>
                  ) : ''}
                  {experience.status === 'approved' && !inPast ? (
                    <span className="experience-card__location" style={{width: '100%', padding:'3px 0px 3px 42px'}}>
                          Live
                    </span>
                  ) : ''}
                </div>
              </div>
              {!hideAvatar && (
                <ChefAvtarHost displayInfo={false} person={experience.host} />
              )}
              <div className="experience-card__text grid-view-item__text " >
                {!hideAvatar && (
                  <ChefInfo
                    displaySpecialties={false}
                    person={experience.host}
                  />
                )}

                <div className="experience-card__title grid-view-item__title">
                  <h5>{experience.title}</h5>
                  <big className=" text-secondary text-sm">{scheduleTime}</big>
                </div>

                {experience.cuisineTypes
                  && experience.cuisineTypes.map((specialty, i) => (
                    <small className=" text-primary" key={specialty.id}>
                      {specialty.name}
                      {experience.cuisineTypes.length - 1 !== i && (
                        <span>, </span>
                      )}
                    </small>
                  ))}
                {/* experience.seatsAvailable || displaySoldStatus ? (
                    ''
                  ) : (
                    <div className="experience-card__title grid-view-item__title">
                      <h5>
                        <div>
                          <h4>Sold Out 2</h4>
                        </div>
                      </h5>
                    </div>
                  ) */}
              </div>
            </div>
            {/* <div className="experience-card__reservation-info group">
              <div className="experience-card__date">
                {experience.experienceType?(<p>
                }
                  {moment(
                    experience.time,
                    'HH:mm'
                  ).format('h:mm A')}
                </p>):''}
              </div>
              <div className="experience-card__price">
                {experience.currency} <span>{experience.price}</span>
              </div>
            </div> */}
            <div className="experience-card__reservation-info group ">
              {/* <div className="experience-card__price">
                Cost
                <br/>
                <p>
                  {experience.price} {experience.currency}
                </p>
              </div> */}
              {/* <div className="experience-card__reservation-info group"> */}
              {/* <div className="experience-card__date">
                {hasPassed ? (
                  <p>
                    This experience<br />has already passed
                  </p>
                ) : (
                  <p>
                    {experience.readableDate} <br /> {experience.time}
                  </p>
                )}
              </div> */}
              <div className="experience-card__price">
                {experience.currency}
                <p>{experience.price}</p>
              </div>
              <div
                className="experience-card__date"
                style={{ borderLeft: 'none' }}
              >
                Type{' '}
                {/* <br/> */}
                { experience.experienceType ? (
                  <p>
                    {experience.experienceType.name}
                  </p>
                ) : ''}
              </div>
              {/* </div> */}
              {/* <div className="experience-card__price">
                Status{' '}<br/>
                <p
                  className={statusClass}
                  style={{
                    fontSize: '14px',
                    margin: '5px 5px 0 0',
                    padding: '7px',
                    color: 'white'
                  }}
                >
                  {experience.status == 'submitted'
                    ? 'Pending'
                    : experience.status.charAt(0).toUpperCase() + experience.status.slice(1) }
                </p>
              </div> */}
              {/* <div
                className="experience-card__price"
                style={{ borderLeft: 'none' }}
              >
                Guests{' '}
                <br/>
                <p>
                  {`${experience.seats - experience.seatsAvailable}/${
                    experience.seats
                  }`}{' '}
                </p>
              </div> */}
            </div>
          </Link>
        </div>
      </React.Fragment>
    )
  }
}

ExperienceCard.defaultProps = {
  featured: false,
  hideAvatar: false,
  forceHoverState: false
}

ExperienceCard.propTypes = {
  experience: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    host: PropTypes.shape({
      fullname: PropTypes.string,
      avatarUrl: PropTypes.string,
      specialties: PropTypes.arrayOf(PropTypes.shape({})).isRequired
    })
  }).isRequired,
  featured: PropTypes.bool,
  hideAvatar: PropTypes.bool,
  forceHoverState: PropTypes.bool
}
