import React from "react"

const SvgAmericanExpressIcon = props => (
  <svg viewBox="0 0 65.611 40.987" {...props}>
    <defs>
      <style>
        {`.americanexpress-2{fill:#fff}.americanexpress-3{fill:#0078a9}`}
      </style>
    </defs>
    <g id="american-express-icon">
      <path
        id="Path_353"
        data-name="Path 353"
        d="M227.858 603.091a2.209 2.209 0 0 1-2.2 2.2H164.5a2.209 2.209 0 0 1-2.2-2.2V566.5a2.209 2.209 0 0 1 2.2-2.2h61.219a2.209 2.209 0 0 1 2.2 2.2v36.6z"
        transform="translate(-162.3 -564.3)"
        fill="#f9f9f9"
      />
      <g
        id="Group_132"
        data-name="Group 132"
        transform="translate(7.058 10.926)"
      >
        <path
          id="Path_354"
          data-name="Path 354"
          className="americanexpress-2"
          d="M189.079 593.826v-.68l-.314.68h-2.719l-.366-.68v.68h-5.07l-.575-1.412h-.993l-.627 1.412H175.8v-4.287l1.934-4.339h3.712l.523 1.255v-1.2h4.5l.993 2.091.993-2.091h14.272a2.824 2.824 0 0 1 1.255.314v-.314h3.607v.471a2.886 2.886 0 0 1 1.621-.471h6.326l.575 1.255v-1.255h4.078l.732 1.255v-1.255h3.869v8.678h-3.973l-.941-1.516v1.516h-4.967l-.68-1.568h-.993l-.68 1.568h-2.98a3.611 3.611 0 0 1-1.934-.627v.627h-6.117v-2.039c0-.314-.209-.261-.209-.261h-.216v2.3h-12.021z"
          transform="translate(-175.8 -585.2)"
        />
        <path
          id="Path_355"
          data-name="Path 355"
          className="americanexpress-2"
          d="M195.5 605.3h7.842l1.15 1.307 1.15-1.307h6.012a2.38 2.38 0 0 1 1.1.314v-.314h5.385a2.62 2.62 0 0 1 1.255.366v-.366h6.9v.314a2.246 2.246 0 0 1 1.15-.314h4.5v.314a2.786 2.786 0 0 1 1.15-.314h3.555v8.156a1.954 1.954 0 0 1-1.412.523h-4.657v-.261a2.22 2.22 0 0 1-1.1.261h-12.39v-2.091c0-.209-.1-.261-.261-.261h-.157v2.353H212.8v-2.353a2.774 2.774 0 0 1-1.15.209h-1.412v2.091h-4.757l-1.1-1.307-1.15 1.307H195.5z"
          transform="translate(-185.201 -594.792)"
        />
        <path
          id="Path_356"
          data-name="Path 356"
          className="americanexpress-3"
          d="M242.8 607.8h4.967v1.307h-3.45v1.15h3.346v1.15h-3.346v1.2h3.45v1.307H242.8z"
          transform="translate(-207.773 -595.985)"
        />
        <path
          id="Path_357"
          data-name="Path 357"
          className="americanexpress-3"
          d="M267.494 610.153c1.83.1 1.987 1.046 1.987 1.987a1.8 1.8 0 0 1-1.934 1.778h-3.189v-1.308h2.457c.418 0 1.046 0 1.046-.575 0-.261-.1-.47-.575-.523a7.733 7.733 0 0 0-1.046-.052c-1.673-.052-2.039-.889-2.039-1.882a1.673 1.673 0 0 1 1.777-1.778h3.189v1.307h-2.3c-.523 0-1.1-.052-1.1.523 0 .366.261.47.627.47a8.1 8.1 0 0 0 1.1.053z"
          transform="translate(-217.985 -595.985)"
        />
        <path
          id="Path_358"
          data-name="Path 358"
          className="americanexpress-3"
          d="M256.594 610.153c1.83.1 1.987 1.046 1.987 1.987a1.8 1.8 0 0 1-1.934 1.778h-3.189v-1.308h2.457c.418 0 1.046 0 1.046-.575 0-.261-.1-.47-.575-.523a7.733 7.733 0 0 0-1.046-.052c-1.673-.052-2.039-.889-2.039-1.882a1.673 1.673 0 0 1 1.777-1.778h3.189v1.307h-2.3c-.523 0-1.1-.052-1.1.523 0 .366.261.47.627.47a8.1 8.1 0 0 0 1.1.053z"
          transform="translate(-212.783 -595.985)"
        />
        <path
          id="Path_359"
          data-name="Path 359"
          className="americanexpress-3"
          d="M212.038 607.752h-5.28l-1.725 1.882-1.673-1.934h-5.96v6.117h5.751l1.83-2.039 1.777 2.039h2.875v-2.091h2.039c.784 0 2.2-.052 2.2-2.2a1.67 1.67 0 0 0-1.834-1.774zm-9.619 4.81h-3.45v-1.2h3.346v-1.15h-3.346v-1.15h3.66l1.464 1.673zm5.751.732l-2.143-2.562 2.143-2.4zm3.4-2.771h-1.882v-1.464h1.882a.737.737 0 0 1 .836.68c-.006.47-.206.784-.838.784z"
          transform="translate(-186.108 -595.937)"
        />
        <path
          id="Path_360"
          data-name="Path 360"
          className="americanexpress-3"
          d="M235.105 611.194a1.64 1.64 0 0 0 1.046-1.673 1.6 1.6 0 0 0-1.83-1.621H230.4v6.117h1.516v-2.143h1.984c.523 0 .732.523.732 1.046l.052 1.15h1.464l-.052-1.255c.004-1.099-.311-1.569-.991-1.621zm-1.307-.627h-1.882v-1.412h1.884a.737.737 0 0 1 .836.68c-.001.465-.21.731-.836.731z"
          transform="translate(-201.855 -596.033)"
        />
        <path
          id="Rectangle_50"
          data-name="Rectangle 50"
          className="americanexpress-3"
          transform="translate(29.172 1.202)"
          d="M0 0h1.516v6.169H0z"
        />
        <path
          id="Path_361"
          data-name="Path 361"
          className="americanexpress-3"
          d="M208 587.5h4.914v1.307h-3.45v1.15h3.346v1.2h-3.346v1.2h3.45v1.307H208z"
          transform="translate(-191.166 -586.298)"
        />
        <path
          id="Path_362"
          data-name="Path 362"
          className="americanexpress-3"
          d="M223.805 590.894a1.64 1.64 0 0 0 1.046-1.673 1.6 1.6 0 0 0-1.83-1.621H219.1v6.117h1.516v-2.143h1.984c.523 0 .732.523.732 1.046l.052 1.15h1.464l-.052-1.255c-.05-1.046-.311-1.515-.991-1.621zm-1.307-.627h-1.882v-1.412h1.884a.737.737 0 0 1 .836.68c-.001.465-.21.731-.836.731z"
          transform="translate(-196.463 -586.345)"
        />
        <path
          id="Path_363"
          data-name="Path 363"
          className="americanexpress-3"
          d="M189.179 587.5l-1.83 4.13-1.83-4.13h-2.353v5.908l-2.614-5.908h-1.987l-2.666 6.117h1.568l.575-1.359h3.032l.575 1.359h3.032v-4.548l2.039 4.548h1.359l2.039-4.5v4.5h1.516V587.5zm-10.613 3.45l.889-2.143.941 2.143z"
          transform="translate(-175.848 -586.298)"
        />
        <path
          id="Path_364"
          data-name="Path 364"
          className="americanexpress-3"
          d="M250.645 587.4v4.235l-2.562-4.235h-2.248v5.751l-2.562-5.751h-1.987l-2.091 4.757h-.941c-.366-.052-.941-.314-.941-1.411v-.471c0-1.464.784-1.568 1.777-1.568h.941v-1.359h-1.987c-.68 0-2.2.523-2.248 3.032-.052 1.673.68 3.085 2.353 3.085h1.987l.575-1.359h3.032l.575 1.359h2.98v-4.5l2.719 4.5h2.039V587.3h-1.412zm-9.253 3.45l.889-2.143.941 2.143z"
          transform="translate(-204.43 -586.202)"
        />
      </g>
    </g>
  </svg>
)

export default SvgAmericanExpressIcon
