import React from 'react'
import PropTypes from 'prop-types'
import {
  AlmondsIcon,
  CeleryIcon,
  ChefHatIcon,
  CornIcon,
  CrustaceanShellfishIcon,
  DairyIcon,
  EggIcon,
  FishIcon,
  GlutenIcon,
  GmoIcon,
  HoneyIcon,
  LupinsIcon,
  MolluskIcon,
  MustardIcon,
  OrganicIcon,
  PeanutsIcon,
  SesameIcon,
  SoybeansIcon,
  SpicyIcon,
  SugarIcon,
  SulfateIcon,
  TreeNutsIcon,
  VegetarianIcon,
} from '../../../icons/allergies_and_diets/icons'

import WhatsAppIcon from '../../../icons/social/Whatsapp'
import InstagramIcon from '../../../icons/social/Instagram'
import FacebookIcon from '../../../icons/social/Facebook'
import TikTokIcon from '../../../icons/social/TikTok'
import LinkedInIcon from '../../../icons/social/LinkedIn'
import TwitterIcon from '../../../icons/social/Twitter'
import SnapChatIcon from '../../../icons/social/SnapChat'
import YoutubeIcon from '../../../icons/social/Youtube'
import EmailIcon from '../../../icons/social/Email'
import CopyIcon from '../../../icons/social/Copy'
import CopiedIcon from '../../../icons/social/Copied'

const Icon = ({ iconName, width, height }) => {
  width = width || 25;
  height = height || 25;
  switch (iconName) {
  case 'Almonds':
    return <AlmondsIcon />
  case 'Celery':
    return <CeleryIcon />
  case 'ChefHat':
    return <ChefHatIcon />
  case 'Corn':
    return <CornIcon />
  case 'CrustaceanShellfish':
    return <CrustaceanShellfishIcon />
  case 'Shellfish':
    return <CrustaceanShellfishIcon />
  case 'Dairy':
    return <DairyIcon />
  case 'Egg':
    return <EggIcon />
  case 'Fish':
    return <FishIcon />
  case 'Wheat':
    return <GlutenIcon />
  case 'Gluten':
    return <GlutenIcon />
  case 'Gmo':
    return <GmoIcon />
  case 'Honey':
    return <HoneyIcon />
  case 'Lupins':
    return <LupinsIcon />
  case 'Mollusk':
    return <MolluskIcon />
  case 'Mustard':
    return <MustardIcon />
  case 'Organic':
    return <OrganicIcon />
  case 'Peanuts':
    return <PeanutsIcon />
  case 'Sesame':
    return <SesameIcon />
  case 'Soy':
    return <SoybeansIcon />
  case 'Soybeans':
    return <SoybeansIcon />
  case 'Spicy':
    return <SpicyIcon />
  case 'Sugar':
    return <SugarIcon />
  case 'Sulfate':
    return <SulfateIcon />
  case 'Tree nuts':
    return <TreeNutsIcon />
  case 'TreeNuts':
    return <TreeNutsIcon />
  case 'Vegetarian':
    return <VegetarianIcon />
  case 'Pistachio':
    return <TreeNutsIcon />
  case 'WhatsApp':
    return <WhatsAppIcon width={width} height={height} />
  case 'Instagram':
    return <InstagramIcon />
  case 'Facebook':
    return <FacebookIcon width={width} height={height} />
  case 'TikTok':
    return <TikTokIcon />
  case 'LinkedIn':
    return <LinkedInIcon width={width} height={height} />
  case 'Twitter':
    return <TwitterIcon width={width} height={height} />
  case 'SnapChat':
    return <SnapChatIcon />
  case 'Youtube':
    return <YoutubeIcon />
  case 'Email':
    return <EmailIcon width={width} height={height} />
  case 'Copy':
    return <CopyIcon width={width} height={height} />
  case 'Copied':
    return <CopiedIcon width={width} height={height} />
  default:
    return null
  }
}

Icon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  iconName: PropTypes.string.isRequired
}

export default Icon
