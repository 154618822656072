// import Swal from 'sweetalert2/dist/sweetalert2';
// import { push } from 'connected-react-router';

import api from '../../utils/api'
/* eslint-disable-next-line no-unused-vars */
// import { normalizeProfile } from '../../utils/normalization'
// import alertModal from '../../utils/alert'

import {
  PAYMENTS_MADE_REQUEST_SENT,
  PAYMENTS_MADE_REQUEST_SUCCESS,
  PAYMENTS_MADE_REQUEST_FAILED,
  SET_PAYMENTS_MADE_REQUEST_DATA,
  SET_SAVED_CARD_DATA,
  SET_ORDER_ID,
  SET_SETUP_INTENT
} from '../../constants/actionTypes'

export function paymentsMadeRequestSent() {
  return { type: PAYMENTS_MADE_REQUEST_SENT }
}

export function paymentsMadeRequestSuccess() {
  return { type: PAYMENTS_MADE_REQUEST_SUCCESS }
}

export function paymentsMadeRequestFailed() {
  return { type: PAYMENTS_MADE_REQUEST_FAILED }
}

export function setPaymentsMadeData(payload) {
  return {
    type: SET_PAYMENTS_MADE_REQUEST_DATA,
    payload
  }
}

export function setSavedCard(payload) {
  return {
    type: SET_SAVED_CARD_DATA,
    payload
  }
}

export function setOrderId(payload) {
  return {
    type: SET_ORDER_ID,
    payload
  }
}

export function setSetupIntent(payload) {
  return {
    type: SET_SETUP_INTENT,
    payload
  }
}

export function fetchPaymentsMade() {
  return async (dispatch, getState) => {
    dispatch(paymentsMadeRequestSent())
    let response
    try {
      response = await api.get(`/users/payments`)

      const json = response.data
      if (json.is_success) {
        // console.log('JSON EXP ', json.experience);
        dispatch(setPaymentsMadeData(json.payments))
      } else {
        // console.log(json.error);
      }

      dispatch(paymentsMadeRequestSuccess())
      // dispatch(getExperience(paymentsMadeRequest.experience.id));
    } catch (err) {
      // alertModal(err.message, 'error', 'Close');
      dispatch(paymentsMadeRequestFailed())
      throw err
    }
  }
}

export function fetchSavedCard() {
  return async (dispatch, getState) => {
    const { reduxTokenAuth } = getState()
    const { id, email } = reduxTokenAuth.currentUser.attributes

    const response = await api.post('diners/get_diner_card', { id, email })

    const json = response.data
    if (json.is_success) {
      const cardToSet = json.card && json.card.card && json.card.id
        ? { ...json.card.card, id: json.card.id }
        : null
      dispatch(setSavedCard(cardToSet))
    } else {
      dispatch(setSavedCard(null))
    }
  }
}

export function createOrderId() {
  return async (dispatch, getState) => {
    const { reservationRequest } = getState()
    const { seats, schedules, experience } = reservationRequest

    const response = await api.post('diners/create_order', { seats, schedule_id: schedules, experience_id: experience.id })

    const json = response.data

    if (json.is_success) {
      dispatch(setOrderId(json.order.id))
    } else {
      dispatch(setOrderId(null))
    }
  }
}

export function createSetupIntent() {
  return async (dispatch, getState) => {
    const { reduxTokenAuth, transactions } = getState()

    const { orderId } = transactions.payments.made
    const { id, email } = reduxTokenAuth.currentUser.attributes

    const response = await api.post('diners/create_setup_intent', { order_id: orderId, id, email })

    const json = response.data
    if (json.is_success) {
      dispatch(setSetupIntent(json.intent))
    } else {
      dispatch(setSetupIntent(null))
    }
  }
}

export function saveCard() {
  // not in use
  return async (dispatch, getState) => {
    const { reduxTokenAuth } = getState()

    const { id, email } = reduxTokenAuth.currentUser.attributes

    // const response = await api.post('/v2/payments/setup_intent', { orderId, id, email })

    // const json = response.data
    // if (json.is_success) {
    //   dispatch(setSetupIntent(json.intent))
    // } else {
    //   dispatch(setSetupIntent(null))
    // }
  }
}

export function handleSetupIntentFailure(errorPayload) {
  return async (dispatch, getState) => {
    const { transactions } = getState()
    const { orderId } = transactions.payments.made

    await api.post('diners/track_order_failure', { order_id: orderId, error: errorPayload })
  }
}

/*

/v2/order_id
Params
- seats
- experienceId
- id - userid
- email - user email
- scheduleId - schedule

Responds with { orderId: 1234 }

/v2/payments/setup_intent
Params
- orderId
- id - userid
- email - user email

Returns setupintent from stripe
Responds with { intent: 'seti_1JKIFUGEwB358jyCfgPnm2eo…tAe2wS7UD'}

/v2/user/saved_card
Params
- id - userid
- email - user email

Responds with default saved card for user
Reference to reservationRequest.saveCard boolean: With an internal wrapper to check if the user has saved card via checkbox
(if user has not checked the box then we return null from the backend even if the user has a saved card on stripe)

/reservations
params
- orderid
- saveCard - boolean of should save card or not
- scheduleId - default is null
- cardId - the cardId of the payment method

returns same response as currently

/v2/payments/failed
params
- orderId
-id - userId
- email
- error - full error object to be saved to the order
*/
