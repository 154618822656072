import { push } from 'connected-react-router'

import {
  deleteAuthHeaders,
  deleteAuthHeadersFromDeviceStorage
} from 'redux-token-auth/dist/services/auth'
import Storage from 'redux-persist/lib/storage/index'
import api from '../utils/api'
import alertModal from '../utils/alert'

import {
  AUTH_SIGNOUT_REQUEST_SENT,
  AUTH_SIGNOUT_SUCCESS,
  AUTH_SIGNOUT_REQUEST_FAILED,
  AUTH_FORCE_RESET
} from '../constants/actionTypes'

export const signOutRequestSent = () => ({
  type: AUTH_SIGNOUT_REQUEST_SENT
})

export const signOutRequestSucceeded = () => ({
  type: AUTH_SIGNOUT_SUCCESS
})

export const signOutRequestFailed = () => ({
  type: AUTH_SIGNOUT_REQUEST_FAILED
})

export const forceSignOutReset = () => ({
  type: AUTH_FORCE_RESET
})

export const signOutUser = () => async dispatch => {
  const userSignOutCredentials = {
    'access-token': await Storage.getItem('access-token'),
    client: await Storage.getItem('client'),
    uid: await Storage.getItem('uid')
  }
  dispatch(signOutRequestSent())
  try {
    await api({
      method: 'DELETE',
      url: '/auth/sign_out',
      data: userSignOutCredentials
    })
    deleteAuthHeaders()
    deleteAuthHeadersFromDeviceStorage(Storage)
    dispatch(signOutRequestSucceeded())
    dispatch(push('/'))
    //alertModal('Successfully logged out!', 'success', 'close');
  } catch (error) {
    dispatch(signOutRequestFailed())
    if (
      typeof error.response !== 'undefined' &&
      error.response.status === 404 &&
      error.response.data.errors[0] ===
        'User was not found or was not logged in.'
    ) {
      dispatch(forceSignOutReset())
      deleteAuthHeaders()
      deleteAuthHeadersFromDeviceStorage(Storage)
      dispatch(signOutRequestSucceeded())
      //alertModal('Successfully logged out!', 'success', 'close');
    }
    console.log('Oops! Logout Session Expired.')
    // throw error;
  }
}
