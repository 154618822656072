import React from 'react'

import AmericanExpressIcon from './AmericanExpressIcon'
import MastercardIcon from './MastercardIcon'
import PaypalIcon from './PaypalIcon'
import VisaIcon from './VisaIcon'

const CreditCardIconGroup = props => (
  <div className="icon-list payment-icons">
    <AmericanExpressIcon className="icon icon--payment" />
    {/* <PaypalIcon className="icon icon--payment" /> */}
    <MastercardIcon className="icon icon--payment" />
    <VisaIcon className="icon icon--payment" />
  </div>
)

export default CreditCardIconGroup
