import {
  SET_FORGOT_PASSWORD,
  VERIFY_OTP,
  VERIFY_MOBILE_OTP,
  SET_UPDATE_API_ERROR
} from '../constants/actionTypes'
import api from '../utils/api'
import { setUserAttributes, setUpdateApiError } from './user'
import { camelToSnake } from '../utils/camelSnake'
/* set a flag to  show signin / forget password screen */
export function setForgotPass(state) {
  return {
    type: SET_FORGOT_PASSWORD,
    payload: state
  }
}
export function setVerifyMobile(flag) {
  return {
    type: VERIFY_OTP,
    payload: flag
  }
}

export function setVerifyMobileOtp(flag, data) {
  return {
    type: VERIFY_MOBILE_OTP,
    payload: flag,
    userData: data
  }
}


/* call api for forget password */
export function forgetPassword(email) {
  return async dispatch => {
    try {
      const response = await api.put(`/forgot_password/`, {
        email
      })
      const json = response.data
      return json.is_success
    } catch (err) {
      return false
      // throw err
    }
  }
}
/* call api for reset password */
export function resetPassword(data) {
  return async dispatch => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.put(`/reset_password/`, data)
      const json = response.data
      return json
    } catch (err) {
      // return err;
      throw err
    }
  }
}
/* call api for verify token on reset password screen */
export function verifyToken(token) {
  return async dispatch => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.put(`/verify_password_token/`, {
        token_type: 'reset_password',
        token
      })
      const json = response.data
      if (json.valid) {
        return true
      } else {
        return false
      }
    } catch (err) {
      throw err
    }
  }
}

export function sendVerifyEmailLink(email) {
  return async dispatch => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.put(`/send_email_verification_link`, {
        email
      })
      const json = response.data
      dispatch(setVerifyMobile(false))
      return json
    } catch (err) {
      throw err
    }
  }
}

export function verifyEmailToken(token) {
  return async dispatch => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.put(`/verify_email`, {
        token
      })
      const json = response.data
      // eslint-disable-next-line no-empty
      if (json.valid) {
      }

      return json
    } catch (err) {
      throw err
    }
  }
}

export function sendVerifyMobileLink(
  id,
  phone,
  phoneCountryCode,
  phoneMetaData,
  currentUser
) {
  return async dispatch => {
    dispatch(setUpdateApiError(null))
    let phoneNumber
    let userId
    let countryCode
    let currentStateUser
    let localPhoneMetaData = phoneMetaData
    if (id && id.attributes && id.attributes.phoneNumber) {
      phoneNumber = id.attributes.phoneNumber.split(' ').join('')
      userId = id.attributes.id
      countryCode = id.attributes.phoneCountryCode
      currentStateUser = id
      localPhoneMetaData = id.attributes.phoneMetaData
    } else {
      phoneNumber = phone.split(' ').join('')
      userId = id
      countryCode = phoneCountryCode
      currentStateUser = currentUser
    }

    const response = await api.put(
      '/send_mobile_verification_otp',
      {
        id: userId,
        phone_number: phoneNumber,
        phone_country_code: countryCode,
        phone_meta_data: localPhoneMetaData ? camelToSnake(localPhoneMetaData) : localPhoneMetaData
      }
    )
    const json = response.data
    if (json.is_success) {
      if (currentStateUser && currentStateUser.attributes) {
        dispatch(setUserAttributes({ ...currentStateUser.attributes, phoneNumber, phoneCountryCode: countryCode, phoneMetaData: localPhoneMetaData }))
      }
      dispatch(setVerifyMobile(true))
    } else {
      dispatch(setUpdateApiError(json))
    }
    return json
  }
}

export function verifyMobile(currentUser, data) {
  return async dispatch => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.put(`/verify_mobile_number`, {
        id: currentUser.attributes.id,
        phone_number: currentUser.attributes.phoneNumber,
        phone_country_code: currentUser.attributes.phoneCountryCode,
        phone_meta_data: currentUser.attributes.phoneMetaData,
        otp: data
      })
      const json = response.data
      // dispatch(setVerifyMobile(false))
      if (json.is_success) {
        currentUser.attributes.phone_verified = true
        dispatch(setUserAttributes(currentUser.attributes))
        dispatch(setVerifyMobileOtp(true, data))
      }

      return json
    } catch (err) {
      throw err
    }
  }
}

/* call api for change password */
export function changePassword(data) {
  return async dispatch => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.put(`/change_password/`, data)
      const json = response.data
      return json
    } catch (err) {
      // return err;
      throw err
    }
  }
}
