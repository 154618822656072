import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ChefSocialLinks from './ChefSocialLinks'

export default class ChefInfo extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { person, displaySpecialties, displayLinks } = this.props
    return (
      <React.Fragment>
        <div className="chef-or-host__chef">
          <div className=" experience-card__chef--name">
            {' '}
            <Link to={`/host/${person.slug}`}>{person.fullname}</Link>
          </div>

          {displaySpecialties && (
            <div className="chef-or-host__chef--specialties">
              {person.specialties.map((specialty, i) => (
                /* eslint-disable-next-line react/no-array-index-key */
                <small key={specialty.name}>
                  {specialty.name}
                  {person.specialties.length - 1 !== i && <span>, </span>}
                </small>
              ))}
            </div>
          )}

          {displayLinks && typeof socialLinks === 'undefined' && (
            <ChefSocialLinks links={person.socialLinks} />
          )}
        </div>
      </React.Fragment>
    )
  }
}

ChefInfo.defaultProps = {
  person: {
    specialties: []
  },
  displayLinks: false,
  displaySpecialties: true
}
ChefInfo.propTypes = {
  displaySpecialties: PropTypes.bool,
  displayLinks: PropTypes.bool,
  person: PropTypes.shape({
    specialties: PropTypes.arrayOf(PropTypes.shape({}))
  })
}
