import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { showHostReviewModal } from '../../../actions/modals'

const HostReviewLink = ({ className, children, showModal, experience, style }) => (
  <button
    type="button"
    style={style}
    className={`${className}`}
    onClick={() =>
      showModal({
        show: true,
        experience
      })
    }
  >
    {children}
  </button>
)

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = dispatch => ({
  showModal: state => dispatch(showHostReviewModal(state))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HostReviewLink)
