export const selectPresent = fieldValue => {
  if (fieldValue === null || typeof fieldValue === 'undefined') {
    return false
  }
  if (typeof fieldValue === 'object' && fieldValue.length) {
    return true
  }
  if (typeof fieldValue === 'object' && !fieldValue.value) {
    return false
  }
  return true
}

export function nestedPresent(parent, child) {
  if (typeof parent === 'undefined') {
    return false
  }
  if (typeof parent[child] === 'undefined') {
    return false
  }
  return true
}
export function validateStringValues(fields, values, errors) {
  const intErrors = errors || {}
  fields.forEach(field => {
    if (!values[field]) {
      intErrors[field] = 'Required'
    }
  })
  return intErrors
}

export function validateSelect(fields, values, errors) {
  const intErrors = errors || {}
  fields.forEach(field => {
    if (!selectPresent(values[field])) {
      intErrors[field] = 'Required'
    }
  })
  return intErrors
}

export function retrieveNestedStates(array, name) {
  if (typeof array === 'undefined') {
    return undefined
  }
  const fieldNameArray = name.split('.')
  const fieldTarget = array[fieldNameArray[0]]
  if (typeof fieldTarget !== 'undefined')
    return array[fieldNameArray[0]][fieldNameArray[1]]
  return undefined
}
export function retrieveSyncErrors(formFields, name) {
  if (typeof formFields === 'undefined') {
    return undefined
  }
  const fieldNameArray = name.split('.')
  const fieldTarget = formFields[fieldNameArray[0]]
  if (typeof fieldTarget !== 'undefined')
    return formFields[fieldNameArray[0]][fieldNameArray[1]]
  return undefined
}

export const isValidURL = string => {
  if (typeof string !== 'string') {
    return false
  }
  const res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  )
  if (res == null) return false
  return true
}

export const convertKeyToName = key => {
  const nameArray = key.split('_')
  for (let i = 0; i < nameArray.length; i += 1) {
    nameArray[i] =
      nameArray[i].charAt(0).toUpperCase() + nameArray[i].substr(1)
  }
  return nameArray.join(' ')
}

export const photoPresent = photo => {
  if (isValidURL(photo)) {
    return true
  }
  if (photo === null || typeof photo === 'undefined') {
    return false
  }
  if (typeof photo === 'object' && photo.attachment_id) {
    return true
  }
  if (typeof photo !== 'object' && !photo.value) {
    return false
  }
  if (typeof photo === 'object' && !photo.length) {
    return false
  }
  return true
}

export const getErrorSentence = errors =>
  Object.keys(errors)
    .map(key => {
      const value = errors[key]
      const name = convertKeyToName(key)

      if (value) {
        return `${name} is missing`
      }
      return false
    })
    .join(', ')

export function renderErrors(errors, allFieldsStates, index) {
  let touchedFields = false
  let errorMessage = ''
  if(typeof errors === 'string'){
    return errors
  }
  const errorsPresent = errors.length && typeof errors[index] !== 'undefined'
  const fieldsPresent = typeof allFieldsStates[index] !== 'undefined'

  /* Checks the error index for the field and returns a message */
  if (errorsPresent) {
    errorMessage = getErrorSentence(errors[index])
  }

  if (errorsPresent && fieldsPresent) {
    Object.keys(allFieldsStates[index]).map(key => {
      const field = allFieldsStates[index][key]
      if (field.touched) {
        touchedFields = true
      }
      return false
    })
  }

  if (errors && errors[index] && touchedFields && errorMessage) {
    return `All values are required: ${errorMessage}`
  }
  return null
}

function keysMatch(key, obj) {
  const keys = Object.keys(obj).map(x => x.toLowerCase())
  return keys.indexOf(key.toLowerCase()) !== -1
}

const disableFields = fieldCollection => {
  const { currentUser } = this.props
  if (typeof currentUser === 'undefined' || !currentUser.isSignedIn) {
    return fieldCollection
  }
  const obj = fieldCollection
  const { attributes } = currentUser
  Object.keys(obj).map(key => {
    if (attributes[key] !== null) {
      obj[key].disabled = !!keysMatch(key, attributes)
    }
    return obj[key]
  })
  return obj
}
