// Packages
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import MobileDetect from 'mobile-detect'
import RegHostLink from '../../Registration/Hosts/RegHostLink'
// Components
import Placeholder from '../Placeholder'

// Utilities
import { trackCTAClick } from '../../../utils/tracking'

export default class Hero extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobile: false
    }
    this.mobileDetect = this.mobileDetect.bind(this)
  }

  componentDidMount() {
    this.mobileDetect()

    if (typeof window !== 'undefined') {
      this.responsiveState()
      window.addEventListener('resize', this.handleResize)
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.handleResize)
    }
  }

  responsiveState = () => {
    const w = window
    const d = document
    const { documentElement } = d.documentElement
    const body = d.getElementsByTagName('body')[0]
    const width = w.innerWidth || documentElement.clientWidth || body.clientWidth

    this.setState({ mobile: width < 993 })
  }

  handleResize = () => {
    this.responsiveState()
    this.forceUpdate()
  }

  heroBackground() {
    const { heroVideo, heroImage, mobileHeroImage } = this.props
    const defaultMobileHeroImage = mobileHeroImage || heroImage
    const { mobile } = this.state

    if (mobile) {
      return (
        <div className="breakbread-component-bg-inner fadeIn -mobile">
          <div
            className="breakbread-component-bg-photo"
            style={{ backgroundImage: `url(${defaultMobileHeroImage})` }}
          />
        </div>
      )
    }

    if (heroVideo) {
      return (
        <div className="breakbread-component-bg-inner fadeIn">
          <div
            className="breakbread-component-bg-video"
            // style={{ backgroundImage: `url(${heroImage})` }}
          >
            <video preload="auto" loop muted autoPlay>
              <source src={heroVideo} type="video/mp4" />
            </video>
          </div>
        </div>
      )
    }
    if (heroImage) {
      return (
        <div className="breakbread-component-bg-inner fadeIn">
          <div
            className="breakbread-component-bg-photo"
            style={{ backgroundImage: `url(${heroImage})` }}
          />
        </div>
      )
    }
    return ''
  }

  mobileDetect() {
    const body = $('body')
    if (window) {
      const device = new MobileDetect(window.navigator.userAgent)
      if (device.mobile()) { body.addClass('mobile') }
      if (device.tablet()) { body.addClass('tablet') }
      if (device.is('iPhone') || device.is('AndroidOS') || device.is('Windows Phone')) {
        body.addClass('touch')
      }
    }
    const browser = {
      mobile: body.hasClass('mobile'),
      tablet: body.hasClass('tablet'),
      touch: body.hasClass('touch')
    }

    if (typeof browser !== 'undefined' && (browser.mobile || browser.touch)) {
      this.setState({ mobile: true })
    }
  }

  renderHero() {
    const { theme, title, textPosition, subtitle, buttonCTA, buttonComponent, buttonLink, children, isLoading, modalActions, currentUser, sectionName } = this.props
    const btnClass = theme === 'color'
      ? 'breakbread-btn btn--flat btn--darker'
      : 'breakbread-btn'
    const includeChildren = typeof children === 'function' ? children(this.props) : children

    const { hostApplication } = currentUser && currentUser.attributes ? currentUser.attributes : 'undefined'

    if (typeof children === 'undefined') {
      return (
        <div className="breakbread-component-text" style={{ textAlign: `${textPosition}` }}>
          <h1 className="h1 mega-title">{!isLoading ? title : <Placeholder isLoading={isLoading} />}</h1>
          <div className="mega-subtitle">
            <p>{!isLoading ? subtitle : <Placeholder isLoading={isLoading} rows={2} />}</p>
          </div>
          {buttonComponent && typeof buttonComponent !== 'undefined' && buttonComponent}
          {(typeof buttonCTA !== 'undefined' && buttonCTA !== null && buttonCTA.length > 0) && (typeof buttonLink !== 'undefined' && buttonLink !== null && buttonLink.length > 0) ? (
            <Link to={`${buttonLink}`} onClick={() => trackCTAClick({ buttonCTA })} className={btnClass}>
              {buttonCTA}
            </Link>
          ) : '' }

          {buttonCTA === 'Sign Up' && !currentUser.isSignedIn && (
            <button onClick={() => modalActions.showRegModal({
              show: true,
              modalCallBack: () => {
                // This gets added to Modal object in Redux as a prop. Can then be used
                // to send the user to a previous state, next state and/or close the modal.
                /*alertModal(
                  'Yay! You have succesfully logged in',
                  'success',
                  'continue'
                );*/
                modalActions.hideRegModal()
              }
            })} className={btnClass}>
              {buttonCTA}
            </button>
          )}

          {buttonCTA === 'Log In' && !currentUser.isSignedIn && (
            <button onClick={() => modalActions.showAuthModal({
              show: true,
              modalCallBack: () => {
                // This gets added to Modal object in Redux as a prop. Can then be used
                // to send the user to a previous state, next state and/or close the modal.
                /*alertModal(
                  'Yay! You have succesfully logged in',
                  'success',
                  'continue'
                );*/
                modalActions.hideAuthModal()
              }
            })} className={btnClass}>
              {buttonCTA}
            </button>
          )}

          {buttonCTA === 'Book an experience' && currentUser.isSignedIn && (
            hostApplication === 'complete'
              ? '' : (
                <Link to={`${buttonLink}`} className="btn" >
                  {' '}{buttonCTA}
                </Link>
              )
          )}

          {sectionName === 'hero5' ? (
            (currentUser.isSignedIn && hostApplication === 'complete') ? '' : <RegHostLink className="btn" />
          ): '' }

          {sectionName === 'hero6' ? (
            (currentUser.isSignedIn &&
            hostApplication === 'complete' ||
            hostApplication === 'declined' ||
            hostApplication === 'approved') ? '' : <RegHostLink className="btn" />
          ) : ''}

          {buttonCTA === 'Become a Host Today' && (
            <RegHostLink className="btn">
              {buttonCTA}
            </RegHostLink>
          )}
        </div>
      )
    }

    return <React.Fragment>{includeChildren}</React.Fragment>
  }

  render() {
    const {
      textPosition,
      theme,
      topHero,
      treatment,
      heroImageHeight
    } = this.props

    // Styling
    const topClass = topHero ? '-top' : ''
    const heroClasses = `${!treatment ? '-treatment' : treatment}-fill -${theme} ${topClass}`
    const treatmentClasses = `${!treatment ? '-treatment' : treatment}`
    const heroHeaderHeightClass = heroImageHeight === 'medium' ? 'breakbread--hero-medium' : heroImageHeight === 'small' ? 'breakbread--hero-small' : ''

    return (
      <React.Fragment>
        <header
          id="hero"
          className={`breakbread-component breakbread-component--hero fadeIn -landing ${heroHeaderHeightClass} ${heroClasses}`}
        >
          <div className="breakbread-component-wrap">
            <div
              className={`breakbread-component-fill ${treatmentClasses}`}
            />
            <div className="breakbread-component-bg">
              {this.heroBackground()}
            </div>
            <div className={`breakbread-component-body -${textPosition}`}>
              <div className="breakbread-component-body-inner fadeIn">
                <div className="breakbread-container">
                  {this.renderHero()}
                </div>
              </div>
            </div>
          </div>
        </header>
      </React.Fragment>
    )
  }
}

Hero.defaultProps = {
  mobileHeroImage: null,
  heroVideo: null,
  buttonCTA: null,
  title: null,
  subtitle: null,
  theme: null,
  heroImage: null,
  isLoading: false,
  buttonLink: null,
  topHero: false,
  treatment: false,
  buttonComponent: null,
  children: undefined,
  heroImageHeight: 'large'
}

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonCTA: PropTypes.string,
  heroImage: PropTypes.string,
  heroImageHeight: PropTypes.string,
  textPosition: PropTypes.string.isRequired,
  theme: PropTypes.string,
  mobileHeroImage: PropTypes.string,
  heroVideo: PropTypes.string,
  isLoading: PropTypes.bool,
  buttonLink: PropTypes.string,
  topHero: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func
  ]),
  treatment: PropTypes.bool,
  buttonComponent: PropTypes.node,
  modalActions: PropTypes.object
}
