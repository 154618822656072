import moment from 'moment'
import userAttributes from './userAttributes'
import userRegistrationAttributes from './userRegistrationAttributes'
export const HOST =
  process.env.REACT_APP_DEV_API_URL || 'https://breakbread-api-dev.herokuapp.com/'

export const FIREBASE_API_KEY= process.env.FIREBASE_API_KEY
export const FIREBASE_AUTH_DOMAIN= process.env.FIREBASE_AUTH_DOMAIN
export const FIREBASE_PROJECT_ID= process.env.FIREBASE_PROJECT_ID
  
export const API_WSS_URL =
  process.env.API_WSS_URL ||
  process.env.REACT_APP_API_WSS_URL ||
  'breakbread-api-dev.herokuapp'

export const API_WS_ROOT = `wss://${API_WSS_URL}/cable`

export const HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
}

export const COMMON_GRID_SIZE =
  'grid__item small--one-whole medium-down--one-half medium--one-half large-up--one-third'
export const FEATURED_GRID_SIZE =
  'grid__item small--one-whole medium-down--one-whole medium--one-whole large-up--one-whole'
export const GRID_SIZE_THUMBS =
  'grid__item small--one-whole medium-down--one-half medium-up--one-half'

export const SPLIT_GRID_SIZE =
  'grid__item small--one-whole medium-down--one-half medium-up--one-half'

export const authUrl = `${HOST}/api/v2/auth`

export const requiredExperience = 1
/* export const amenities = [{
  label:"Nearby public transport",
  value:"Nearby public transport"
},
{
  label:"Wifi available",
  value:"Wifi available"
},
{
  label:"Free visitor parking",
  value:"Free visitor parking"
},
{
  label:"Paid parking",
  value:"Paid parking"
},
{
  label:"Kids friendly",
  value:"Kids friendly"
},
{
  label:"Air conditioning",
  value:"Air conditioning"
},
{
  label:"Elevator in the building",
  value:"Elevator in the building"
},
{
  label:"Wheelchair accessible",
  value:"Wheelchair accessible"
},
{
  label:"Pets in the house",
  value:"Pets in the house"
},
{
  label:"Smoking allowed",
  value:"Smoking allowed"
},
{
  label:"Smoking free",
  value:"Smoking free"
}] */
/* export const wow_elements = [
  {
    label:"Rooftop View",
    value:"Rooftop View"
  },
  {
    label:"Terrace",
    value:"Terrace"
  },
  {
    label:"Outdoor setting",
    value:"Outdoor setting"
  },
  {
    label:"High-end value",
    value:"High-end value"
  },
  {
    label:"Live music",
    value:"Live music"
  },
  {
    label:"Unusual space",
    value:"Unusual space"
  },
  {
    label:"Historical monument",
    value:"Historical monument"
  },
  {
    label:"Decoration",
    value:"Decoration"
  }
] */  

export const experienceDuration = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6]
export const authHeaderKeys = [
  'access-token',
  'token-type',
  'client',
  'expiry',
  'uid'
]

export const CURRENT_YEAR = moment.utc().year()
export const EARLIEST_YEAR = 1960
export const LATEST_YEAR = 2050
export const NEXT_YEAR = moment
  .utc()
  .add(1, 'years')
  .year()

export const MIN_AGE = moment
  .utc()
  .subtract(17, 'years')
  .year()

export { userAttributes, userRegistrationAttributes }
