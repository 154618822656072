import Swal from 'sweetalert2/dist/sweetalert2'
import { push } from 'connected-react-router'
import _ from 'lodash'
import moment from 'moment'
import api from '../utils/api'
/* eslint-disable-next-line no-unused-vars */
import { normalizeProfile ,normalizeReservation} from '../utils/normalization'
import { currencyTypes } from '../constants/currency'
import alertModal from '../utils/alert'
import { getExperience } from './experience'

import {
  RESERVATION_REQUEST_SENT,
  RESERVATION_REQUEST_SUCCESS,
  RESERVATION_REQUEST_FAILED,
  SET_RESERVATION_REQUEST_INFO,
  SET_COUPON_CODE,
  SET_AUTH_CALLBACK,
  SET_COUPON_DETAIL,
  SET_COUPON_ERROR,
  GET_RESERVATION_DETAIL,
  SET_IS_SAVED_CARD
} from '../constants/actionTypes'
import { trackCompleteCheckout, trackTransaction } from '../utils/tracking'

export function reservationRequestSent() {
  return { type: RESERVATION_REQUEST_SENT }
}

export function reservationRequestSuccess() {
  return { type: RESERVATION_REQUEST_SUCCESS }
}

export function reservationRequestFailed() {
  return { type: RESERVATION_REQUEST_FAILED }
}

export function setReservationDetail(reservation) {
  return { type: GET_RESERVATION_DETAIL,reservation }
}

export function setReservationRequestInfo(info) {
  let payload = info
  // Inorder to keep whats saved to storage to a minimum
  if (typeof info.experience !== 'undefined') {
    const { experience, schedules } = info

    const currency = currencyTypes[experience.currency]

    const payloadToSet = {
      experience: {
        title: experience.title || '',
        price: experience.price || '',
        id: experience.id || '',
        host: experience.host.fullname || '',
        date: experience.date || '',
        currency: currency || '',
        instant: experience.instant
      },
      ...schedules
    }
    payload = {
      ...info,
      ...payloadToSet
    }
  }
  return {
    type: SET_RESERVATION_REQUEST_INFO,
    payload
  }
}

export function setCouponCode(info) {
  let payload = info
  if((typeof info.old_coupon !== 'undefined' && info.old_coupon !== null) && (typeof info.coupon !== 'undefined' && info.coupon !== null) && (info.old_coupon !== info.coupon)) {
    payload = {
      coupon: info.coupon,
      couponInfo: null,
      couponError: null,
      couponDetail: null
    }
  } else if(typeof info.coupon !== 'undefined' && info.coupon !== null){
    payload= {
      coupon: info.coupon
    }
  } else if(info.coupon == null){
    payload = {
      coupon: null,
      couponInfo: null,
      couponError: null,
      couponDetail: null
    }
  }

  return {
    type: SET_COUPON_CODE,
    payload
  }
}

export function setIsSavedCard(boolean) {
  return {
    type: SET_IS_SAVED_CARD,
    payload: {
      saveCard: boolean
    }
  }
}

export function setCouponDetails(couponDetail, couponInfo) {
  return {
    type: SET_COUPON_DETAIL,
    payload: {
      couponDetail,
      couponInfo
    }
  }
}
export function setAuthCallback(info) {
  return {
    type: SET_AUTH_CALLBACK,
    payload: {
      ...info
    }
  }
}
export function setCouponError(couponError) {
  return {
    type: SET_COUPON_ERROR,
    couponError
  }
}

const fireReservationConfirmation = (reservationRequest, reservationResponse, dispatch) => {
  const seats = `${reservationRequest.seats} ` + reservationRequest.seats > 1 ? 'Seats' : 'Seat'
  const experienceTitle = reservationResponse.reservation.experience.title
  const bookingNumber = reservationResponse.booking_number
  const scheduleDate = moment.utc(reservationResponse.reservation.schedule.schedule_time).format('LL')
  const text_for_non_instant_experience = 'We will let you know when your request has been reviewed and approved.'
  const text_for_instant_experience = "Your booking is confirmed and you will receive an email shortly with the booking details."
  const text = reservationResponse.reservation.experience.instant ? text_for_instant_experience : text_for_non_instant_experience
  const message = `<b style="font-size: 18px">Experience:</b> ${experienceTitle} (${scheduleDate})`

  const sweetness = Swal.mixin({
    confirmButtonClass: 'btn',
    cancelButtonClass: 'btn',
    buttonsStyling: false,
    showCancelButton: true,
    confirmButtonText: 'Explore Experiences',
    padding: '2rem'
  })

  sweetness({
    title: 'Thank You For Your Booking.',
    html: `<p style="font-size: 22px; color: #595959;">Booking Number: #<b>${bookingNumber}</b></p></h5>${message}<p>${text}</p>`,
    type: 'success',
    showCloseButton: true,
    showCancelButton: false,
    confirmButtonText: 'Explore Experiences',
    padding: '3rem'
  }).then(result => {
    if (result.value) {
      dispatch(push('/experiences'))
    }
  })
}

export function bookExperience() {
  return async (dispatch, getState) => {
    const { reservationRequest, transactions } = getState()
    // console.log({ transactions })
    dispatch(reservationRequestSent())
    //  TO_DO: change the body according to the request;
    const reservation = {
      // experience_id: reservationRequest.experience.id,
      // seats_booked: Number(reservationRequest.seats),
      coupon: reservationRequest.coupon ? reservationRequest.coupon : '',
      order_id: transactions.payments.made.orderId,
      card_token_id: transactions.payments.made.savedCard && transactions.payments.made.savedCard.id ? transactions.payments.made.savedCard.id : '',
      save_card: reservationRequest.saveCard
    }

    // console.log({ reservation })

    try {
      const response = await api.post('diners/create_reservation', { ...reservation })
      if (response.data.is_success) {
        trackCompleteCheckout()
        trackTransaction({
          transactionId: _.get(response, 'data.booking_number'),
          // transactionAffiliation: experience type pending
          transactionTotal: _.get(response, 'data.reservation.total'),
          transactionTax: _.get(response, 'data.reservation.tax', 0),
          transactionShipping: 0,
          currencyCode: _.get(response, 'data.reservation.currency')
        })
        fireReservationConfirmation(reservationRequest, response.data, dispatch)
        dispatch(reservationRequestSuccess())
        dispatch(getExperience(reservationRequest.experience.id))
        // Close booking modal here
      } else {
        alertModal(response.message, 'error', 'Close')
      }
    } catch (err) {
      if (err && err.response && err.response.data) {
        alertModal(err.response.data.message, 'error', 'Close')
        dispatch(reservationRequestFailed())
        dispatch(getExperience(reservationRequest.experience.id))
      }
    }
  }
}

export function changeReservation(experienceId, reservationId, status) {
  return async (dispatch, getState) => {
    // const { accessToken } = getState().user;
    let url = `/reservations/${reservationId}/`
    if (typeof status !== 'undefined') {
      url += status
    } else {
      url += 'decline'
    }
    try {
      const response = await api.post(url)

      if (response.data.is_succes) {
        // dispatch(getReservations(experienceId));
      } else {
        alertModal(err.message, 'error', 'Close')
      }
    } catch (err) {
      // alertModal(err.message, 'error', 'Close');
      throw err
    }
  }
}


export function getReservationDetail(id) {
  return async dispatch => {

    try {
      const response = await api.get(`diners/reservations/${id}`)
      const json = response.data
      json.reservation = normalizeReservation(json.reservation)
      if(json.is_success){
      
        dispatch(setReservationDetail(json.reservation))
      }else{
      //dispatch(getReservationDetail())
      }
      
      //return json;
    } catch (err) {
      throw err
    }

  }
}

export function applyCouponCode(code) {
  return async (dispatch, getState) => {
    const { transactions } = getState()
    const orderId = transactions.payments.made.orderId
    try {
      const response = await api.post('/diners/reservations/apply_coupon', { order_id: orderId, coupon: code })
      const data = response.data
      const coupon = {...data.coupon, ...{ netDiscount: data.redeemed_amount }}
      if (data.is_success) {
        dispatch(setCouponDetails(coupon, data.info_message))
      } else {
        dispatch(setCouponError(data.error_message))
      }
    } catch (err) {
      if (err && err.response && err.response.data) {
        alertModal(err.response.data.error, 'error', 'Close')
      }
    }
  }
}