export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const SET_PROFILE = 'SET_PROFILE'
export const SET_FULL_REGISTRATION_STATE = 'SET_FULL_REGISTRATION_STATE'
export const HOST_APPLICATION_COMPLETE = 'HOST_APPLICATION_COMPLETE'
// export const SET_PAYMENT = 'SET_PAYMENT';
export const SET_USER_ATTRIBUTES = 'SET_USER_ATTRIBUTES'
export const FETCH_CURRENT_USER_START = 'FETCH_CURRENT_USER_START'
export const SET_UPDATE_API_ERROR = 'SET_UPDATE_API_ERROR'
// AUTH
export const AUTH_TOKEN_VERIFIED = 'redux-token-auth/VERIFY_TOKEN_REQUEST_SUCCEEDED'
export const AUTH_SIGNIN_SUCCESS = 'redux-token-auth/SIGNIN_REQUEST_SUCCEEDED'
export const AUTH_SIGNOUT_SUCCESS = 'redux-token-auth/SIGNOUT_REQUEST_SUCCESS'
export const AUTH_TOKEN_FAILED = 'redux-token-auth/VERIFY_TOKEN_REQUEST_FAILED'
// Reload Page
export const AUTH_RELOAD_PAGE = 'AUTH_RELOAD_PAGE'
// User Sessions
export const AUTH_SIGNOUT_REQUEST_SENT = 'redux-token-auth/SIGNOUT_REQUEST_SENT'
export const AUTH_SIGNOUT_REQUEST_FAILED = 'redux-token-auth/SIGNOUT_REQUEST_FAILED'

export const AUTH_FORCE_RESET = 'AUTH_FORCE_RESET'
export const SET_FORGOT_PASSWORD = 'SET_FORGOT_PASSWORD'
export const VERIFY_OTP = 'VERIFY_OTP'
export const VERIFY_MOBILE_OTP = 'VERIFY_MOBILE_OTP'

// Coupons
export const COUPON_LOADING = 'COUPON_LOADING'
export const SET_MY_COUPONS = 'SET_MY_COUPONS'
export const SET_MY_REFERRAL_COUPON = 'SET_MY_REFERRAL_COUPON'

// Experiences
export const SET_ALL_EXPEREINCES_DATA = 'SET_ALL_EXPEREINCES_DATA'
export const SET_EXPERIENCES = 'SET_EXPERIENCES'
export const SET_EXPERIENCE = 'SET_EXPERIENCE'
export const EXPERIENCE_LOADING = 'EXPERIENCE_LOADING'
export const EXPERIENCE_UPDATING = 'EXPERIENCE_UPDATING'

export const SET_CUISINE_TYPE_FILTER_VALUES = 'SET_CUISINE_TYPE_FILTER_VALUES'

export const SET_CUISINE_TYPES = 'SET_CUISINE_TYPES'
export const SET_MENU_ALLERGENS = 'SET_MEAL_CATEGORIES'
export const SET_MEAL_CATEGORIES = 'SET_MEAL_CATEGORIES'

export const SET_FILTER = 'SET_FILTER'
export const SET_HOST_EXPERINCE_FILTER = 'SET_HOST_EXPERINCE_FILTER'
export const SET_CREATE_EXPERIENCE = 'SET_CREATE_EXPERIENCE'

export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const BECOME_HOST = 'BECOME_HOST'
export const AUTH = 'AUTH'
export const REG = 'REG'
export const SUBCRIPTION = 'SUBCRIPTION'
export const HOST_CONFIRMATION = 'HOST_CONFIRMATION'
export const USER_CONFIRMATION = 'USER_CONFIRMATION'
export const RESERVATION = 'RESERVATION'
export const EXPERIENCE = 'EXPERIENCE'
export const ACCOUNT = 'ACCOUNT'

// HOSTS
export const SET_LISTINGS = 'SET_LISTINGS'
export const SET_LISTINGS_PAST_FUTURE = 'SET_LISTINGS_PAST_FUTURE'
export const SET_HOST_APPLICATION_STATUS = 'SET_HOST_APPLICATION_STATUS'
export const SET_HOSTS = 'SET_HOSTS'
export const SET_HOST_EXPERIENCES = 'SET_HOST_EXPERIENCES'
export const SET_HOSTS_META = 'SET_HOSTS_META'
export const SET_HOST = 'SET_HOST'
export const SET_HOST_FILTER = 'SET_HOST_FILTER'
export const SET_RESERVATIONS = 'SET_RESERVATIONS'
export const SET_PROFILE_PIC_OBJ = 'SET_PROFILE_PIC_OBJ'

// FEATURED ITEMS
export const SET_FEATURED_ITEMS = 'SET_FEATURED_ITEMS'

export const FETCH_LISTINGS_REQUEST_SENT = 'FETCH_LISTINGS_REQUEST_SENT'
export const FETCH_LISTINGS_REQUEST_FAILED = 'FETCH_LISTINGS_REQUEST_FAILED'
export const FETCH_LISTINGS_REQUEST_SUCCESS = 'FETCH_LISTINGS_REQUEST_SUCCESS'

// Editing Listing
export const SET_EDITING_LISTING = 'SET_EDITING_LISTING'
export const FETCH_EDITING_LISTING_SENT = 'FETCH_EDITING_LISTING_SENT'
export const FETCH_EDITING_LISTING_FAILED = 'FETCH_EDITING_LISTING_FAILED'
export const FETCH_EDITING_LISTING_SUCCESS = 'FETCH_EDITING_LISTING_SUCCESS'

// Create Listing
export const SET_MENU = 'SET_MENU'

// FORM
export const UPDATE_STEP = 'UPDATE_STEP'
export const TOGGLE_PANE_CLICKED = 'TOGGLE_PANE_CLICKED'
export const ALL_OPTIONS = 'ALL_OPTIONS'
export const EMAIL_FORM = 'EMAIL_FORM'
export const JUST_REGISTER = 'JUST_REGISTER'

// PAYMENT METHODS
export const PAYMENT_METHOD_TOKEN_FORM_STATUS = 'PAYMENT_METHOD_TOKEN_FORM_STATUS'
export const SET_PAYMENT_TOKEN = 'SET_PAYMENT_TOKEN'
export const PAYMENT_METHOD_SENT_FOR_VERIFICATION = 'PAYMENT_METHOD_SENT_FOR_VERIFICATION'
export const PAYMENT_METHOD_STATUS = 'PAYMENT_METHOD_STATUS'
export const SET_PREFERRED_PAYMENT_METHOD_INFO = 'SET_PREFERRED_PAYMENT_METHOD_INFO'

// LOAD SCRIPTS
export const FRAMES_SCRIPT_LOAD_STATUS = 'FRAMES_SCRIPT_LOAD_STATUS'
export const FRAMES_SCRIPT_INITIALIZED = 'FRAMES_SCRIPT_INITIALIZED'

// RESERVATION
export const RESERVATION_REQUEST_SENT = 'RESERVATION_REQUEST_SENT'
export const RESERVATION_REQUEST_SUCCESS = 'RESERVATION_REQUEST_SUCCESS'
export const RESERVATION_REQUEST_FAILED = 'RESERVATION_REQUEST_FAILED'
export const SET_RESERVATION_REQUEST_INFO = 'SET_RESERVATION_REQUEST_INFO'
export const RESERVATION_REQUEST = 'RESERVATION_REQUEST'
export const SET_COUPON_CODE = 'SET_COUPON_CODE'
export const SET_IS_SAVED_CARD = 'SET_IS_SAVED_CARD'
export const SET_COUPON_DETAIL = 'SET_COUPON_DETAIL'
export const SET_COUPON_ERROR = 'SET_COUPON_ERROR'
export const SET_DINER_RESERVATIONS = 'SET_DINER_RESERVATIONS'
export const SET_DINER_RESERVATION_TYPE = 'SET_DINER_RESERVATION_TYPE'
export const SET_AUTH_CALLBACK = 'SET_AUTH_CALLBACK'
// COMPONENT STATES
export const CHANGE_SELECTED_TAB = 'CHANGE_SELECTED_TAB'

// TRANSACTIONS
export const PAYMENTS_MADE_REQUEST_SENT = 'PAYMENTS_MADE_REQUEST_SENT'
export const PAYMENTS_MADE_REQUEST_SUCCESS = 'PAYMENTS_MADE_REQUEST_SUCCESS'
export const PAYMENTS_MADE_REQUEST_FAILED = 'PAYMENTS_MADE_REQUEST_FAILED'
export const SET_PAYMENTS_MADE_REQUEST_DATA = 'SET_PAYMENTS_MADE_REQUEST_INFO'
export const SET_SAVED_CARD_DATA = 'SET_SAVED_CARD_DATA'
export const SET_ORDER_ID = 'SET_ORDER_ID'
export const SET_SETUP_INTENT = 'SET_SETUP_INTENT'
// export const TRANSACTIONS_REQUEST = 'TRANSACTIONS_REQUEST';

// RESERVATIONS MADE
export const RESERVATIONS_MADE_REQUEST_SENT = 'RESERVATIONS_MADE_REQUEST_SENT'
export const RESERVATIONS_MADE_REQUEST_SUCCESS = 'RESERVATIONS_MADE_REQUEST_SUCCESS'
export const RESERVATIONS_MADE_REQUEST_FAILED = 'RESERVATIONS_MADE_REQUEST_FAILED'
export const SET_RESERVATIONS_MADE_REQUEST_DATA = 'SET_RESERVATIONS_MADE_REQUEST_INFO'
export const SET_RESERVATIONS_MADE_PAST_FUTURE = 'SET_RESERVATIONS_MADE_PAST_FUTURE'

// REVIEWS
export const SET_MY_REVIEWS = 'SET_MY_REVIEWS'
export const SET_GUEST_REVIEWS = 'SET_GUEST_REVIEWS'
export const SET_MY_HOST_REVIEWS = 'SET_MY_HOST_REVIEWS'
export const SET_MY_DINERS_REVIEWS = 'SET_MY_DINERS_REVIEWS'
export const HOST_REVIEW = 'HOST_REVIEW'
export const GUEST_REVIEW = 'GUEST_REVIEW'
export const REVIEW_COMMENT_FORM = 'REVIEW_COMMENT_FORM'
export const SHOW_REVIEW_COMMENT_MODAL = 'SHOW_REVIEW_COMMENT_MODAL'
export const HIDE_REVIEW_COMMENT_MODAL = 'HIDE_REVIEW_COMMENT_MODAL'

// RECEIPTS
export const RECEIPT = 'RECEIPT'
export const GUEST_RECEIPT = 'GUEST_RECEIPT'
export const HOST_RECEIPT = 'HOST_RECEIPT'
export const EXPERIENCE_SCHEDULE = 'EXPERIENCE_SCHEDULE'
export const EXPERIENCE_SCHEDULE_FORM = 'EXPERIENCE_SCHEDULE_FORM'
export const HIDE_SCHEDULE_MODAL = 'HIDE_SCHEDULE_MODAL'
export const SHOW_SCHEDULE_MODAL = 'SHOW_SCHEDULE_MODAL'

// USER HOST LOCATION
export const SET_LOCATIONS = 'SET_LOCATIONS'
export const SET_SINGLE_LOCATION = 'SET_SINGLE_LOCATION'
export const HOST_LOCATION_FORM = 'HOST_LOCATION_FORM'

// NOTIFICATIONS
export const SET_USER_ONLINE_STATUS = 'SET_USER_ONLINE_STATUS'
export const NOTIFICATIONS_LOADING = 'NOTIFICATIONS_LOADING'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_CABLE_NOTIFICATION = 'SET_CABLE_NOTIFICATION'
export const SET_USER_BANK_ATTRIBUTES = 'SET_USER_BANK_ATTRIBUTES'
export const GET_RESERVATION_DETAIL = 'GET_RESERVATION_DETAIL'
export const SET_SAMPLE_EXPERINCE = 'SET_SAMPLE_EXPERINCE'

// BLOGS
export const SET_BLOGS = 'SET_BLOGS'
export const SET_BLOG = 'SET_BLOG'
export const BLOG_LOADING = 'BLOG_LOADING'

// UPCOMING SCHEDULES
export const SET_UPCOMING_SCHEDULE = 'SET_UPCOMING_SCHEDULE'
export const SET_FILTER_SCHEDULE_DATES = 'SET_FILTER_SCHEDULE_DATES'
