import React, { Component } from 'react'
import LogoNegative from './LogoNegative'

export default class ImageWithOverlaySquare extends Component {
  componentWillMount() {}

  render() {
    const { theme } = this.props
    let mainclass
    if (typeof theme !== 'undefined') {
      mainclass = `breakbread-component -square -treatment-fill -inverse -${theme}`
    } else {
      mainclass = `breakbread-component -square -treatment-fill -inverse`
    }
    return (
      <div
        data-component-name="default"
        className={mainclass}
        data-wow-offset={20}
      >
        <div className="breakbread-component-wrap">
          <div className="breakbread-component-fill -treatment" />
          <div className="breakbread-component-bg">
            <div className="breakbread-component-bg-inner">
              <div
                className="breakbread-component-bg-photo"
                style={{ backgroundImage: this.props.image?`url(${this.props.image})`:null }}
              />
            </div>
          </div>
          <div className="breakbread-component-body -center -full">
            <div className="breakbread-component-body-inner">
              <div className="breakbread-container">
                <div className="breakbread-container-inner">
                  {this.props.children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
