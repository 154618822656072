import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ChefAvtarHost from './ChefAvtarHost'
import ChefInfo from './ChefInfo'


export default class ChefOrHostCard extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { person, displayInfo, displayLinks } = this.props
    if (!displayInfo) {
      return null
    }
    const hasAvatar = person.avatarUrl && !person.avatarUrl.includes('/thumb_default.png')

    return (
      <div className="chef-or-host">
        {/* <Link to={`/hosts/${person.id}`}> */}
        <ChefAvtarHost person={person} />
        {/* </Link> */}
        {/* <Link to={`/hosts/${person.id}`}> */}
        {!hasAvatar ? (<p style={{ marginBottom: '50px' }}/>) : ''}
        <ChefInfo displayLinks={displayLinks} person={person} />
        {/* </Link> */}
      </div>
    )
  }
}

ChefOrHostCard.defaultProps = {
  // featured: false,
  // hideAvatar: false,
  // forceHoverState: false,
  // mobileHeroImage: null,
  // heroVideo: null,
  displayLinks: false,
  displayInfo: true
}
ChefOrHostCard.propTypes = {
  displayInfo: PropTypes.bool,
  displayLinks: PropTypes.bool,
  person: PropTypes.shape({
    fullname: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string.isRequired,
    specialties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    socialLinks: PropTypes.object.isRequired,
    reviewsReceivedAsHostCount: PropTypes.number.isRequired,
    hostRating: PropTypes.number.isRequired
  }).isRequired
}
