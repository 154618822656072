import moment from 'moment'

export const getTime = timeData => {
  if (timeData) {
    const timeDataArr = timeData.split(':')
    const timeDataStr = `${timeDataArr[0]}:${timeDataArr[1]}`
    return timeDataStr// moment.utc(new Date(`11-08-2021 ${timeData}`)).format('HH:mm')
  }
  return null
}
export const experienceInitialValues = valuesFromState => {
  if (!valuesFromState) {
    return null
  }
  // console.log('getTime(valuesFromState.time)', getTime(valuesFromState.time))
  // console.log('valuesFromState.date', valuesFromState.date)
  const attributes = {
    id: valuesFromState.id,
    title: valuesFromState.title,
    date: valuesFromState.date,
    time: getTime(valuesFromState.time),
    heroImage: valuesFromState.heroImage,
    /* StartTime:moment.utc(valuesFromState.start_date +" "+valuesFromState.start_time).add(1, 'days').format('HH:mm'),
    start_date:moment.utc(valuesFromState.start_date).add(1, 'days').format('MM/DD/YYYY'),
    EndTime: moment.utc(valuesFromState.end_date +" "+valuesFromState.end_time).add(1, 'days').format('HH:mm'),
    end_date:moment.utc(valuesFromState.end_date).add(1, 'days').format('MM/DD/YYYY'),  */
    price: valuesFromState.price,
    status: valuesFromState.status,
    currency: valuesFromState.currency,
    seats: valuesFromState.seats,
    notes: valuesFromState.notes,
    description: valuesFromState.description,
    summary: valuesFromState.summary,
    cuisineTypesAttributes: valuesFromState.cuisineTypes?valuesFromState.cuisineTypes.map((el)=>({id: el.id, label: el.name, value: el.id})):[],
    photos: valuesFromState.photos,
    location: valuesFromState.location,
    experienceType: valuesFromState.experienceType?{id: valuesFromState.experienceType.id, value: valuesFromState.experienceType.id, label: valuesFromState.experienceType.name}:'',
    instant: typeof valuesFromState.instant !== 'undefined'?valuesFromState.instant:false,
    duration: valuesFromState.duration ? { value: valuesFromState.duration, label: valuesFromState.duration } : '',
    countryCode:valuesFromState.location ? valuesFromState.location.countryCode: null,
    phoneCountryCode: valuesFromState.phone_country_code,
    menuAttributes: {
      menuAllergensAttributes: [],
      mealCategories: [],
      menuItemsAttributes: []
    }
  }
  if (valuesFromState.menu) {
    attributes.menuAttributes = {
      id: valuesFromState.menu.id,
      menuAllergensAttributes: valuesFromState.menu.menuAllergens?valuesFromState.menu.menuAllergens.map((el)=>({id: el.id, label: el.name, value: el.id})):[],
      mealCategories: valuesFromState.menu.mealCategories,
      menuItemsAttributes: valuesFromState.menu.menuItems
    }
  }
  
  return attributes
}

export const experienceNew = valuesFromState => {
  const attributes = {
    id: null,
    title: null,
    date: null,
    time: null,
    price: null,
    currency: null,
    seats: null,
    notes: null,
    description: null,
    summary: null,
    cuisineTypesAttributes: null,
    location:null,
    menuAttributes: {
      menuAllergensAttributes: [],
      mealCategories: [],
      menuItemsAttributes: []
    }
  }
  return attributes
}
