import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

/* Constants */
import { currentUserPropTypes } from '../../../constants/composed-proptypes'

/* Actions */
import * as modalActionCreators from '../../../actions/modals'
import * as signUpActionCreators from '../../../actions/signUp'

const RegLink = ({ className, children, modalActions, currentUser }) => {
  const { isSignedIn } = currentUser
  return (
    <React.Fragment>
      {!isSignedIn && (
        <button
          type="button"
          className={`${className}`}
          disabled={isSignedIn}
          onClick={() => {
            modalActions.showRegModal({
              modalCallBack: () => {
                // This gets added to Modal object in Redux as a prop. Can then be used
                // to send the user to a previous state, next state and/or close the modal.
                modalActions.hideRegModal()
              }
            })
          }}
        >
          {children}
        </button>
      )}
    </React.Fragment>
  )
}

RegLink.propTypes = {
  modalActions: PropTypes.shape({}).isRequired,
  currentUser: currentUserPropTypes.isRequired,
  children: PropTypes.shape({}).isRequired,
  className: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  signUpActions: bindActionCreators(signUpActionCreators, dispatch)
  /** NOTE: This would also be a nice way of doing it
   * https://github.com/reduxjs/react-redux/blob/master/docs/api.md#inject-todos-and-all-todoactioncreators-and-counteractioncreators-directly-as-props
   *
   * actions: bindActionCreators(Object.assign({}, modalActions), dispatch)
   */
})
export default connect(mapStateToProps, mapDispatchToProps)(RegLink)
