import React from 'react'

const AccountsWrapper = ({ title, children }) => (
  <div className="breakbread-container">
    <div className="page-header text-center">
      <h1>{title}</h1>
    </div>
    <div className="account">{children}</div>
  </div>
)

export default AccountsWrapper
