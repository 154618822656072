import storage from 'redux-persist/lib/storage/index'

/* Utility Function to create header object from Storage
 * @returns {object}
 */
async function getHeadersFromStorage() {
  try {
    const [accessToken, tokenType, client, expiry, uid] = await Promise.all([
      storage.getItem('access-token'),
      storage.getItem('token-type'),
      storage.getItem('client'),
      storage.getItem('expiry'),
      storage.getItem('uid')
    ])
    const headers = {
      'access-token': accessToken,
      'token-type': tokenType,
      client,
      expiry,
      uid
    }
    return headers
  } catch (err) {
    throw new Error('Something went wrong getting items from storage')
  }
}

export default getHeadersFromStorage
