import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Avatar from './Avatar'
import CardInfo from './CardInfo'

export default class UserCard extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { person, hideInfo } = this.props
    return (
      <div className="guest_card">
        <Avatar person={person} />
        {/* <CardInfo person={person} /> */}
      </div>
    )
  }
}

UserCard.defaultProps = {
  // featured: false,
  // hideAvatar: false,
  // forceHoverState: false,
  // mobileHeroImage: null,
  // heroVideo: null,
}
UserCard.propTypes = {
  person: PropTypes.shape({
    fullname: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string.isRequired,
    guestReviewsReceivedCount: PropTypes.number.isRequired,
    guestRating: PropTypes.number.isRequired
  }).isRequired
}
