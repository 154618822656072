import PropTypes from 'prop-types'

const locationPropTypes = {
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  hash: PropTypes.string.isRequired,
  state: PropTypes.any,
  key: PropTypes.string
}

const userAttributeProptypes = {
  id: PropTypes.number,
  fullName: PropTypes.string,
  description: PropTypes.string
}

const historyPropTypes = {
  length: PropTypes.number.isRequired,
  action: PropTypes.string.isRequired,
  location: PropTypes.shape(locationPropTypes).isRequired,
  index: PropTypes.number,
  entries: PropTypes.arrayOf(PropTypes.shape(locationPropTypes)),
  createHref: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
  go: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  goForward: PropTypes.func.isRequired,
  canGo: PropTypes.func,
  block: PropTypes.func.isRequired,
  listen: PropTypes.func.isRequired
}

const currentUserPropTypes = PropTypes.shape({
  isSignedIn: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasVerificationBeenAttempted: PropTypes.bool.isRequired,
  attributes: PropTypes.shape(userAttributeProptypes).isRequired
})

export {
  locationPropTypes,
  userAttributeProptypes,
  historyPropTypes,
  currentUserPropTypes
}
