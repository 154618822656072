import moment from 'moment'
import { normalizeScheduleds } from './normalization'

export function normalizeProfile(email, fullname, image, isHost) {
  return {
    email: email || '',
    fullname: fullname || '',
    avatarUrl: image || '',
    is_host: isHost || ''
  }
}

export function scheduleCut(schedule) {
  if (!schedule) {
    return {
      associationMissing: 'The Association is missing in normalization'
    }
  }
  let updatedDate = null
  if(schedule && schedule.date) {
    const dateArr = schedule.date.split('/')
    updatedDate = `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`
  }
  return {
    createdAt: schedule.created_at || '',
    date: updatedDate,
    scheduleTime: schedule.schedule_time || '',
    experienceId: schedule.experience_id || '',
    guestReviewsAverageRating: schedule.guest_reviews_average_rating || '',
    guestReviewsCount: schedule.guest_reviews_count || '',
    hasPassed: schedule.has_passed || '',
    hostReviewsAverageRating: schedule.host_reviews_average_rating || '',
    hostReviewsCount: schedule.host_reviews_count || '',
    id: schedule.id || '',
    pausedAt: schedule.paused_at || '',
    resumedAt: schedule.resumed_at || '',
    seats: schedule.seats || 0,
    seatsAvailable: schedule.seats_available && schedule.seats_available != ''?schedule.seats_available:0,
    seatsBooked: schedule.seats_booked || 0,
    revenueSharePercentage: schedule.revenue_share_percentage || 0,
    status: schedule.status || '',
    updatedAt: schedule.updated_at || '',
    blockBefore: schedule.block_before || ''
  }
}

export function guestCut(guest) {
  if (!guest) {
    return {
      associationMissing: 'The Association is missing in normalization'
    }
  }
  
  return {
    id: guest.id,
    slug: guest.slug || '',
    fullname: guest.fullname || 'Anonymous',
    avatarUrl: guest.profile_picture && guest.profile_picture.thumb && !guest.profile_picture.thumb.includes('/thumb_default.png')? guest.profile_picture.thumb + '?' + new Date().getTime() : '/defaults/profile_pictures/default.png',
    heroImage: guest.hero_image && guest.hero_image.big ? guest.hero_image.big + '?' + new Date().getTime() : '',
    covidCertificate: guest.covid_certificate ? guest.covid_certificate : null,
    guestRating: guest.guest_rating || 0,
    hostRating: guest.guest_rating || 0,
    hostApplication: guest.host_application || '',
    guestReviewsReceivedCount: guest.guest_reviews_received_count || 0,
    reviewsReceivedAsHostCount: guest.reviews_received_as_guest_count || 0
  }
}

export function hostCut(host) {
  if (!host) {
    return {
      associationMissing: 'The Association is missing in normalization'
    }
  }

  return {
    id: host.id || 0,
    slug: host.slug || '',
    fullname: host.fullname || 'Anonymous',
    avatarUrl: host.profile_picture && host.profile_picture.thumb && !host.profile_picture.thumb.includes('/thumb_default.png')? host.profile_picture.thumb + '?' + new Date().getTime() : '/defaults/profile_pictures/default.png',
    heroImage: host.hero_image && host.hero_image.big ? host.hero_image.big + '?' + new Date().getTime() : '',
    covidCertificate: host.covid_certificate ? host.covid_certificate : null,
    hostRating: host.host_rating || 0,
    specialties: host.specialties || [],
    hostApplication: host.host_application || '',
    reviewsReceivedAsHostCount: host.reviews_received_as_host_count || 0,
    reviewsReceivedAsGuestCount: host.reviews_received_as_guest_count || 0,
    // reviewsReceivedAsHostCount: host.host_reviews_received_count || 0,
    socialLinks: host.socialLinks || {},
    revenueSharePercentage: host.revenue_share_percentage || 0
  }
}

export function experienceCut(experience) {
  if (!experience) {
    return {
      associationMissing: 'The Association is missing in normalization'
    }
  }
  // console.log('new Date(experience.schedules[0].date)', new Date('28-10-2021'))
  let updatedDate = null
  let scheduleDateTime = null

  const normalizationScheduled = experience.schedules?normalizeScheduleds(experience.schedules):[]
  const scheduleDataDesc = [...normalizationScheduled]
  scheduleDataDesc.sort(function(a,b){
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return  new Date(b.scheduleTime) - new Date(a.scheduleTime)
  })
  const scheduleDataAsc = [...normalizationScheduled]
  scheduleDataAsc.sort(function(a,b){
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return  new Date(a.scheduleTime) - new Date(b.scheduleTime)
  })
  if (scheduleDataDesc) {
    updatedDate = scheduleDataDesc[0] && scheduleDataDesc[0].date?new Date(scheduleDataDesc[0].date):null
    scheduleDateTime = scheduleDataDesc[0] && scheduleDataDesc[0].scheduleTime?new Date(scheduleDataDesc[0].scheduleTime):null
  }
  return {
    id: experience.id || '',
    slug: experience.slug || '',
    title: experience.title || '',
    duration: experience.duration || '',
    experienceType: experience.experience_type || '',
    schedules: scheduleDataDesc,
    schedulesAsc: scheduleDataAsc,
    image: `${experience.hero_image && experience.hero_image.big?experience.hero_image.big:experience.gallery_images && experience.gallery_images[0] && experience.gallery_images[0].big?experience.gallery_images[0].big + '?' + new Date().getTime() :''}`,
    heroImage: experience.hero_image || '',
    date: updatedDate,
    dateWithTime: scheduleDateTime,
    readableDate: experience.readable_date || '',
    time: experience.time,
    pause_reservations: experience.pause_reservations || '',
    paused: experience.paused || '',
    // time: experience.date ? moment.utc(experience.date).format('hh:mm A') : '',
    price: experience.price || '',
    approved: experience.approved || false,
    status: experience.status || '',
    currency: experience.currency || '',
    address: experience.address || '',
    location: experience.location || '',
    city: experience.city || '',
    seats: experience.schedules && experience.schedules[0] ? experience.schedules[0].seats : '',
    cuisineTypes: experience.cuisine_types || '',
    seatsAvailable: experience.seats_available || 0,
    seatsBooked: experience.schedules && experience.schedules[0] ? experience.schedules[0].seats_booked:'',
    instant: experience.instant || false,
    summary: experience.summary || '',
    description: experience.description || '',
    favorite: experience.favorite || '',
    hasReviewed: experience.has_reviewed,
    hasBooked: experience.has_booked,
    hasPassed: experience.has_passed,
    localEventTime: experience.local_event_time,
    currentLocalTime: experience.current_local_time,
    hasPassedLocally: experience.has_passed_locally,
    reviewsPending: experience.reviews_pending,
    host: hostCut(experience.host),
    searchable: experience.searchable,
    blockBefore: experience.block_before || ''
  }
}