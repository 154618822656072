import { CHANGE_SELECTED_TAB } from '../constants/actionTypes'

export function changeSelectedTab(selectedTab, tabNamespace) {
  return {
    type: CHANGE_SELECTED_TAB,
    tab: selectedTab,
    namespace: tabNamespace
  }
}

export function someFunction() {
  return null
}
