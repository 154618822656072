import {
  TOGGLE_PANE_CLICKED,
  ALL_OPTIONS,
  EMAIL_FORM
} from '../constants/actionTypes'

export function showEmailPane() {
  return {
    type: TOGGLE_PANE_CLICKED,
    payload: EMAIL_FORM
  }
}

export function showAllOptionsPane() {
  return {
    type: TOGGLE_PANE_CLICKED,
    payload: ALL_OPTIONS
  }
}
