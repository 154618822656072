import React from 'react'
import { connect } from 'react-redux'
import { showEditExperienceModal } from '../../../actions/modals'
import { getEditExperience } from '../../../actions/host'

const EditExperienceLink = ({ className, children, showModal, item }) => (
  <React.Fragment>
    <span
      className={`${className}`}
      style={{ padding: '10px' }}
      onClick={() => showModal({ show: true, item })}
      // disabled={item.seatsBooked}
    >
      {children}
    </span>
  </React.Fragment>
)

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = dispatch => ({
  showModal: async state => {
    // TODO: Show modal first (or global spinner) and hide the form until
    // getEditExperience has resolved.
    await dispatch(getEditExperience(state.item.id))
    dispatch(showEditExperienceModal(state))
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(EditExperienceLink)
