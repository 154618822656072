import qs from 'qs'
import api from '../../utils/api'
import alertModal from '../../utils/alert'
import { push } from 'connected-react-router'
// import moment from 'moment';
import {
  normalizeExperience,
  normalizeExperiences,
  normalizeHosts,
  normalizeHostProfile,
  normalizeReservations
} from '../../utils/normalization'

import { getUserAttributesFromResponse } from '../../utils/auth-tools'
import { setUserAttributes } from '../user'

import {
  SET_LISTINGS,
  SET_HOSTS,
  SET_FILTER,
  SET_HOST_EXPERIENCES,
  SET_HOSTS_META,
  SET_HOST,
  SET_HOST_FILTER,
  SET_RESERVATIONS,
  SET_LISTINGS_PAST_FUTURE,
  FETCH_LISTINGS_REQUEST_SENT,
  FETCH_LISTINGS_REQUEST_FAILED,
  FETCH_LISTINGS_REQUEST_SUCCESS,
  SET_EDITING_LISTING,
  SET_MENU,
  FETCH_EDITING_LISTING_SENT,
  FETCH_EDITING_LISTING_FAILED,
  FETCH_EDITING_LISTING_SUCCESS,
  SET_SAMPLE_EXPERINCE,
  SET_HOST_EXPERINCE_FILTER,
  SET_DINER_RESERVATIONS,
  SET_DINER_RESERVATION_TYPE,
  SET_HOST_APPLICATION_STATUS
} from '../../constants/actionTypes'
import { meta } from 'react-dom-factories'

export function listingsRequestSent() {
  return { type: FETCH_LISTINGS_REQUEST_SENT }
}

export const setSampleExperince = sampleExperince => ({
  type: SET_SAMPLE_EXPERINCE,
  payload: {
    sampleExperince
  }
})

export const setHostApplicationStatus = status => ({
  type: SET_HOST_APPLICATION_STATUS,
  payload: {
    status
  }
})

export function listingsRequestSuccess() {
  return { type: FETCH_LISTINGS_REQUEST_SUCCESS }
}

export function listingsRequestFailed() {
  return { type: FETCH_LISTINGS_REQUEST_FAILED }
}

export function setListings(experiences) {
  return {
    type: SET_LISTINGS,
    experiences
  }
}
export function  setDinerReservations(reservation) {
  return {
    type: SET_DINER_RESERVATIONS,
    reservation
  }
}

export function  setDinerReservationType(filter) {
  return {
    type: SET_DINER_RESERVATION_TYPE,
    filter
  }
}

export function setListingsByDate({ past, future }) {
  return {
    type: SET_LISTINGS_PAST_FUTURE,
    payload: {
      past,
      future
    }
  }
}

export function setHosts({ hosts, page, oldHosts }) {
  /*  let newHosts = hosts
  if (page > 1) {
    newHosts = [...oldHosts.hosts, ...hosts]
  } */
  return {
    type: SET_HOSTS,
    hosts: hosts
  }
}

export function setHostsFilter({ filter }) {
  return {
    type: SET_FILTER,
    filter
  }
}

export function setHostsMeta(hostsMeta) {
  return {
    type: SET_HOSTS_META,
    hostsMeta
  }
}

export function setHost(host) {
  return {
    type: SET_HOST,
    host
  }
}

export function setHostExperiences({experiences, futureExperiences, pastExperiences, filter}) {
  return {
    type: SET_HOST_EXPERIENCES,
    experiences,
    futureExperiences,
    pastExperiences,
    filter
  }
}

export function setReservations(reservations) {
  return {
    type: SET_RESERVATIONS,
    reservations
  }
}

export function setReservationsLoading(loading) {
  return { type: 'RESERVATIONS_LOADING', loading }
}

export function setFilter(filter) {
  return {
    type: SET_HOST_FILTER,
    filter
  }
}
export function setHostExperinceFilter(filter) {
  return {
    type: SET_HOST_EXPERINCE_FILTER,
    filter
  }
}

export function fetchEditingListingSent() {
  return { type: FETCH_EDITING_LISTING_SENT }
}

export function fetchEditingListingSuccess() {
  return { type: FETCH_EDITING_LISTING_SUCCESS }
}

export function fetchEditingListingFailed() {
  return { type: FETCH_EDITING_LISTING_FAILED }
}

export function setEditingListing(experience) {
  return {
    type: SET_EDITING_LISTING,
    experience
  }
}
export function setMenu(menu) {
  return { type: SET_MENU, menu }
}
// Utils
const haveNotHappened = listings => {
  const items = listings || []
  const futureDates = items.filter(e => !e.hasPassed)
  // console.log(futureDates)
  return futureDates
}

const alreadyHappened = listings => {
  const items = listings || []
  const pastDates = items.filter(e => e.hasPassed)
  // console.log(pastDates)
  return pastDates
}

// Thunks
export function loadMenu(selectedMenu) {
  return (dispatch, getState) => {
    const listingData = getState().host.listings.data
    const [firstExperience] = listingData.slice(-1)
    if (listingData.length) {
      const { menu } = firstExperience
      if ((typeof menu !== 'undefined' && menu.length > 0) || selectedMenu) {
        if (selectedMenu) {
          dispatch(setMenu(selectedMenu))
        } 
        else if (typeof menu !== 'undefined' && menu.length > 0) 
        { 
          dispatch(setMenu(menu)) 
        }
      }
    }
  }
}
export function getExperiences(experienceType = 'all') {
  return async (dispatch, getState) => {
    dispatch(listingsRequestSent())
    let response
    try {
      response = await api.get(
        `hosts/experiences?experienceType=${experienceType}`
      )
      const json = response.data
      json.experiences.map(item => {
        if (item.status.toLowerCase() === 'submitted')
          item.pause_reservations = true
      })
      if (json.is_success || (json.meta && json.meta.is_success)) {
        /* Mutations */
        const normalizedExperiences = normalizeExperiences(json.experiences)
        const future = haveNotHappened(normalizedExperiences)
        const past = alreadyHappened(normalizedExperiences)
        /* Dispatch */
        dispatch(setListings(normalizedExperiences)) // All Data
        dispatch(setListingsByDate({ past, future })) // Past vs Future
        dispatch(listingsRequestSuccess()) // Mark Status
      } else {
        // alertModal(json.error, 'error', 'Close')
        dispatch(listingsRequestFailed()) // Mark Status
      }
    } catch (err) {
      // alertModal(err.message, 'error', 'Close');
      dispatch(listingsRequestFailed()) // Mark Status
      throw err
    }
  }
}

export function getHosts() {
  return (dispatch, getState) => {
    const { filter, hosts } = getState().host
    const rating = filter.rating ? filter.rating.value : ''
    // const cuisineTypes = filter.cuisineTypes ? filter.cuisineTypes.value : '';
    const cuisineTypes = filter.cuisineTypes
      ? filter.cuisineTypes.map(cuisine => cuisine.value)
      : []
    return api('search/hosts', {
      params: {
        /* eslint-disable-next-line camelcase */
        per_page: filter.perPage,
        /* eslint-disable-next-line camelcase */
        rating,
        /* eslint-disable-next-line camelcase */
        page: filter.currentPage,
        /* eslint-disable-next-line camelcase */
        cuisine_types: cuisineTypes
        /* eslint-disable-next-line camelcase */
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
      .then(response => {
        const json = response.data
        if (json.meta.is_success) {
          dispatch(
            setHosts({
              hosts: normalizeHosts(json.hosts),
              page: filter.currentPage,
              oldHosts: hosts
            })
          )
          dispatch(setHostsMeta(json.meta))
        } else {
          alertModal(json.error, 'error', 'Close')
        }
      })
      .catch(e => {
        // alertModal(e.message, 'error', 'Close');
        throw e
      })
  }
}

export function getHost(hostId) {
  // console.log('HOST ID ', hostId);

  return (dispatch, getState) =>
    api
      .get(`/hosts/${hostId}`)
      .then(response => {
        const json = response.data
        if (json.is_success || typeof json.user !== 'undefined') {
          dispatch(setHost(normalizeHostProfile(json.user)))
        } else {
          alertModal(json.error, 'error', 'Close')
        }
      })
      .catch(e => {
        // alertModal(e.message, 'error', 'Close')
        throw e
      })
}

export function getHostExperiences({host_id, page}){
  return (dispatch, getState) => {
    // const startDate = moment(new Date()).add(1, 'days')
    const startDate = null
    const endDate = null
    // return api('browse/experiences', {
    return api('search/experiences', {
      params: {
        page,
        per_page: 9,
        host_id,
        locations: [],
        cuisine_types: [],
        date_range: {
          start_date: startDate,
          end_date: endDate
        }
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
      .then(({ data }) => {
        const json = { ...data }
        if (json.meta.is_success) {
          const experiences = normalizeExperiences(json.experiences)
          const future = experiences.filter(
            e => moment(e.dateWithTime).isAfter(moment(new Date()).utc())
          )
          const past = experiences.filter(
            e => moment(e.dateWithTime).isBefore(moment(new Date()).utc())
          )
          const response = {
            experiences: experiences,
            futureExperiences: future,
            pastExperiences: past,
            filter: { page: json.meta.page, total: json.meta.total_count }
          }
          dispatch(setHostExperiences(response))
          // dispatch(setExperiencesAllStat(json))
        } else {
          // console.log('getExperiences failed', json);
        }
      })
      .catch(e => console.log(e)) /* eslint-disable-line no-console */
  }
}

export function getReservations() {
  return () => api
    .get('/hosts/reservations')
    .then(response => {
      const json = response.data
      if (!json.meta.is_success) {
        alertModal(json.error, 'error', 'Close')
      }
      json.reservations = normalizeReservations(json.reservations)
      // dispatch(setReservations(normalizeReservations(json.reservations)));
      return json
    })
    .catch(e => {
      // alertModal(e.message, 'error', 'Close')
      throw e
    })
}

export function getDinerReservations(type) {
  return (dispatch, getState) =>{
    const reservationFiletr =type ? type : getState().user.reservation.filter.reservationType
    return api(`diners/reservations?reservationtype=${reservationFiletr}`)
      .then(response => {
        const json = response.data
        if (json.is_success) {
          dispatch(setDinerReservations(normalizeReservations(json.reservations)))
          //dispatch(fetchEditingListingSuccess())
        } else {
          dispatch(setDinerReservations([]))
          //dispatch(fetchEditingListingFailed())
        }
      })
      .catch(e => {
        dispatch(setDinerReservations([]))
        console.log(e) /* eslint-disable-line no-console */
      })
  }
}

export function getConfirmedReservations(experienceId) {
  return (dispatch, getState) =>
    api
      // .get(`experiences/${experienceId}/confirmed_reservations`)
      .get(`/hosts/schedules/${experienceId}/confirmed_reservations`)
      .then(response => {
        const json = response.data
        if (json.is_success) {
          dispatch(setReservations(normalizeReservations(json.reservations)))
        } else {
          alertModal(json.error, 'error', 'Close')
        }
      })
      .catch(e => {
        // alertModal(e.message, 'error', 'Close')
        throw e
      })
}

// const byId = (array, itemId) => {
//   const result = array.filter(r => r.id === itemId);
//   return result[0];
// };

export function getEditExperience(experienceId) {
  return async (dispatch, getState) => {
    dispatch(fetchEditingListingSent())

    return api(`hosts/experiences/${experienceId}`)
      .then(response => {
        const json = response.data
        if (json.is_success) {
          dispatch(setEditingListing(normalizeExperience(json.experience)))
          dispatch(fetchEditingListingSuccess())
        } else {
          dispatch(fetchEditingListingFailed())
        }
      })
      .catch(e => {
        dispatch(fetchEditingListingFailed())
        console.log(e) /* eslint-disable-line no-console */
      })
  }
}

export function hostApplicationStarted() {
  /* eslint-disable-next-line no-unused-vars, no-console */
  return async (dispatch, getState) => {
    const currentUserData = getState().reduxTokenAuth.currentUser.attributes
    const { hostApplication } = currentUserData
    if (hostApplication === 'not_applied') {
      let response
      try {
        response = await api.post(`hosts/become_a_host`)
      } catch (err) {
        throw err
      }
      // const userAttributes = getUserAttributesFromResponse(response)
      if(response.data.is_success) {
        dispatch(setUserAttributes({...currentUserData, hostApplication: response.data.data.host_application }))
      }
    }
  }
}

export function hostApplicationSubmitted() {
  return async (dispatch, getState) => {
    const currentUserData = getState().reduxTokenAuth.currentUser.attributes
    let response
    try {
      response = await api.post(`hosts/update_host_application`)
    } catch (err) {
      throw err
    }
    if(response.data.is_success) {
      dispatch(setUserAttributes({...currentUserData, hostApplication: response.data.data.host_application }))
    }
    dispatch(setHostApplicationStatus(true))
    dispatch(push('/'))
  }
}

export function updateReservationStatus(experience) {
  return async (dispatch, getState) => {
    let response = {}
    const experinceFilters = getState().experience.experienceIndex
    // console.log('experinceFilters', experinceFilters)

    const satatus = experience.status && experience.status === 'paused' ? 'resume' : 'pause'
    response = await api.put(`/hosts/experiences/${experience.id}/${satatus}`, {})
    alertModal('Your status is updated now', 'success', 'Close')
    response = await api.get(
      `hosts/experiences?experienceType=${experinceFilters.HostExperincefilter.experienceType}`
    )
    const json = response.data
    json.experiences.map((item) => {
      if (item.status === 'Pending Approval') { item.pause_reservations = true }

      return item
    })

    if (json.meta.is_success) {
      /* Mutations */
      const normalizedExperiences = normalizeExperiences(json.experiences)
      const future = haveNotHappened(normalizedExperiences)
      const past = alreadyHappened(normalizedExperiences)
      /* Dispatch */
      dispatch(setListings(normalizedExperiences)) // All Data
      dispatch(setListingsByDate({ past, future })) // Past vs Future
      dispatch(listingsRequestSuccess()) // Mark Status
    } else {
      alertModal(json.error, 'error', 'Close')
      dispatch(listingsRequestFailed()) // Mark Status
    }
  }
  // const past = getState().host.listings.past;
  // dispatch(setListings(normalizeExperiences(getState().host.listings.data))); // All Data
  // dispatch(setListingsByDate({ past, future })); // update Future reservation
  // dispatch(listingsRequestSuccess()); // Mark Status
}

export function getSampleExperiences() {
  return async (dispatch, getState) => {
    const hostApplication = getState().reduxTokenAuth.currentUser.attributes.hostApplication
    if (hostApplication !== 'not_applied') {
      let sampleExperince
      try {
        const responseExperince = await api.get(
          `hosts/sample_experience`
        )
        if (responseExperince.data.is_success && responseExperince.data.experience) {
          sampleExperince = normalizeExperience(responseExperince.data.experience)
          dispatch(setSampleExperince(sampleExperince))
        } else {
          // sampleExperince= normalizeExperience(responseExperince.data.experience);
          dispatch(setSampleExperince([]))
        }
      } catch (err) {
        // alertModal(err.message, 'error', 'Close');
        // dispatch(listingsRequestFailed()); // Mark Status
        dispatch(setSampleExperince([]))
        throw err
      }
    }
  }
}
