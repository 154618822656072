import React from "react"

const SvgMastercardIcon = props => (
  <svg viewBox="0 0 65.611 40.987" {...props}>
    <defs>
      <style>
        {`.mastercard-2{fill:#fbb231}.mastercard-2,.mastercard-3,.mastercard-4{fill-rule:evenodd}.mastercard-3{fill:#ec1c2e}.mastercard-4{fill:#fff}.mastercard-5{fill:none;stroke:#fff;stroke-miterlimit:2.613;stroke-width:.216px}`}
      </style>
    </defs>
    <g id="mastercard-icon">
      <path
        id="Path_289"
        data-name="Path 289"
        d="M227.858 38.791a2.209 2.209 0 0 1-2.2 2.2H164.5a2.209 2.209 0 0 1-2.2-2.2V2.2a2.209 2.209 0 0 1 2.2-2.2h61.219a2.209 2.209 0 0 1 2.2 2.2v36.6z"
        transform="translate(-162.3)"
        fill="#f9f9f9"
      />
      <g
        id="Group_130"
        data-name="Group 130"
        transform="translate(13.122 8.731)"
      >
        <path
          id="Path_290"
          data-name="Path 290"
          className="mastercard-2"
          d="M229.363 40.226h.575l.627-.052.575-.1.575-.1.575-.1.575-.157.523-.209.523-.209.523-.209.523-.261.523-.314.471-.261.471-.366.418-.314.418-.418.418-.366.418-.418.366-.418.314-.471.366-.418.314-.523.261-.471.261-.523.209-.523.209-.523.209-.523.157-.575.157-.575.1-.575.052-.575.052-.627v-1.2l-.052-.575-.052-.627-.1-.575-.157-.523-.157-.575-.209-.575-.209-.523-.209-.523-.261-.471-.261-.523-.314-.471-.366-.471-.314-.418-.366-.418-.418-.418-.418-.418-.418-.366-.418-.314-.471-.366-.471-.261-.523-.314-.523-.261-.523-.209-.523-.209-.523-.209-.575-.157-.575-.1-.575-.1-.575-.1-.627-.052h-1.2l-.575.052-.627.1-.575.1-.523.1-.575.157-.575.209-.523.209-.523.209-.471.261-.523.314-.471.261-.471.366-.418.314-.418.366-.418.418-.418.418-.366.418-.314.418-.366.471-.261.471-.314.523-.261.471-.209.523-.209.523-.209.575-.157.575-.1.523-.1.575-.1.627-.052.575v1.2l.052.627.1.575.1.575.1.575.157.575.209.523.209.523.209.523.261.523.314.471.261.523.366.418.314.471.366.418.418.418.418.366.418.418.418.314.471.366.471.261.523.314.471.261.523.209.523.209.575.209.575.157.523.1.575.1.627.1.575.052z"
          transform="translate(-201.812 -16.7)"
        />
        <path
          id="Path_291"
          data-name="Path 291"
          className="mastercard-3"
          d="M199.215 40.226h.575l.627-.052.575-.1.575-.1.575-.1.575-.157.523-.209.523-.209.523-.209.523-.261.471-.314.471-.261.471-.366.471-.314.418-.418.418-.366.366-.418.366-.418.366-.471.314-.418.314-.523.261-.471.261-.523.261-.523.209-.523.157-.523.157-.575.157-.575.1-.575.052-.575.052-.627v-1.2l-.052-.575-.052-.627-.1-.575-.157-.523-.157-.575-.157-.575-.209-.523-.261-.523-.261-.471-.261-.523-.314-.471-.314-.471-.366-.418-.366-.418-.366-.418-.418-.418-.418-.366-.471-.314-.471-.366-.471-.261-.471-.314-.523-.261-.523-.209-.523-.209-.523-.209-.575-.157-.575-.1-.575-.1-.575-.1-.627-.052h-1.2l-.575.052-.627.1-.575.1-.575.1-.523.157-.575.209-.523.209-.523.209-.523.261-.471.314-.471.261-.471.366-.471.314-.418.366-.418.418-.366.418-.366.418-.366.418-.314.471-.314.471-.314.523-.261.471-.209.523-.209.523-.209.575-.157.575-.1.523-.1.575-.1.627v.575l-.052.627.052.575v.627l.1.575.1.575.1.575.157.575.209.523.209.523.209.523.261.523.314.471.314.523.314.418.366.471.366.418.366.418.418.366.418.418.471.314.471.366.471.261.471.314.523.261.523.209.523.209.575.209.523.157.575.1.575.1.627.1.575.052z"
          transform="translate(-187.4 -16.7)"
        />
        <path
          id="Path_292"
          data-name="Path 292"
          className="mastercard-2"
          d="M222.4 24.727h6.221V24.1h-5.7l-.523.627z"
          transform="translate(-204.102 -20.231)"
        />
        <path
          id="Path_293"
          data-name="Path 293"
          className="mastercard-2"
          d="M220.6 27.427h7.162V26.8h-6.8l-.366.627z"
          transform="translate(-203.243 -21.52)"
        />
        <path
          id="Path_294"
          data-name="Path 294"
          className="mastercard-2"
          d="M219.3 30.18h7.842v-.68h-7.581l-.261.68z"
          transform="translate(-202.623 -22.808)"
        />
        <path
          id="Path_295"
          data-name="Path 295"
          className="mastercard-2"
          d="M218.3 32.88h8.365v-.68h-8.156l-.209.68z"
          transform="translate(-202.146 -24.097)"
        />
        <path
          id="Path_296"
          data-name="Path 296"
          className="mastercard-2"
          d="M218.709 46.027h8.1V45.4H218.5l.209.627z"
          transform="translate(-202.241 -30.396)"
        />
        <path
          id="Path_297"
          data-name="Path 297"
          className="mastercard-2"
          d="M219.714 48.727h7.528V48.1H219.4l.314.627z"
          transform="translate(-202.671 -31.684)"
        />
        <path
          id="Path_298"
          data-name="Path 298"
          className="mastercard-2"
          d="M221.266 51.427h6.692V50.8H220.9l.366.627z"
          transform="translate(-203.386 -32.973)"
        />
        <path
          id="Path_299"
          data-name="Path 299"
          className="mastercard-2"
          d="M223.323 54.127h5.542V53.5H222.8l.523.627z"
          transform="translate(-204.293 -34.261)"
        />
        <path
          id="Path_300"
          data-name="Path 300"
          className="mastercard-2"
          d="M217.8 35.627h8.626V35H217.9l-.1.627z"
          transform="translate(-201.907 -25.433)"
        />
        <path
          id="Path_301"
          data-name="Path 301"
          className="mastercard-2"
          d="M227.9 43.327h3.294V42.7H228l-.1.627z"
          transform="translate(-206.727 -29.107)"
        />
        <path
          id="Path_302"
          data-name="Path 302"
          className="mastercard-2"
          d="M228.4 40.627h3.032V40H228.5l-.1.627z"
          transform="translate(-206.965 -27.819)"
        />
        <path
          id="Path_303"
          data-name="Path 303"
          className="mastercard-2"
          d="M217.4 40h.836v.627h-.784L217.4 40z"
          transform="translate(-201.716 -27.819)"
        />
        <path
          id="Path_304"
          data-name="Path 304"
          className="mastercard-4"
          d="M211.246 37.593h-.052l-.052-.052h-.314l-.052-.052h-.476l-.1.052h-.261l-.052.052h-.052l-.052.052-.052.052v.052h-.052v.261l.052.052.052.052.052.052.1.1.1.052h.1l.157.052.1.052.157.052.1.052.157.052.1.1.1.1.052.1.052.157.052.157v.68l-.052.157-.1.157-.052.1-.1.1-.1.1-.1.1-.1.052-.157.1-.1.052h-.157l-.1.052h-.157l-.157.052h-.941l-.1-.052h-.366l-.052-.052h-.157l-.052-.052H208l-.1-.052.209-.836h.1l.1.052h.157l.052.052h.314l.1.052h.68l.1-.052.052-.052.052-.052.052-.052.052-.052v-.157l.052-.1-.052-.1-.052-.052-.1-.052-.157-.1-.1-.052-.157-.052-.157-.052-.147-.029-.157-.1-.157-.1-.1-.1-.1-.157-.052-.209-.052-.209V37.7l.052-.1.052-.157.052-.1.052-.157.052-.1.1-.1.1-.052.1-.1.1-.052.157-.052.157-.052h.157l.209-.052h1.307l.052.052h.261l.052.052-.209.889z"
          transform="translate(-197.183 -26.196)"
        />
        <path
          id="Path_305"
          data-name="Path 305"
          className="mastercard-5"
          d="M211.246 37.593h-.052l-.052-.052h-.314l-.052-.052h-.476l-.1.052h-.261l-.052.052h-.052l-.052.052-.052.052v.052h-.052v.261l.052.052.052.052.052.052.1.1.1.052h.1l.157.052.1.052.157.052.1.052.157.052.1.1.1.1.052.1.052.157.052.157V39.872l-.052.157-.1.157-.052.1-.1.1-.1.1-.1.1-.1.052-.157.1-.1.052h-.157l-.1.052h-.157l-.157.052h-.941l-.1-.052h-.366l-.052-.052h-.157l-.052-.052H208l-.1-.052.209-.836h.1l.1.052h.157l.052.052h.314l.1.052h.68l.1-.052.052-.052.052-.052.052-.052.052-.052v-.157l.052-.1-.052-.1-.052-.052-.1-.052-.157-.1-.1-.052-.157-.052-.157-.052-.147-.029-.157-.1-.157-.1-.1-.1-.1-.157-.052-.209-.052-.209V37.7l.052-.1.052-.157.052-.1.052-.157.052-.1.1-.1.1-.052.1-.1.1-.052.157-.052.157-.052h.157l.209-.052h1.307l.052.052h.261l.052.052-.209.889"
          transform="translate(-197.183 -26.196)"
        />
        <path
          id="Path_306"
          data-name="Path 306"
          className="mastercard-4"
          d="M215.384 34.9h1.1l-.157.941H217l-.157.889h-.68l-.418 2.2v.1l.052.052.052.052v.052h.1l.052.052h.523l.052-.052h.052l-.157.732-.052.052h-.052v.052h-.157l-.052.052h-1.046l-.1-.052-.1-.052h-.052l-.1-.052-.052-.052-.052-.052v-.157l-.052-.052V39.4l.052-.052v-.1l.732-4.339z"
          transform="translate(-200.38 -25.385)"
        />
        <path
          id="Path_307"
          data-name="Path 307"
          className="mastercard-5"
          d="M215.384 34.9h1.1l-.157.941H217l-.157.889h-.68l-.418 2.2v.1l.052.052.052.052v.052h.1l.052.052h.523l.052-.052h.052l-.157.732-.052.052h-.052v.052h-.157l-.052.052h-1.046l-.1-.052-.1-.052h-.052l-.1-.052-.052-.052-.052-.052v-.157l-.052-.052V39.4l.052-.052v-.1l.732-4.339"
          transform="translate(-200.38 -25.385)"
        />
        <path
          id="Path_308"
          data-name="Path 308"
          className="mastercard-5"
          d="M219.8 39.057l-.052.052v.157l.052.1v.052l.052.052.052.1.052.052.052.052.052.052.052.1.052.052h.1l.052.052.1.052h.261l.1.052h.523l.1-.052h.1l.1-.052h.1l.157-.052.1-.052.1-.052.1-.052-.157.941v.067h-.052l-.1.052h-.052l-.052.052h-.1l-.1.052h-1.359l-.209-.052-.157-.1-.209-.052-.157-.1-.209-.1-.157-.157-.1-.157-.1-.157-.1-.209-.052-.261-.052-.261-.083-.28.052-.366v-.1l.052-.157v-.157l.052-.157.052-.157.1-.209.052-.157.1-.157.157-.157.157-.157.157-.157.209-.1.209-.1.261-.052.314-.052h.627l.157.052h.157l.157.052.157.1.157.1.1.1.157.1.1.157.052.209.052.209.052.209v.314l-.052.261-.052.366v.209h-2.928l.209-.784h1.725v-.366l-.052-.052V37.8l-.052-.052-.052-.052-.052-.052-.052-.052-.052-.052H221l-.052-.052h-.148l-.052-.052-.052.052h-.157l-.052.052h-.1l-.052.052-.052.052-.052.052-.052.052-.052.052-.052.052v.052l-.052.1-.052.052v.157l-.157.836"
          transform="translate(-202.289 -26.196)"
        />
        <path
          id="Path_309"
          data-name="Path 309"
          className="mastercard-4"
          d="M235.278 35.912l-.052-.052h-.052l-.052-.052h-.1l-.052-.052h-.052l-.052-.052h-.166l-.1-.052h-.209l-.1-.052h-.314l-.1.052h-.209l-.1.052-.1.052-.1.052-.1.052-.157.1-.052.1-.1.1-.1.157-.1.157-.052.209-.1.209-.052.261-.052.157v.523l.052.157v.1l.052.1.052.1.1.1.052.1.1.1.1.052.157.052.1.052.157.052h.209l.052.052h.366l.1-.052h.1l.1-.052h.1l.1-.052.1-.052.052-.052.1-.1-.209 1.1v.052h-.052l-.052.052h-.052l-.052.052h-.052l-.052.052h-.1l-.1.052h-1.2l-.157-.052-.209-.052-.157-.1-.209-.1-.157-.1-.157-.1-.157-.157-.1-.209-.1-.209-.1-.209-.1-.261v-.261l-.052-.261v-.299l.052-.314.052-.157v-.157l.052-.157.052-.157.052-.157.052-.1.052-.157.052-.1.052-.157.052-.1.052-.1.1-.1.052-.1.1-.1.052-.052.1-.1.1-.1.052-.052.1-.052.1-.052.1-.1.1-.052h.1l.1-.052.157-.052.1-.052h.157l.1-.052h.261l.157-.052h.314l.1.052h.366l.1.052h.1l.052.052h.1l.1.052h.052l.052.052h.1l.052.052-.157 1.1z"
          transform="translate(-208.302 -25.194)"
        />
        <path
          id="Path_310"
          data-name="Path 310"
          className="mastercard-5"
          d="M235.278 35.912l-.052-.052h-.052l-.052-.052h-.1l-.052-.052h-.052l-.052-.052h-.166l-.1-.052h-.209l-.1-.052h-.314l-.1.052h-.209l-.1.052-.1.052-.1.052-.1.052-.157.1-.052.1-.1.1-.1.157-.1.157-.052.209-.1.209-.052.261-.052.157v.523l.052.157v.1l.052.1.052.1.1.1.052.1.1.1.1.052.157.052.1.052.157.052h.209l.052.052h.366l.1-.052h.1l.1-.052h.1l.1-.052.1-.052.052-.052.1-.1-.209 1.1v.052h-.052l-.052.052h-.052l-.052.052h-.052l-.052.052h-.1l-.1.052h-1.2l-.157-.052-.209-.052-.157-.1-.209-.1-.157-.1-.157-.1-.157-.157-.1-.209-.1-.209-.1-.209-.1-.261v-.261l-.052-.261v-.299l.052-.314.052-.157v-.157l.052-.157.052-.157.052-.157.052-.1.052-.157.052-.1.052-.157.052-.1.052-.1.1-.1.052-.1.1-.1.052-.052.1-.1.1-.1.052-.052.1-.052.1-.052.1-.1.1-.052h.1l.1-.052.157-.052.1-.052h.157l.1-.052h.261l.157-.052h.314l.1.052h.366l.1.052h.1l.052.052h.1l.1.052h.052l.052.052h.1l.052.052-.157 1.1"
          transform="translate(-208.302 -25.194)"
        />
        <path
          id="Path_311"
          data-name="Path 311"
          className="mastercard-4"
          d="M254.36 40.076h-1.15l.1-.418v-.052l-.052.1-.052.052-.1.052-.052.052-.1.052-.052.052-.052.052-.1.052h-.052l-.1.052h-.209l-.052.052h-.209l-.157-.052-.209-.052-.157-.052-.157-.1-.157-.1-.157-.1-.1-.157-.1-.157-.052-.209-.052-.157-.052-.209-.052-.209v-.627l.052-.157.052-.261.1-.209.052-.209.1-.209.1-.157.1-.157.157-.157.1-.1.157-.157.157-.052.157-.1.1-.052.209-.1h.157l.157-.052h.261l.1.052h.209l.1.052.052.052.1.052h.052l.052.052.052.052.052.052.052.052.052.052.052.052v.052l.052.052.261-1.568h1.1l-.889 5.176z"
          transform="translate(-217.607 -25.385)"
        />
        <path
          id="Path_312"
          data-name="Path 312"
          className="mastercard-5"
          d="M254.36 40.076h-1.15l.1-.418v-.052l-.052.1-.052.052-.1.052-.052.052-.1.052-.052.052-.052.052-.1.052h-.052l-.1.052h-.209l-.052.052h-.209l-.157-.052-.209-.052-.157-.052-.157-.1-.157-.1-.157-.1-.1-.157-.1-.157-.052-.209-.052-.157-.052-.209-.052-.209v-.627l.052-.157.052-.261.1-.209.052-.209.1-.209.1-.157.1-.157.157-.157.1-.1.157-.157.157-.052.157-.1.1-.052.209-.1h.157l.157-.052h.261l.1.052h.209l.1.052.052.052.1.052h.052l.052.052.052.052.052.052.052.052.052.052.052.052v.052l.052.052.261-1.568h1.1l-.889 5.176"
          transform="translate(-217.607 -25.385)"
        />
        <path
          id="Path_313"
          data-name="Path 313"
          className="mastercard-2"
          d="M253.675 40.853h.209l.1-.052.1-.052.052-.052.1-.052.052-.052.1-.1.052-.052.052-.1.052-.052.052-.1v-.1l.052-.1.052-.1v-.239l.052-.157v-.1l-.052-.1v-.209l-.052-.052v-.1l-.052-.1-.052-.052-.052-.052-.052-.052-.052-.052-.1-.052h-.052l-.1-.052-.1.052h-.1l-.052.052h-.1l-.1.052-.052.1-.052.052-.1.052-.052.1-.052.1-.052.052-.052.1-.052.1v.157l-.052.1v.1l-.052.157.052.209v.209l.052.157.1.157.1.157.1.1z"
          transform="translate(-218.752 -27.103)"
        />
        <path
          id="Path_314"
          data-name="Path 314"
          className="mastercard-4"
          d="M204.307 40.05l-.157.836h-.993v-.156l.052-.1v-.209l-.052.1-.052.052-.052.052-.1.052-.1.052-.1.052-.1.052-.1.052h-.1l-.1.052h-.1l-.157.052h-.314l-.1-.052h-.157l-.1-.052-.1-.052-.1-.052-.1-.1-.052-.052-.1-.052-.052-.1-.052-.052v-.1l-.052-.1v-.052l-.052-.1v-.418l.052-.157v-.157l.052-.1.052-.157.1-.1.052-.1.1-.1.1-.1.1-.052.1-.1.157-.052.1-.052.157-.052.1-.052h.366l.052-.052h.471l.052-.052h.1l.1.052h.366v-.052l.052-.052v-.261l-.052-.052-.052-.1-.052-.052-.052-.052h-.1l-.1-.052h-.941l-.157.052h-.24l-.1.052h-.1l-.1.052h-.052l-.052.052h-.052l.261-.941.1-.052h.1l.1-.052h.052l.1-.052h.314l.1-.052h.889l.157.052h.209l.157.052.1.052.1.052.157.052.1.1.1.052.052.1.1.1.052.157.052.1v.471l-.418 2.091z"
          transform="translate(-193.747 -26.196)"
        />
        <path
          id="Path_315"
          data-name="Path 315"
          className="mastercard-5"
          d="M204.307 40.05l-.157.836h-.993v-.156l.052-.1v-.209l-.052.1-.052.052-.052.052-.1.052-.1.052-.1.052-.1.052-.1.052h-.1l-.1.052h-.1l-.157.052h-.314l-.1-.052h-.157l-.1-.052-.1-.052-.1-.052-.1-.1-.052-.052-.1-.052-.052-.1-.052-.052v-.1l-.052-.1v-.052l-.052-.1V39.655l.052-.157v-.157l.052-.1.052-.157.1-.1.052-.1.1-.1.1-.1.1-.052.1-.1.157-.052.1-.052.157-.052.1-.052h.366l.052-.052h.471l.052-.052h.1l.1.052h.366v-.052l.052-.052v-.257l-.052-.052-.052-.1-.052-.052-.052-.052h-.1l-.1-.052h-.941l-.157.052h-.24l-.1.052h-.1l-.1.052h-.052l-.052.052h-.052l.261-.941.1-.052h.1l.1-.052h.052l.1-.052h.314l.1-.052h.889l.157.052h.209l.157.052.1.052.1.052.157.052.1.1.1.052.052.1.1.1.052.157.052.1v.471l-.418 2.091"
          transform="translate(-193.747 -26.196)"
        />
        <path
          id="Path_316"
          data-name="Path 316"
          className="mastercard-3"
          d="M204.464 41.1v-.052h-.157l-.052-.048h-.1l-.052.052h-.314l-.052.052h-.1l-.052.052h-.1l-.052.052h-.052l-.052.052-.052.052-.052.052h-.052l-.052.052v.052l-.052.052v.157l-.052.1.052.1.052.1.052.052.052.052h.052l.052.052h.209l.1-.052h.157l.1-.052.052-.052h.1l.052-.052.052-.052.052-.052.052-.052v-.052h.052v-.1l.052-.052v-.1l.052-.157v-.1l.052-.1z"
          transform="translate(-194.844 -28.296)"
        />
        <path
          id="Path_317"
          data-name="Path 317"
          className="mastercard-4"
          d="M190.646 40.076H189.6l.889-5.176h1.882l.157 3.084 1.307-3.084h1.987l-.941 5.176h-1.1l.68-3.869h-.052l-1.568 3.869h-1.255V39.5l-.052-.627-.052-.68-.052-.732v-.627l-.052-.418v-.209h-.052l-.68 3.869z"
          transform="translate(-188.45 -25.385)"
        />
        <path
          id="Path_318"
          data-name="Path 318"
          className="mastercard-5"
          d="M190.646 40.076H189.6l.889-5.176h1.882l.157 3.084 1.307-3.084h1.987l-.941 5.176h-1.1l.68-3.869h-.052l-1.568 3.869h-1.255V39.5l-.052-.627-.052-.68-.052-.732v-.627l-.052-.418v-.209h-.052l-.68 3.869"
          transform="translate(-188.45 -25.385)"
        />
        <path
          id="Path_319"
          data-name="Path 319"
          className="mastercard-4"
          d="M242.007 40.05l-.157.836h-.993v-.418l-.052.052-.052.052-.1.052-.052.052-.1.052-.052.052-.1.052-.1.052h-.1l-.1.052h-.157l-.1.052h-.314l-.1-.052h-.157l-.1-.052-.1-.052-.1-.052-.1-.1-.1-.052-.052-.052-.052-.1-.052-.052v-.1l-.052-.1v-.052l-.052-.1v-.418l.052-.157v-.157l.052-.1.052-.157.1-.1.052-.1.1-.1.1-.1.1-.052.1-.1.157-.052.1-.052.157-.052.1-.052h.366l.052-.052h.418l.052-.052h.157l.1.052h.366v-.1l.052-.1v-.052l-.052-.1V37.8l-.052-.1-.052-.052-.1-.052h-.052l-.1-.052h-.941l-.157.052h-.24l-.1.052h-.1l-.1.052h-.052l-.052.052h-.052l.261-.941.1-.052h.1l.1-.052h.052l.1-.052h.314l.1-.052h.889l.1.052h.261l.1.052.157.052.1.052.1.052.1.1.1.052.1.1.1.1.052.157.052.1v.471l-.418 2.091z"
          transform="translate(-211.737 -26.196)"
        />
        <path
          id="Path_320"
          data-name="Path 320"
          className="mastercard-4"
          d="M244.952 44.089l.157-.889h-.052l-.157.836h.052v.052z"
          transform="translate(-214.839 -29.346)"
        />
        <path
          id="Path_321"
          data-name="Path 321"
          className="mastercard-4"
          d="M243 44.8l.052.052h.993V44.8H243v.052h.052L243 44.8z"
          transform="translate(-213.933 -30.109)"
        />
        <path
          id="Path_322"
          data-name="Path 322"
          className="mastercard-4"
          d="M243.052 43.952V43.9L243 44v.366h.052v-.418z"
          transform="translate(-213.933 -29.68)"
        />
        <path
          id="Path_323"
          data-name="Path 323"
          className="mastercard-4"
          d="M240.2 44.475h.523l.157-.052h.1l.1-.052.1-.052.1-.052h.1l.052-.1.1-.052.052-.052.052-.052.052-.052V43.9l-.052.1-.052.052-.1.052-.052.052-.1.052-.1.052h-.1l-.052.052-.1.052h-.157l-.1.052h-.523v.052z"
          transform="translate(-212.596 -29.68)"
        />
        <path
          id="Path_324"
          data-name="Path 324"
          className="mastercard-4"
          d="M238.3 42.7v.052l.052.1v.209l.052.052v.1l.052.1.052.052.052.1.052.052.1.1.052.052.1.052.157.052.1.052.157.052v-.027l-.157-.052-.1-.052-.1-.052-.1-.052-.1-.052-.052-.052-.052-.1-.052-.052-.052-.1-.052-.052-.052-.1v-.261l-.052-.1V42.7h-.057z"
          transform="translate(-211.69 -29.107)"
        />
        <path
          id="Path_325"
          data-name="Path 325"
          className="mastercard-4"
          d="M239.764 39.8h-.157l-.157.052-.1.052-.157.052-.1.052-.1.052-.1.1-.1.1-.1.1-.1.1-.052.1-.052.157-.052.1-.052.157v.157l-.052.157h.052l.052-.157V41l.052-.157.052-.1.052-.157.052-.1.1-.1.052-.1.1-.052.1-.1.157-.052.1-.1.1-.052h.157l.1-.052.157-.052z"
          transform="translate(-211.69 -27.723)"
        />
        <path
          id="Path_326"
          data-name="Path 326"
          className="mastercard-4"
          d="M241.884 39.6h-.314l-.1.052h-.37v.048h.366l.1-.052h.314V39.6z"
          transform="translate(-213.026 -27.628)"
        />
        <path
          id="Path_327"
          data-name="Path 327"
          className="mastercard-4"
          d="M243.175 39.652V39.6h-.575v.052h.575z"
          transform="translate(-213.742 -27.628)"
        />
        <path
          id="Path_328"
          data-name="Path 328"
          className="mastercard-4"
          d="M243.7 39.2v.261l.052-.052V39.2h-.052z"
          transform="translate(-214.267 -27.437)"
        />
        <path
          id="Path_329"
          data-name="Path 329"
          className="mastercard-4"
          d="M242.8 38.352l.1.052h.1l.052.052.052.052.052.052.052.052.052.052v.1h.052v-.1l-.052-.052-.052-.1-.052-.052-.052-.052-.1-.052h-.1l-.1-.052v.052z"
          transform="translate(-213.837 -27.008)"
        />
        <path
          id="Path_330"
          data-name="Path 330"
          className="mastercard-4"
          d="M239.6 38.561v.052l.052-.052h.052l.1-.052h.052l.1-.052h.209l.1-.052h.261l.157-.052h.575l-.1-.052h-.358l-.1.052h-.418l-.1.052h-.209l-.1.052h-.1l-.052.052h-.052l-.052.052z"
          transform="translate(-212.31 -27.008)"
        />
        <path
          id="Path_331"
          data-name="Path 331"
          className="mastercard-4"
          d="M239.861 36.9v.052l-.261.941h.052l.261-.941-.052-.052z"
          transform="translate(-212.31 -26.34)"
        />
        <path
          id="Path_332"
          data-name="Path 332"
          className="mastercard-4"
          d="M241.878 36.6h-1.1l-.1.052h-.209l-.052.052h-.1l-.1.052h-.1l.052.052h.052l.1-.052.1-.052h.157l.1-.052h1.2V36.6z"
          transform="translate(-212.549 -26.196)"
        />
        <path
          id="Path_333"
          data-name="Path 333"
          className="mastercard-4"
          d="M244.859 37.959l.052-.157v-.157l-.052-.157-.052-.157-.052-.1-.1-.1-.052-.1-.1-.1-.1-.052-.157-.052-.1-.052-.157-.052-.1-.052-.1-.052h-.289v.052h.209l.157.052.1.052h.1l.157.1.1.052.1.052.1.1.1.1.052.1.052.1.052.1.052.157v.164l-.052.1.052.052z"
          transform="translate(-214.171 -26.196)"
        />
        <path
          id="Path_334"
          data-name="Path 334"
          className="mastercard-4"
          d="M245.252 41.243l.418-2.091-.052-.052-.418 2.143h.052z"
          transform="translate(-214.982 -27.389)"
        />
        <path
          id="Path_335"
          data-name="Path 335"
          className="mastercard-2"
          d="M242.159 41.1v-.052h-.052l-.052-.048h-.155l-.1.052h-.261l-.052.052h-.157l-.052.052h-.052l-.052.052h-.052l-.052.052h-.052l-.052.052-.052.052v.052l-.052.052-.052.052v.366l.052.1v.052l.1.052h.052l.052.052h.209l.1-.052h.1l.1-.052h.052l.1-.052.052-.052.052-.052h.052l.052-.052.052-.052.052-.052v-.046l.052-.052v-.1l.052-.1V41.1z"
          transform="translate(-212.883 -28.296)"
        />
        <path
          id="Path_336"
          data-name="Path 336"
          className="mastercard-4"
          d="M259.1 43.323v-.209l.1-.1.052-.052.052-.052.1-.052.1-.052h.209l.1.052.1.052.1.052.052.052.052.1.052.1v.1h-.1v-.052l-.052-.1-.052-.052-.052-.1-.052-.052-.052-.052h-.366l-.1.052-.052.052-.052.1v.052l-.052.1v.052z"
          transform="translate(-221.616 -29.155)"
        />
        <path
          id="Path_337"
          data-name="Path 337"
          className="mastercard-4"
          d="M259.8 43.609V43.4h.366v.1h.052l-.052.052v.052h-.1l.052-.052v-.104h-.261v.157z"
          transform="translate(-221.95 -29.441)"
        />
        <path
          id="Path_338"
          data-name="Path 338"
          className="mastercard-4"
          d="M259.1 43.8v.261l.1.1.052.052.052.052.1.052.1.052h.209l.1-.052.1-.052.1-.052.052-.052.052-.1.052-.1V43.8h-.1v.1l-.052.1-.052.052-.052.1-.052.052-.052.052h-.366l-.1-.052-.052-.052-.052-.1V44l-.052-.1v-.1z"
          transform="translate(-221.616 -29.632)"
        />
        <path
          id="Path_339"
          data-name="Path 339"
          className="mastercard-4"
          d="M259.8 43.8v.261h.052v-.209h.1l.157.209h.052l-.1-.209h.052l.052-.052h-.365z"
          transform="translate(-221.95 -29.632)"
        />
        <path
          id="Path_340"
          data-name="Path 340"
          className="mastercard-5"
          d="M259.1 43.375v-.1l.052-.1.052-.1.052-.1.1-.052.1-.052.1-.052h.209l.1.052.1.052.1.052.052.1.052.1.052.1v.2l-.052.1-.052.1-.052.1-.1.052-.1.052-.1.052h-.209l-.1-.052-.1-.052-.1-.052-.052-.1-.052-.1-.052-.1v-.1m.418.209h-.1v-.471h.418v.2h-.1l.1.209h-.1l-.1-.209h-.1v.261m.1-.261h.052v-.052h-.1v.157h.052m0 .366h.1l.1-.052.052-.052.052-.052.052-.052.052-.052v-.418l-.052-.1-.052-.052-.052-.052-.052-.052-.1-.052h-.209l-.1.052-.052.052-.052.052-.052.052-.052.1v.418l.052.052.052.052.052.052.052.052.1.052h.1"
          transform="translate(-221.616 -29.155)"
        />
        <path
          id="Path_341"
          data-name="Path 341"
          className="mastercard-4"
          d="M245.8 40.887h1.15l.366-2.248.052-.052v-.1l.052-.1v-.052l.052-.1.052-.1.052-.052.052-.1.1-.052.052-.052.1-.052.1-.052.1-.052.1-.052h.418l.157-1.046-.1.052h-.209l-.1.052h-.052l-.1.052-.052.052-.1.052-.052.052-.052.052-.1.052-.052.052-.052.1-.052.052-.052.052-.052.1.1-.732h-1.1l-.784 4.235z"
          transform="translate(-215.269 -26.196)"
        />
        <path
          id="Path_342"
          data-name="Path 342"
          className="mastercard-5"
          d="M245.8 40.887h1.15l.366-2.248.052-.052v-.1l.052-.1v-.052l.052-.1.052-.1.052-.052.052-.1.1-.052.052-.052.1-.052.1-.052.1-.052.1-.052h.418l.157-1.046-.1.052h-.209l-.1.052h-.052l-.1.052-.052.052-.1.052-.052.052-.052.052-.1.052-.052.052-.052.1-.052.052-.052.052-.052.1.1-.732h-1.1l-.784 4.235"
          transform="translate(-215.269 -26.196)"
        />
        <path
          id="Path_343"
          data-name="Path 343"
          className="mastercard-4"
          d="M225.7 40.887h1.15l.366-2.248.052-.052v-.1l.052-.1v-.052l.052-.1v-.052l.052-.052.052-.1.1-.052.052-.052.1-.052.052-.052h.157l.1-.052h.157l.157-.052v.052h.1V37.6l.052-.052v-.1l.052-.157.052-.1v-.1l.052-.052.052-.1v-.052l.052-.052.052-.1.052-.052h-.1l-.052-.052h-.052l-.1.052h-.209l-.1.052h-.1l-.052.052-.1.052-.052.052-.052.052-.1.052-.052.052-.052.052-.052.052-.052.1-.052.052-.052.1.1-.732h-1.1l-.784 4.235z"
          transform="translate(-205.677 -26.196)"
        />
        <path
          id="Path_344"
          data-name="Path 344"
          className="mastercard-5"
          d="M225.7 40.887h1.15l.366-2.248.052-.052v-.1l.052-.1v-.052l.052-.1v-.052l.052-.052.052-.1.1-.052.052-.052.1-.052.052-.052h.157l.1-.052h.157l.157-.052v.052h.1V37.6l.052-.052v-.1l.052-.157.052-.1v-.1l.052-.052.052-.1v-.052l.052-.052.052-.1.052-.052h-.1l-.052-.052h-.052l-.1.052h-.209l-.1.052h-.1l-.052.052-.1.052-.052.052-.052.052-.1.052-.052.052-.052.052-.052.052-.052.1-.052.052-.052.1.1-.732h-1.1l-.784 4.235"
          transform="translate(-205.677 -26.196)"
        />
        <path
          id="Path_345"
          data-name="Path 345"
          className="mastercard-2"
          d="M259.1 52.418v-.1l.1-.1.052-.052.052-.052.1-.052.1-.052h.209l.1.052.1.052.1.052.052.052.052.1.052.1h-.1l-.052-.052-.052-.1-.052-.052-.052-.052-.052-.052h-.1l-.1-.052-.052.052h-.1l-.1.052-.052.052-.052.052v.1l-.052.052z"
          transform="translate(-221.616 -33.545)"
        />
        <path
          id="Path_346"
          data-name="Path 346"
          className="mastercard-2"
          d="M259.8 52.7v-.1h.366v.1h-.052v-.052h-.261v.052z"
          transform="translate(-221.95 -33.832)"
        />
        <path
          id="Path_347"
          data-name="Path 347"
          className="mastercard-2"
          d="M259.1 52.9v-.1h.1v.1z"
          transform="translate(-221.616 -33.927)"
        />
        <path
          id="Path_348"
          data-name="Path 348"
          className="mastercard-2"
          d="M259.8 52.9v-.1h.052v.1h.209v-.052h.052V52.8h.1l-.052.052v.048h-.361z"
          transform="translate(-221.95 -33.927)"
        />
        <path
          id="Path_349"
          data-name="Path 349"
          className="mastercard-2"
          d="M261 52.9v-.1h.1v.1z"
          transform="translate(-222.522 -33.927)"
        />
        <path
          id="Path_350"
          data-name="Path 350"
          className="mastercard-2"
          d="M259.1 53v.209l.1.1.052.1.052.052.1.052.1.052h.209l.1-.052.1-.052.1-.052.052-.1.052-.1.052-.1V53h-.1v.1l-.052.1-.052.052-.052.052-.052.1h-.052l-.1.052h-.157l-.1-.052h-.1l-.052-.1-.052-.052V53.2l-.052-.1V53h-.1z"
          transform="translate(-221.616 -34.023)"
        />
        <path
          id="Path_351"
          data-name="Path 351"
          className="mastercard-2"
          d="M259.8 53v.209h.052v-.157h.1l.157.157h.052l-.1-.157.052-.052h-.313z"
          transform="translate(-221.95 -34.023)"
        />
        <path
          id="Path_352"
          data-name="Path 352"
          className="mastercard-4"
          d="M222.612 37.859l-.052-.261-.052-.209-.1-.157-.1-.157-.1-.157-.1-.1-.157-.1-.157-.052-.157-.052-.157-.052-.18-.062h-.627l-.261.052-.261.052-.209.1-.209.1-.157.157-.157.157-.1.157-.1.157-.1.157-.052.157-.052.157-.052.157-.052.157v.889l.052.261.052.209.1.209.1.157.157.157.157.157.157.1.157.1.209.052.209.052.209.052.209.052h1.568l.052-.052.052-.052.157-.993-.1.052-.1.052-.1.052-.1.052-.1.052h-1.278l-.1-.052-.1-.052-.052-.052-.052-.052-.052-.052-.052-.052-.052-.052-.052-.052-.052-.052-.052-.052-.052-.052v-.209h2.562V38.8l.052-.314.052-.314zm-1.1.366h-1.462v-.261l.052-.1.052-.052.052-.052.052-.052.052-.052.052-.052.052-.052.052-.052.052-.052.052-.052h.418l.052.052.052.052.052.052.052.052.052.052.052.052.052.052.052.052v.314z"
          transform="translate(-202.432 -26.149)"
        />
      </g>
    </g>
  </svg>
)

export default SvgMastercardIcon
