import Swal from 'sweetalert2/dist/sweetalert2'

/* FOR ERRORS
  NOTE: we expect the error caught from axios / our api is the response from the error.

  Example:
  alertModal(err.response, 'error', 'Try Again');
  The response object is expected to contain a data property with either an error or errors

  In some instances we can also pass in an Exception like
  error = new Error("Message Here");
  alertModal(error, 'error', 'Try Again');

  Both of the cases above are caught below and normalized.
*/
const alertModal = (response, modalType = 'success', actionText = 'Ok') => {
  console.log('response', response)
  const { data } = response

  const hasData = typeof data !== 'undefined'
  const erorrsInData = hasData && typeof data.errors !== 'undefined'
  const errorInData = hasData && typeof data.error !== 'undefined'

  const dataHasMessages =
    erorrsInData && typeof data.errors.full_messages !== 'undefined'

  const isError =
    erorrsInData || modalType !== 'success' || response instanceof Error

  let title = isError ? 'Oops...' : 'Success!'

  let message
  if (isError && response instanceof Error) {
    /* eslint-disable-next-line prefer-destructuring */
    message = response.message
  } else if (isError && dataHasMessages) {
    const { full_messages: fullMessages } = data.errors
    message = fullMessages.join(', ')
  } else if (isError) {
    if (erorrsInData) {
      message = data ? data.errors : ''
    } else if (errorInData) {
      message = data ? data.error : ''
    } else if (typeof response.message !== 'undefined') {
      message = response.message
    } else {
      message = response
    }
  } else {
    message = response
  }

  if (response === 'Request failed with status code 401') {
    title = 'Your session expired.'
    message =
      'If you continue having trouble, please contact support@breakbread.com'
  }
  // SweetAlert2
  const sweetness = Swal.mixin({
    confirmButtonClass: 'btn',
    cancelButtonClass: 'btn btn-danger',
    showCloseButton: true,
    buttonsStyling: false,
    confirmButtonText: actionText
  })

  return sweetness({
    type: modalType,
    title,
    text: message,
    footer:
      '<a target="_blank" href="mailto:support@breakbread.com">Having trouble? Click here to contact support.</a>'
  })
}

export default alertModal
