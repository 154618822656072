import React, { useState } from 'react'
import moment from 'moment'
import StarRatingComponent from 'react-star-rating-component'
import PropTypes from 'prop-types'
import ReactPlaceholder from 'react-placeholder'
import UserCard from '../User/UserCard'
import ChefAvtarHost from '../Chef/ChefAvtarHost'
import ChefInfo from '../Chef/ChefInfo'

const Review = ({ review, user, host, addPlaceholder, byMe, handleReadMore, readMoreFlag, maxCharCountReview, actions }) => {
  const ready = () => {
    if (!review.experience) {
      return true
    }
    return review.experience.hasReviewed || !addPlaceholder
  }
  return (
    <div className="reviews--item" key={review.id}>
      {/* <div className="reviews--item-meta">
        <div className="reviews--item-date">
          {review.experience.title} &#8226;{' '}
          {moment.utc(review.datePosted).format('LL')}
        </div>
        <div
          className="reviews--item-rating"
          style={{ width: '100%', maxWidth: 320 }}
        >
          <ReactPlaceholder
            showLoadingAnimation
            type="text"
            ready={ready()}
            rows={1}
            color="#E0E0E0"
          >
            <span className="reviews--item-rating-label">
              <span className="color">Rated it</span>
              <span className="number">{review.star}.0</span>
            </span>
            <StarRatingComponent
              name="rate1"
              starCount={5}
              value={review.star}
              onStarClick={args => {}}
            />
          </ReactPlaceholder>
        </div>
      </div> */}

      <div className="reviews--item-body">
        <div className="reviews--item-user">
          {!byMe ? (
            user ? (
              <div>
                <ChefAvtarHost displayInfo={false} parentComponent={"reviewItem"} person={user} hideLink={user.hostApplication !== 'approved'} />
                {/* <ChefInfo
                    displaySpecialties={false}
                    person={user}
                  /> */}
                {/* <UserCard person={user} /> */}
                <p className="text-center">
                  <span style={{ color: '#f8a48d'}}>{user ? user.fullname || user.name : host.fullname || host.name}</span>
                </p>
              </div>
            ) : (
              <ChefAvtarHost person={host} parentComponent={"reviewItem"} hideLink={host.hostApplication !== 'approved'} />
            )
          ) : null}
        </div>
        <div className="reviews--item-content customReview">
          {/* <h5
            style={{
              fontFamily: `"Roboto", Helvetica, "Helvetica Neue", "Arial Black", Gadget, sans-serif`
            }}
          >
             {review.experience.title} &#8226;{' '}
             {`(${moment.utc(review.datePosted).format('LL')})`} */}
          {/* {user ? user.fullname || user.name : host.fullname || host.name} */}
          {/* </h5> */}
          <StarRatingComponent name="rate1" starCount={5} value={review.star?Number(review.star):0} onStarClick={(args) => {}} />
          {!ready() && (
            <React.Fragment>
              {/* <p>
                You must review the experience and host before you can see what
                they said about you.
              </p> */}
              <div
                style={{
                  position: 'absolute',
                  background: 'white',
                  zIndex: '99',
                  border: '1 px solid',
                  padding: '41px 30px',
                  marginLeft: '21%',
                  marginTop: '-4%',
                  boxShadow: '1px 2px 3px 4px #E0E0E0',
                  color: 'black',
                  lineHeight: '1.5em'
                }}
              >
                <span>Host has reviewed you!</span> <br />
                <span style={{ cursor: 'pointer', color: 'black' }}>
                  <b>
                    <u>Add your review</u>
                  </b>
                </span>{' '}
                to read it.
              </div>
            </React.Fragment>
          )}
          <ReactPlaceholder type="text" showLoadingAnimation rows={3} ready={ready()} color="#E0E0E0">
            <blockquote className="sidekick" style={{ padding: 0 }}>
              {/* {review.comment.length > 200 && !readMoreFlag? review.comment.slice(0, 197) + '...': review.comment}{' '}
              {review.comment.length > 200 && <span style={{cursor: 'pointer', fontWeight: 'bold', fontStyle: 'italic' }} onClick={()=>{ handleReadMore(review) }}>{ !readMoreFlag?'read more':'less more'}</span>} */}
              {review.comment.length > maxCharCountReview ? review.comment.slice(0, maxCharCountReview - 3) + '...': review.comment}{' '}
              {review.comment.length > maxCharCountReview && <span style={{cursor: 'pointer', fontWeight: 'bold', fontStyle: 'italic' }} onClick={() => actions.showReviewCommenModal({ comment: review.comment })}>read more</span>}

            </blockquote>
            <p className="date_time_review">{moment(review.datePosted).format('DD MMM YYYY')}</p>
            { /*{review.comment.length > 200 && (
              <div className="form-action">
                <button className="btn btn--darker" onClick={() => actions.showReviewCommenModal({ comment: review.comment })}>
                  Read More
                </button>
              </div>
            )} */}
          </ReactPlaceholder>
        </div>
      </div>
    </div>
  )
}

Review.defaultProps = {
  user: null,
  host: null,
  addPlaceholder: false
}

Review.propTypes = {
  review: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}),
  host: PropTypes.shape({}),
  addPlaceholder: PropTypes.bool
}

export default Review
