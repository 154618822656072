// https://stackoverflow.com/questions/35623656/how-can-i-display-a-modal-dialog-in-redux-that-performs-asynchronous-actions/35641680

import {
  SHOW_MODAL,
  HIDE_MODAL,
  AUTH,
  REG,
  SUBCRIPTION,
  BECOME_HOST,
  HOST_CONFIRMATION,
  USER_CONFIRMATION,
  RESERVATION,
  EXPERIENCE,
  HOST_REVIEW,
  GUEST_REVIEW,
  RECEIPT,
  EXPERIENCE_SCHEDULE,
  EXPERIENCE_SCHEDULE_FORM,
  SHOW_SCHEDULE_MODAL,
  HIDE_SCHEDULE_MODAL,
  SHOW_REVIEW_COMMENT_MODAL,
  HIDE_REVIEW_COMMENT_MODAL,
  REVIEW_COMMENT_FORM,
  HOST_LOCATION_FORM
} from '../constants/actionTypes'

export function showAuthModal(props) {
  return {
    type: SHOW_MODAL,
    modalType: AUTH,
    modalProps: {
      ...props,
      show: true
    }
  }
}

export function hideAuthModal() {
  return {
    type: HIDE_MODAL,
    modalType: AUTH
  }
}

export function showRegModal(props) {
  return {
    type: SHOW_MODAL,
    modalType: REG,
    modalProps: {
      ...props,
      show: true
    }
  }
}

export function Modalbtn(props) {
  return {
    type: SHOW_MODAL,
    modalType: SUBCRIPTION,
    modalProps: {
      ...props,
      show: true
    }
  }
}

export function hideRegModal() {
  return {
    type: HIDE_MODAL,
    modalType: REG
  }
}

export function showRegHostModal(props) {
  return {
    type: SHOW_MODAL,
    modalType: BECOME_HOST,
    modalProps: {
      ...props,
      show: props.show
    }
  }
}

export function hideRegHostModal() {
  return {
    type: HIDE_MODAL,
    modalType: BECOME_HOST
  }
}

export function showHostConfirmation(props) {
  return {
    type: SHOW_MODAL,
    modalType: HOST_CONFIRMATION,
    modalProps: {
      ...props,
      show: true
    }
  }
}

export function hideHostConfirmation() {
  return {
    type: HIDE_MODAL,
    modalType: HOST_CONFIRMATION
  }
}

export function showUserConfirmation(props) {
  return {
    type: SHOW_MODAL,
    modalType: USER_CONFIRMATION,
    modalProps: {
      ...props,
      show: true
    }
  }
}

export function hideUserConfirmation() {
  return {
    type: HIDE_MODAL,
    modalType: USER_CONFIRMATION
  }
}

export function showReservationModal(props) {
  return {
    type: SHOW_MODAL,
    modalType: RESERVATION,
    modalProps: {
      ...props,
      show: true
    }
  }
}

export function hideReservationModal() {
  return {
    type: HIDE_MODAL,
    modalType: RESERVATION
  }
}

export function showEditExperienceModal(props) {
  return {
    type: SHOW_MODAL,
    modalType: EXPERIENCE,
    modalProps: {
      ...props,
      show: true,
      type: 'edit'
    }
  }
}

export function hideEditExperienceModall() {
  return {
    type: HIDE_MODAL,
    modalType: EXPERIENCE
  }
}

export function hideEditExperienceModal() {
  return {
    type: HIDE_MODAL,
    modalType: EXPERIENCE
  }
}

export function showCreateExperienceModal(props) {
  return {
    type: SHOW_MODAL,
    modalType: EXPERIENCE,
    modalProps: {
      ...props,
      show: true,
      type: 'create'
    }
  }
}

export function hideCreateExperienceModall() {
  return {
    type: HIDE_MODAL,
    modalType: EXPERIENCE
  }
}

export function hideCreateExperienceModal() {
  return {
    type: HIDE_MODAL,
    modalType: EXPERIENCE
  }
}

export function showHostReviewModal(props) {
  return {
    type: SHOW_MODAL,
    modalType: HOST_REVIEW,
    modalProps: {
      ...props,
      show: true
    }
  }
}

export function hideHostReviewModal() {
  return {
    type: HIDE_MODAL,
    modalType: HOST_REVIEW
  }
}

export function showGuestReviewModal(props) {
  return {
    type: SHOW_MODAL,
    modalType: GUEST_REVIEW,
    modalProps: {
      ...props,
      show: true
    }
  }
}

export function hideGuestReviewModal() {
  return {
    type: HIDE_MODAL,
    modalType: GUEST_REVIEW
  }
}

export function showReceiptModal(props) {
  return {
    type: SHOW_MODAL,
    modalType: RECEIPT,
    modalProps: {
      ...props,
      show: true
    }
  }
}

export function hideReceiptModal() {
  return {
    type: HIDE_MODAL,
    modalType: RECEIPT
  }
}

export function showExpScheduleModal(props) {
  return {
    type: SHOW_MODAL,
    modalType: EXPERIENCE_SCHEDULE,
    modalProps: {
      ...props,
      show: true
    }
  }
}

export function hideExpScheduleModal() {
  return {
    type: HIDE_MODAL,
    modalType: EXPERIENCE_SCHEDULE
  }
}

export function showScheduleFormModal(props) {
  return {
    type: SHOW_SCHEDULE_MODAL,
    modalType: EXPERIENCE_SCHEDULE_FORM,
    modalProps: {
      ...props,
      show: true
    }
  }
}

export function hideScheduleFormModal() {
  return {
    type: HIDE_SCHEDULE_MODAL,
    modalType: EXPERIENCE_SCHEDULE_FORM
  }
}

export function showHostLocationFormModal(props) {
  return {
    type: SHOW_MODAL,
    modalType: HOST_LOCATION_FORM,
    modalProps: {
      ...props,
      show: true
    }
  }
}

export function hideHostLocationFormModal() {
  return {
    type: HIDE_MODAL,
    modalType: HOST_LOCATION_FORM
  }
}


export function showReviewCommenModal(props) {
  return {
    type: SHOW_MODAL,
    modalType: REVIEW_COMMENT_FORM,
    modalProps: {
      ...props,
      show: true
    }
  }
}

export function hideCommentModal(props) {
  return {
    type: HIDE_MODAL,
    modalType: REVIEW_COMMENT_FORM,
    modalProps: {
      ...props,
      show: false
    }
  }
}
