import moment from 'moment'
import qs from 'qs'
import { push } from 'connected-react-router'
import Swal from 'sweetalert2/dist/sweetalert2'
import _ from 'lodash'

import api from '../utils/api'
import alertModal from '../utils/alert'
import { resetMenuFilesObjects } from './user'
import { camelToSnake, snakeToCamel } from '../utils/camelSnake'

// import storage from 'redux-persist/lib/storage/index';
//
import {
  SET_ALL_EXPEREINCES_DATA,
  SET_EXPERIENCES,
  SET_EXPERIENCE,
  EXPERIENCE_LOADING,
  EXPERIENCE_UPDATING,
  SET_CUISINE_TYPE_FILTER_VALUES,
  SET_MENU_ALLERGENS,
  SET_MEAL_CATEGORIES,
  SET_FILTER,
  SET_UPCOMING_SCHEDULE,
  SET_FILTER_SCHEDULE_DATES
} from '../constants/actionTypes'

import {
  normalizeExperiences,
  normalizeExperience,
  normalizeCuisineTypes,
  normalizeExperienceData
} from '../utils/normalization'

import {
  showHostConfirmation
  // hideRegHostModal,
  // hideCreateExperienceModal
} from './modals'
import { trackSearchExperiences } from '../utils/tracking'

// import api from '../utils/api';

export function setExperiences({ experiences, oldExperiences: _oldExperiences, page: _page }) {
  /* let newExperiences = experiences
  if (page > 1) {
    newExperiences = [...oldExperiences.experiences, ...experiences]
  } */
  return { type: SET_EXPERIENCES, experiences }
}

export function setExperiencesAllStat(data) {
  return {
    type: SET_ALL_EXPEREINCES_DATA,
    data
  }
}

export function setExperience(experience) {
  return { type: SET_EXPERIENCE, experience }
}

export function setExperienceLoading(loading) {
  return { type: EXPERIENCE_LOADING, loading }
}

export function setCuisineTypesFilterOptions(cuisineTypes) {
  return { type: SET_CUISINE_TYPE_FILTER_VALUES, cuisineTypes }
}
export function setMealCategories(mealCategories) {
  return { type: SET_MEAL_CATEGORIES, mealCategories }
}
export function setMenuAllergens(menuAllergens) {
  return { type: SET_MENU_ALLERGENS, menuAllergens }
}

export function setFilter(filter) {
  return { type: SET_FILTER, filter }
}

export function setExperienceUpdate(updating) {
  return { type: EXPERIENCE_UPDATING, updating }
}

export function setUpcomingSchedules(upcomingSchedules, needToAdd) {
  return { type: SET_UPCOMING_SCHEDULE, upcomingSchedules, needToAdd }
}

export function setFilterMonths(filterDate) {
  return { type: SET_FILTER_SCHEDULE_DATES, filterDate }
}

// export function setCreateExperience(experience) {
//   return { type: SET_CREATE_EXPERIENCE, experience };
// }

export function getExperiences() {
  return (dispatch, getState) => {
    const { filter, experienceIndex } = getState().experience
    const startDate = filter.startDate
      ? moment
        .utc(filter.startDate)
      // .subtract('years', 1)
        .toString()
      : moment().add(1, 'days')
    const endDate = filter.endDate
      ? moment.utc(filter.endDate).toString()
      : null
    // const endDate = filter.endDate ? moment.utc(filter.endDate).add(1, 'days').toString() : '';
    const address = filter.address ? filter.address.map(add => add.value) : []
    const cuisineTypes = filter.cuisineTypes
      ? filter.cuisineTypes.map(cuisine => cuisine.label)
      : []
    // return api('browse/experiences', {

    if (filter) {
      const isCustomDate = filter.startDate && filter.startDate.isSame ? !filter.startDate.isSame(new Date(), 'day') : false
      const hasCusineTypes = filter.cuisineTypes && filter.cuisineTypes.length > 0
      const hasAddress = filter.address && filter.address.length > 0

      // trigger search only if it has a custom date or cuisine type selected or an address selected
      if (isCustomDate || hasCusineTypes || hasAddress) {
        trackSearchExperiences({
          cuisine: filter.cuisineTypes.map(f => f.label).join(', '),
          city: filter.address.map(f => f.label).join(', '),
          startDate,
          endDate,
          page: filter.current_page
        })
      }
    }

    return api('search/experiences', {
      params: {
        page: filter.current_page,
        per_page: filter.per_page,
        locations: address,
        cuisine_types: cuisineTypes,
        date_range: {
          start_date: startDate,
          end_date: endDate
        }
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
      .then(({ data }) => {
        const json = { ...data }
        if (json.meta.is_success) {
          dispatch(
            setCuisineTypesFilterOptions(
              normalizeCuisineTypes(json.experiences)
            )
          )
          // if (experienceIndex.experiences.length > 0) experienceIndex.experiences.map(item => json.experiences.push(item))
          const experiences = _.uniqBy(json.experiences, 'id')

          dispatch(setExperiences({ experiences: normalizeExperiences(experiences), oldExperiences: experienceIndex, page: filter.current_page }))
          dispatch(setExperiencesAllStat(json))
        } else {
          // console.log('getExperiences failed', json);
        }
      })
      .catch(e => console.log(e)) /* eslint-disable-line no-console */
  }
}

export function getAllFutureSchedules(startDate, endDate) {
  return (dispatch, _getState) => {
    const localStartDate = startDate || moment().startOf('month').format('YYYY-MM-DD')
    const localEndDate = endDate || moment(localStartDate).add(3, 'months').endOf('month').format('YYYY-MM-DD')
    let needToAdd = false
    if (startDate) {
      needToAdd = true
    }
    return api('hosts/schedules/upcoming', {
      params: {
        start_date: localStartDate,
        end_date: localEndDate
      }
    })
      .then(({ data }) => {
        const json = { ...data }
        if (json.is_success) {
          dispatch(
            setUpcomingSchedules(snakeToCamel(json.schedules, 2), needToAdd)
          )
        } else {
          // console.log('getExperiences failed', json);
        }
      })
      .catch(e => console.log(e)) /* eslint-disable-line no-console */
  }
}

export function getExperience(experienceId) {
  return dispatch => {
    dispatch(setExperienceLoading(true))
    api(`/experiences/${experienceId}`)
      .then(response => {
        const json = response.data
        if (json.is_success) {
          dispatch(setExperience(normalizeExperience(json.experience)))
          dispatch(setExperienceLoading(false))
        } else {
          // console.log(json.error);
          dispatch(setExperienceLoading(false))
        }
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log(e)
        dispatch(setExperienceLoading(false))
      }) /* eslint-disable-line no-console */
  }
}

// TODO: Figure out where this is going
// export function getCuisineTypes(experiences) {
//   //console.log('types', experiences);
//   return dispatch => {
//     //console.log('DISPATCH ', dispatch);
//   };
// }

export function bookExperience(experienceId, seatsRequested) {
  /* eslint-disable-next-line no-unused-vars, no-console */
  return async (dispatch, getState) => {
    // const { id } = getState().auth.currentUser.attributes;
    const reservation = {
      experience_id: experienceId,
      seats_booked: Number(seatsRequested)
    }

    // eslint-disable-next-line no-useless-catch
    try {
      await api.post('/reservations', { reservation })
      /* eslint-disable-next-line no-console */
      alertModal('Reservation Request Successful!', 'success', 'Close')
    } catch (err) {
      // alertModal(err.message, 'error', 'Close');
      throw err
    }
  }
}

export function createFirstExperience({ experienceData, modalMessage: _modalMessage }) {
  // experienceData.date = moment(experienceData.date._d).format()

  let experience = normalizeExperienceData(experienceData)
  experience = camelToSnake(experience, 7)
  // console.log('experience===', experience)
  // return false
  // experience.start_time = experience.start_time && experience.start_time.value
  // ? moment(experience.start_time.value).format("H:MM") : moment(experience.start_time).format("H:MM")
  // experience.end_time = experience.end_time && experience.end_time.value  ? moment(experience.end_time.value).format("H:MM") : moment(experience.end_time).format("H:MM")

  // experience.start_date = experience.date
  // experience.end_date = experience.date

  // experience.start_date = experience.start_date? experience.start_date.slice(0,10): experience.start_date;

  // experience.end_date = experience.end_date? experience.end_date.slice(0,10): experience.end_date;

  if (typeof experience.start_date !== undefined) {
    experience.start_date = moment
      .utc(experienceData.start_date)
      .format('DD-MM-YYYY')
    experience.end_date = moment
      .utc(experienceData.end_date)
      .format('DD-MM-YYYY')
  }
  /* eslint-disable-next-line no-unused-vars, no-console */
  return async (dispatch, getState) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const { menuFIleArray } = getState().user.fileUploads
      // console.log('menuFIleArray',menuFIleArray, menuFIleArray.length)
      const formData = new FormData()
      /* if(menuFIleArray) {
        menuFIleArray.map(element => {
          if(element) {
            console.log('in if', element)
            formData.append('menu_item_images[]', element)
          } else {
            console.log('in else', element)
            formData.append('menu_item_images[]', null)
          }
        });
      } */
      const cuisineTypes = [...experience.cuisine_types_attributes]
      const cuisineTypesJSONstr = JSON.stringify(cuisineTypes)
      formData.append('cuisine_types', cuisineTypesJSONstr)
      const menuItems = experience.menu_attributes.menu_items_attributes.map((el) => ({
        id: el.id ? el.id : null,
        course: el.course,
        title: el.title,
        description: el.description
      }))

      menuItems.forEach((_element, index) => {
        if (menuFIleArray && menuFIleArray[index]) {
          formData.append('menu_item_images[]', menuFIleArray[index])
        } else {
          formData.append('menu_item_images[]', 'menu image not changed')
        }
      })
      // return false
      const experienceJSONstr = JSON.stringify(menuItems)
      formData.append('menu_items', experienceJSONstr)

      //  await api.post(`/experiences`, { experience })
      await api.post('hosts/create_sample_experience', formData)
      dispatch(showHostConfirmation())
      dispatch(resetMenuFilesObjects(null))
      // dispatch(hostApplicationComplete());
      // } else if (result.dismiss === Swal.DismissReason.cancel) {
      //   dispatch(hideRegHostModal());
      // }
      // });
    } catch (err) {
      // alertModal(err.message, 'error', 'Close')
      throw err
    }
  }
}

export function createInitialExperience({ experienceData, modalMessage: _modalMessage }) {
  // let experience = normalizeExperienceData(experienceData);
  // experience = camelToSnake(experience, 7);

  // experienceData.date = moment(experienceData.date._d).format()
  if (experienceData.location.city !== undefined) {
    if (typeof experienceData.location.city.value !== undefined) {
      experienceData.location.city = experienceData.location.city.value
      experienceData.location.countryCode = experienceData.location.countryCode.value
    }
  }
  let experience = normalizeExperienceData(experienceData)
  experience = camelToSnake(experience, 7)

  // experience.start_time = experience.start_time && experience.start_time.value
  // ? moment(experience.start_time.value).format("H:MM") : moment(experience.start_time).format("H:MM")
  // experience.end_time = experience.end_time && experience.end_time.value  ? moment(experience.end_time.value).format("H:MM") : moment(experience.end_time).format("H:MM")

  // experience.start_date = experience.date
  // experience.end_date = experience.date

  // experience.start_date = experience.start_date? experience.start_date.slice(0,10): experience.start_date;

  // experience.end_date = experience.end_date? experience.end_date.slice(0,10): experience.end_date;

  if (typeof experience.start_date !== undefined) {
    experience.start_date = moment
      .utc(experienceData.start_date)
      .format('DD-MM-YYYY')
    experience.end_date = moment
      .utc(experienceData.end_date)
      .format('DD-MM-YYYY')
  }

  /* eslint-disable-next-line no-unused-vars, no-console */
  return async (dispatch, getState) => {
    const {
      countryCode,
      city
    } = getState().reduxTokenAuth.currentUser.attributes.hostingLocation
    if (experience.location.city === undefined) {
      experience.location.city = city
    }
    if (experience.location.country_code === undefined) {
      experience.location.country_code = countryCode
    }

    await api.post('/experiences', { experience })
    dispatch(showHostConfirmation())
  }
}

export function createNewExperience({ experienceData }) {
  const experience = { ...experienceData }
  if (experience.date) {
    experience.date = moment
      .utc(experienceData.date)
      .format('DD-MM-YYYY')
  }

  return async (dispatch, _getState) => {
    try {
      dispatch(setExperienceUpdate(true))
      let response
      if (experienceData.id) {
        response = await api.put(`hosts/experiences/${experienceData.id}`, experience)
      } else {
        response = await api.post('hosts/experiences/', experience)
      }
      dispatch(setExperienceUpdate(false))
      /* alertModal(
            'Yay! Your experience has been created!',
            'success',
            'Close'
          )
          console.log('response==', response) */
      return response
      // dispatch(hostApplicationComplete());
    } catch (err) {
      dispatch(setExperienceUpdate(false))
      // alertModal(err.message, 'error', 'Close');
      throw err
    }
  }
}

export function submitAsDraftSubmit({ formValues, type }) {
  const experience = { ...formValues }

  return async (dispatch, _getState) => {
    try {
      dispatch(setExperienceUpdate(true))
      const response = await api.put(`hosts/experiences/${experience.id}/${type}`, {})
      dispatch(setExperienceUpdate(false))
      return response
      // dispatch(hostApplicationComplete());
    } catch (err) {
      dispatch(setExperienceUpdate(false))
      // alertModal(err.message, 'error', 'Close');
      throw err
    }
  }
}

export function submitSchedule({ experienceId, submitData, id, type }) {
  return async (_dispatch, _getState) => {
    let response
    if (type === 'delete') {
      response = await api.delete(`hosts/experiences/${experienceId}/schedules/${id}`)
    } else if (type === 'pause' || type === 'active' || type === 'cancel' || type === 'resume') {
      response = await api.put(`hosts/experiences/${experienceId}/schedules/${id}/${type}`)
    } else if (id) {
      response = await api.put(`hosts/experiences/${experienceId}/schedules/${id}`, submitData)
    } else {
      response = await api.post(`hosts/experiences/${experienceId}/schedules/`, submitData)
    }
    // dispatch(setExperienceUpdate(true))

    // dispatch(setExperienceUpdate(false))
    return response
    // dispatch(hostApplicationComplete());
  }
}

export function getSchedules({ experienceId }) {
  return async (_dispatch, _getState) => {
    const response = await api.get(`hosts/experiences/${experienceId}/schedules/`)
    return response
    // dispatch(hostApplicationComplete());
  }
}

export function createMenuForExperience({ experienceData }) {
  const experience = experienceData
  return async (dispatch, getState) => {
    try {
      dispatch(setExperienceUpdate(true))
      const { menuFIleArray } = getState().user.fileUploads
      // console.log('menuFIleArray',menuFIleArray, menuFIleArray.length)
      const formData = new FormData()
      /* if(menuFIleArray) {
        menuFIleArray.map(element => {
          if(element) {
            console.log('in if', element)
            formData.append('menu_item_images[]', element)
          } else {
            console.log('in else', element)
            formData.append('menu_item_images[]', null)
          }
        });
      } */
      const cuisineTypes = [...experience.cuisineTypesAttributes.map((el) => ({ id: el.id, name: el.label ? el.label : el.name }))]
      const cuisineTypesJSONstr = JSON.stringify(cuisineTypes)
      formData.append('cuisine_types', cuisineTypesJSONstr)

      const mealCategories = [...experience.menuAttributes.mealCategories.map((el) => ({ id: el.id, name: el.label ? el.label : el.name }))]
      const mealCategoriesJSONstr = JSON.stringify(mealCategories)
      formData.append('meal_categories', mealCategoriesJSONstr)

      const menuAllergens = [...experience.menuAttributes.menuAllergensAttributes.map((el) => ({ id: el.id, name: el.label ? el.label : el.name }))]
      const allergensJSONstr = JSON.stringify(menuAllergens)
      formData.append('menu_allergens', allergensJSONstr)

      const menuItems = experience.menuAttributes.menuItemsAttributes.map((el) => ({
        id: el.id ? el.id : null,
        course: el.course.value ? el.course.value : el.course,
        title: el.title,
        description: el.description
      }))

      menuItems.forEach((element, index) => {
        if (menuFIleArray && menuFIleArray[index]) {
          formData.append('menu_item_images[]', menuFIleArray[index])
        } else if (experience.menuAttributes.menuItemsAttributes[index].sample_menu_image_urls) {
          formData.append('menu_item_images[]', JSON.stringify(experience.menuAttributes.menuItemsAttributes[index].sample_menu_image_urls))
        } else {
          formData.append('menu_item_images[]', 'menu image not changed')
        }
      })
      // return false
      const experienceJSONstr = JSON.stringify(menuItems)
      formData.append('menu_items', experienceJSONstr)
      const response = await api.post(`hosts/experiences/${experienceData.id}/save_menu`, formData)
      dispatch(setExperienceUpdate(false))
      dispatch(resetMenuFilesObjects(null))
      /* alertModal(
            'Yay! Your experience has been created!',
            'success',
            'Close'
          )
          console.log('response==', response) */
      return response
      // dispatch(hostApplicationComplete());
    } catch (err) {
      dispatch(setExperienceUpdate(false))
      // alertModal(err.message, 'error', 'Close');
      throw err
    }
  }
}

export function createExperience({ experienceData: _experienceData, modalMessage: _modalMessage }) {
  // experienceData.date = moment(experienceData.date._d).format()
  /* if (experienceData.location.city != undefined) {
    if (typeof experienceData.location.city.value !== undefined) {
      experienceData.location.city = experienceData.location.city.value
      experienceData.location.countryCode =
        experienceData.location.countryCode.value
    }
  }
  let experience = normalizeExperienceData(experienceData)
  experience = camelToSnake(experience, 7) */
  // experience.start_time = experience.start_time && experience.start_time.value
  // ? moment(experience.start_time.value).format("H:MM") : moment(experience.start_time).format("H:MM")
  // experience.end_time = experience.end_time && experience.end_time.value  ? moment(experience.end_time.value).format("H:MM") : moment(experience.end_time).format("H:MM")

  // experience.start_date = experience.date
  // experience.end_date = experience.date

  // experience.start_date = experience.start_date? experience.start_date.slice(0,10): experience.start_date;

  // experience.end_date = experience.end_date? experience.end_date.slice(0,10): experience.end_date;

  /* if (typeof experience.start_date !== undefined) {
    experience.start_date = moment
      .utc(experienceData.start_date)
      .format('DD-MM-YYYY')
    experience.end_date = moment
      .utc(experienceData.end_date)
      .format('DD-MM-YYYY')
  }
 */
  // const photoArray = [];
  // const { photos } = experienceData;
  // if (typeof photos !== 'undefined' && photos.length > 0) {
  //   photos.forEach(object => {
  //     photoArray.push(object.signedId);
  //   });
  // }
  // experience.photos = photoArray;

  /* eslint-disable-next-line no-unused-vars, no-console */
  return async (_dispatch, _getState) => {
    /* const {
      countryCode,
      city
    } = getState().reduxTokenAuth.currentUser.attributes.hostingLocation
    if (experience.location.city == undefined) {
      experience.location.city = city
    }
    if (experience.location.country_code == undefined) {
      experience.location.country_code = countryCode
    } */

    // eslint-disable-next-line no-useless-catch
    try {
      alertModal(
        'Yay! Your experience has been created!',
        'success',
        'Close'
      )
      /* const confirmationModal = Swal.mixin({
        confirmButtonClass: 'btn',
        cancelButtonClass: 'btn btn--white',
        buttonsStyling: false,
        customClass: 'confirmationModal'
      })

      await confirmationModal({
        title: 'Please Confirm',
        html: `<h6>${modalMessage}</h6>`,
        type: '',
        showCancelButton: true,
        confirmButtonText: 'I Agree',
        confirmButtonDisplay: 'block',
        cancelButtonDisplay: 'block',
        padding: '3rem'
      }).then(async result => {
        if (result.value) {
          await api.post(`/experiences`, { experience })
          alertModal(
            'Yay! Your experience has been created!',
            'success',
            'Close'
          )
          // dispatch(hostApplicationComplete());
        } else {
          throw result.dismiss
        }
      }) */
    } catch (err) {
      // alertModal(err.message, 'error', 'Close');
      throw err
    }
  }
}

export function editExperience(data) {
  /* eslint-disable-next-line no-unused-vars, no-console */
  return async (dispatch, getState) => {
    if (typeof data.location.city.value !== undefined) {
      data.location.city = data.location.city.value
      data.location.countryCode = data.location.countryCode.value
    }

    let experience = normalizeExperienceData(data)

    if (typeof experience.start_date !== undefined) {
      experience.start_date = moment.utc(data.start_date).format('DD-MM-YYYY')
      experience.end_date = moment.utc(data.end_date).format('DD-MM-YYYY')
    }
    experience = camelToSnake(experience, 7)
    // eslint-disable-next-line no-useless-catch
    try {
      // console.log('experience.id', experience.id)
      const response = await api.put(`hosts/experiences/${experience.id}`, {
        experience
      })
      /* eslint-disable-next-line no-console */

      const json = response.data
      if (json.is_success) {
        const message = 'You have updated your experience'
        const text = ''
        const pendingText = json.experience.approved
          ? ''
          : 'Your experience is pending approval.'

        const sweetness = Swal.mixin({
          confirmButtonClass: 'btn',
          cancelButtonClass: 'btn btn--white',
          buttonsStyling: false,
          customClass: 'confirmationModal'
        })

        sweetness({
          title: 'Experience Saved.',
          html: `<h6>${message}</h6><p>${text}</p><p class="text-warning">${pendingText}</p>`,
          type: 'success',
          showConfirmButton: json.experience.approved,
          showCancelButton: true,
          confirmButtonText: 'View My Experience Page',
          cancelButtonText: 'Close',
          padding: '3rem'
        }).then(result => {
          if (result.value) {
            dispatch(push(`/experiences/${experience.id}`))
          }
        })
      }
    } catch (err) {
      // alertModal(err.message, 'error', 'Close');
      throw err
    }
  }
}

export function toggleFavorite(experience) {
  // TODO: FIGURE OUT WHY THE ASYNC STUFF IS NOT WORKING HERE

  /* eslint-disable-next-line no-unused-vars, no-console */
  // console.log('EXPERIENCE IN TOGGLE ', experience);

  if (experience.favorite === true) {
    // console.log('FAVORITING');
    api.post('/experiences/favorites/favorite', { experience })
  } else {
    // console.log('UN FAVORITING');
    api.post('/experiences/favorites/unfavorite', { experience })
  }

  // return async (dispatch, getState) => {
  //   let response;
  //   try {
  //     response = await api.post(`/experiences/favorites/favorite`);
  //     //console.log('RESPONSE ', response);
  //   } catch (err) {
  //     throw err;
  //   }
  // };
}
