import axios from 'axios'
import storage from 'redux-persist/lib/storage/index'
import { userAttributes } from '../constants'

const authHeaderKeys = [
  'access-token',
  'token-type',
  'client',
  'uid',
  'expiry'
]

export const setAuthHeaders = headers => {
  authHeaderKeys.forEach(key => {
    storage.getItem(key).then(fromStorage => {
      const value = headers[key] || fromStorage
      axios.defaults.headers.common[key] = value
    })
  })
}

export const persistAuthHeadersInDeviceStorage = headers => {
  authHeaderKeys.forEach(key => {
    storage.getItem(key).then(fromStorage => {
      const value = headers[key] || fromStorage
      storage.setItem(key, value)
    })
  })
}

// export const deleteAuthHeaders = (): void => {
//   authHeaderKeys.forEach(key => {
//     delete axios.defaults.headers.common[key];
//   });
// };
//
// export const deleteAuthHeadersFromDeviceStorage = async ( storage ) => {
//   authHeaderKeys.forEach((key: string) => {
//     Storage.removeItem(key);
//   });
// };

export const invertMapKeysAndValues = stringMap => {
  const newStringMap = {}
  Object.keys(stringMap).forEach(key => {
    const val = stringMap[key]
    newStringMap[val] = key
  })
  return newStringMap
}

export const getUserAttributesFromResponse = response => {
  const invertedUserAttributes = invertMapKeysAndValues(userAttributes)
  const userAttributesBackendKeys = Object.keys(invertedUserAttributes)
  const userAttributesToReturn = {}
  const data =
    typeof response.data.data !== 'undefined'
      ? response.data.data
      : response.data.user

  Object.keys(data).forEach(key => {
    if (userAttributesBackendKeys.indexOf(key) !== -1) {
      userAttributesToReturn[invertedUserAttributes[key]] = data[key]
    }
  })
  return userAttributesToReturn
}
