export const TokenFormStatus = {
  SENT: 'sent',
  RECIEVED: 'recieved',
  FAILED: 'failed'
}

export const PaymentMethodStatus = {
  NOT_SENT: 'notSent',
  SENT: 'sent',
  RECIEVED: 'recieved',
  FAILED: 'failed'
}

export const FramesScriptLoadStatus = {
  STARTED: 'started',
  LOADING: 'loading',
  ERROR: 'error',
  DONE: 'done',
  INITIALIZED: 'initialize'
}

export const ReservationRequestStatus = {
  SENT: 'sent',
  SUCCESS: 'success',
  FAILED: 'failed',
  NOT_SENT: 'notSent'
}

export const GenericRequestStatus = {
  SENT: 'sent',
  SUCCESS: 'success',
  FAILED: 'failed',
  NOT_SENT: 'notSent'
}
