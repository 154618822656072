export const USD = 'USD'
export const AED = 'AED'
export const GBP = 'GBP'

export const currencyTypes = {
  // USD: {
  //   name: 'US Dollar',
  //   value: USD,
  //   label: '$ / USD',
  //   number: '840',
  //   symbol: '$',
  // },
  AED: {
    name: 'UAE Dirham',
    value: AED,
    label: 'د.إ / AED',
    number: '784',
    symbol: 'د.إ'
  }
  /*
  GBP: {
    name: 'Pound Sterling',
    value: GBP,
    label: '£ / GBP',
    number: '826',
    symbol: '£',
  },*/
}

export const currencyOptions = [
  // {
  //   name: 'US Dollar',
  //   value: USD,
  //   label: '$ / USD',
  //   number: '840',
  //   symbol: '$'
  // },
  {
    name: 'UAE Dirham',
    value: AED,
    label: 'د.إ / AED',
    number: '784',
    symbol: 'د.إ'
  }
  /*
  {
    name: 'Pound Sterling',
    value: GBP,
    label: '£ / GBP',
    number: '826',
    symbol: '£'
  }*/
]
