import React from 'react'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'
import inputPropTypes from './propTypes'
import './renderDateField.css'
import {
  CURRENT_YEAR,
  NEXT_YEAR,
  MIN_AGE,
  EARLIEST_YEAR,
  LATEST_YEAR
} from '../../../constants'

const renderDateField = props => {
  const {
    input,
    label,
    // type, // not used in this input?
    focused,
    initialValue, // Redux form doesnt like objects being passed as prop.value
    disabled,
    placeholder,
    meta,
    past
    // meta: { touched, error, active }
  } = props

  const { touched, error, active } = meta

  /*


  You can pass in a shape through the 'field' prop,
  or use the individual props provided.

  This is what the field shape could look like.

  field: {
    name: 'fullName',
    type: 'text',
    autoComplete: 'name',
    placeholder: 'Full Name'
  },

   */

  const fieldPresent = typeof field !== 'undefined'
  const fieldName = fieldPresent ? field.name : name
  const fieldLabel = fieldPresent ? field.placeholder : label
  const errored = touched && error
  const inputValidationClass = errored ? 'input--error' : ''
  const labelValidationClass = errored ? 'error' : ''

  // console.log(meta); Initial value needs to be a moment object, yet redux-form
  // Field only provides a string for initial value
  let dateValue = input.value === '' ? initialValue : input.value

  if (!moment.isMoment(dateValue)) {
    dateValue = moment.utc(new Date(dateValue))
  }

  const outside = day =>
    past ? moment.utc().diff(day) < 0 : moment.utc().diff(day) > 0 // To allow past

  const buildYears = () => {
    const arr = []
    let LAST_YEAR = LATEST_YEAR
    let LAST_YEAR_TO_LOOP = CURRENT_YEAR

    if (past) {
      LAST_YEAR = MIN_AGE
      LAST_YEAR_TO_LOOP = EARLIEST_YEAR
    }

    for (let i = LAST_YEAR; i >= LAST_YEAR_TO_LOOP; i -= 1) {
      arr.push(
        <option key={i} value={i}>
          {i}
        </option>
      )
    }

    return arr
  }

  const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div>
        <select
          value={month.month()}
          onChange={e => onMonthSelect(month, e.target.value)}
        >
          {moment.months().map((label, value) => (
            <option key={label} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <div>
        <select
          value={month.year()}
          onChange={e => onYearSelect(month, e.target.value)}
        >
          {buildYears()}
        </select>
      </div>
    </div>
  )
  return (
    <React.Fragment>
      {fieldLabel && (
        <label htmlFor={fieldName} className={`${labelValidationClass}`}>
          {fieldLabel}
        </label>
      )}
      <div>
        <SingleDatePicker
          numberOfMonths={1}
          required
          disabled={disabled}
          readOnly={true}
          date={dateValue} // momentPropTypes.momentObj or null
          onDateChange={date => input.onChange(date)} // Default redux-form onChange
          focused={focused} // PropTypes.bool - redux-form Field Meta
          onFocusChange={input.onFocus} // PropTypes.func.isRequired
          enableOutsideDays
          isOutsideRange={day => outside(day)} // To allow past
          id={fieldName} // PropTypes.string.isRequired,
          placeholder={placeholder}
          renderMonthElement={renderMonthElement}
        />{' '}
        {errored && (
          <p className="common-p-error">
            <span className="form-error">{error}</span>
          </p>
        )}
      </div>
    </React.Fragment>
  )
}

renderDateField.propTypes = {
  ...inputPropTypes
}

export default renderDateField
