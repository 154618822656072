import qs from 'qs'
import moment from 'moment'
import api from '../../utils/api'
import { getUserAttributesFromResponse } from '../../utils/auth-tools'
import { camelToSnake } from '../../utils/camelSnake'

/* eslint-disable-next-line no-unused-vars */
import { normalizeProfile } from '../../utils/normalization'

import alertModal from '../../utils/alert'

import { getUser } from './getUser'

import {
  SET_ACCESS_TOKEN,
  SET_USER_ATTRIBUTES,
  SET_FULL_REGISTRATION_STATE,
  SET_UPDATE_API_ERROR
} from '../../constants/actionTypes'
import { isObject } from 'lodash'

export function setFullRegistrationState(state) {
  return {
    type: SET_FULL_REGISTRATION_STATE,
    state
  }
}

export const setUserAttributes = userAttributes => ({
  type: SET_USER_ATTRIBUTES,
  payload: {
    userAttributes
  }
})

export const setUpdateApiError = errorData => ({
  type: SET_UPDATE_API_ERROR,
  payload: {
    errorData
  }
})

export function updateUser(attributes) {
  /* eslint-disable-next-line no-unused-vars, no-console */
  return async (dispatch, getState) => {
    let user = { ...attributes }
    // const { id } = getState().auth.currentUser.attributes;
    const { image } = user
    if (typeof image !== 'undefined' && image !== 'object') {
      user = {
        ...user,
        image: image.signed_id ? image.signed_id : undefined
      }
    }
    user.location = user.location ? user.location : {}

    // if (user.city) {
    //   user.location.city = user.city;
    // }
    // user.location.city = user.city ? user.city : null

    // if (user.countryCode) {
    //   user.location.countryCode = user.countryCode.value;
    // }
    user.location.city = user.city ? user.city : null
    if (user.countryCode) {
      user.location.countryCode =
        typeof user.countryCode === 'string'
          ? user.countryCode
          : user.countryCode.value
    }

    const newbirthday = user.birthday
    // delete user.city;
    // delete user.countryCode;
    // Snake case out data to match server side
    user = camelToSnake(user, 2) // Snake Case Keys (data, depth)

    user.birthday = newbirthday

    // user.location = null

    let response
    try {
      user.gender = user.gender ? user.gender.value : user.gender
      // user.birthday = user.birthday
      //   ? moment(user.birthday._d).format()
      //   : user.birthday;

      user.preferred_currency = user.preferred_currency
        ? user.preferred_currency.value
        : user.preferred_currency

      response = await api.post(`/users/update_profile`, { user })
      alertModal('Great Job! Profile updated successfully', 'success', 'Close')
      const userAttributes = getUserAttributesFromResponse(response)

      dispatch(setUserAttributes(userAttributes))
      getUser(user.id)
    } catch (err) {
      // alertModal(err.response, 'error', 'Try Again');
      throw err
    }
  }
}

export function updateUserAccount(attributes, showPopup, fromBecomeHost) {
  /* eslint-disable-next-line no-unused-vars, no-console */

  return async (dispatch, getState) => {
    const userData = { ...attributes }
    dispatch(setUpdateApiError(null))
    // console.log('userData====', userData)
    let userAttributes = {
      // avatarUrl: userData.avatarUrl,
      firstName: userData.firstName,
      lastName: userData.lastName,
      gender: userData.gender,
      birthday: isObject(userData.birthday)?moment(userData.birthday).format('YYYY-MM-DD'):userData.birthday,
      languages: userData.languages,
      phoneCountryCode: userData.phoneCountryCode,
      phoneNumber: userData.phoneNumber,
      location: userData.location ? userData.location : {},
      city: userData.city
        ? userData.city
        : (userData.location && userData.location.city)
          ? userData.location.city
          : null,
      destinationLink: typeof userData.destinationLink === 'string'
        ? userData.destinationLink
        : (userData.location && userData.location.destinationLink)
          ? userData.location.destinationLink
          : null,
      countryCode: typeof userData.countryCode === 'string'
        ? userData.countryCode
        : userData.countryCode && userData.countryCode.value
          ? userData.countryCode.value
          : null,
      phoneMetaData: userData.phoneMetaData,
      accept_terms: userData.acceptTerms ? true : false,
      social_links: userData.socialLinks,
      culinary_journey: userData.culinaryJourney,
      specialties: userData.specialties ? userData.specialties.map((el) => { if (el.name) { return el } return { name: el.label, id: el.id } }) : null
    }
    if (fromBecomeHost) {
      delete userAttributes.phoneNumber
      delete userAttributes.phoneCountryCode
      delete userAttributes.phoneMetaData
    }

    // user.location = user.location ? user.location : {};

    // userAttributes.location.city = userData.city ? userData.city : null
    if (userData.countryCode) {
      userAttributes.location.countryCode =
        typeof userData.countryCode === 'string'
          ? userData.countryCode
          : userData.countryCode && userData.countryCode.value
            ? userData.countryCode.value
            : null
    }
    const newbirthday = userAttributes.birthday
    userAttributes = camelToSnake(userAttributes, 2) // Snake Case Keys (data, depth)
    userAttributes.birthday = newbirthday
    // user.location = null
    let response
    /* try { */
    userAttributes.gender = userAttributes.gender && userAttributes.gender.value
      ? userAttributes.gender.value
      : userAttributes.gender
      // user.birthday = user.birthday
      //   ? moment(user.birthday._d).format()
      //   : user.birthday;

    userAttributes.preferred_currency = userAttributes.preferred_currency && userAttributes.preferred_currency.value
      ? userAttributes.preferred_currency.value
      : userAttributes.preferred_currency

    /* let newUserAttributes = {...userAttributes}
        newUserAttributes.country_code = newUserAttributes.country_code
        newUserAttributes.city = newUserAttributes.city

        delete newUserAttributes.location */
    // console.log('newUserAttributes====', newUserAttributes, userAttributes)
    // users/personal_info
    // /users/update_account_details
    return api.post(`users/personal_info`, {
      user: userAttributes
    }).then(response=>{
      if (response.data.is_success) {
        if (showPopup) {
          alertModal(
            'Great Job! Profile updated successfully',
            'success',
            'Close'
          )
        }
    
        const userAttributesResponse = getUserAttributesFromResponse(response)
        dispatch(setUserAttributes(userAttributesResponse))
      } else {
        return response
      }
    }).catch(error => {
      // eslint-disable-next-line no-console
      console.log('error===', error.response)
      dispatch(setUpdateApiError(error.response.data))
      return response
    })
    //getUser(userAttributes.id)
    /* } catch (err, response) {
      // alertModal(err.response, 'error', 'Try Again');
      console.log('data in catch', err.message)
      throw err
    } */
  }
}

export function updateProfessionalInfo(attributes, showPopup) {
  /* eslint-disable-next-line no-unused-vars, no-console */

  return async (dispatch, getState) => {
    const userData = { ...attributes }

    /* let userAttributes = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      gender: userData.gender,
      birthday: userData.birthday,
      languages: userData.languages,
      phoneCountryCode: userData.phoneCountryCode,
      phoneNumber: userData.phoneNumber,
      location: userData.location ? userData.location : {},
      phoneMetaData: {
        dialCode: userData.phoneCountryCode,
        iso2: 'ae',
        name: 'United Arab Emirates'
      }
    } */

    // user.location = user.location ? user.location : {};

    /* userAttributes.location.city = userData.city ? userData.city : null
    if (userData.countryCode) {
      userAttributes.location.countryCode =
        typeof userData.countryCode === 'string'
          ? userData.countryCode
          : userData.countryCode.value
    }
    const newbirthday = userAttributes.birthday
    userAttributes = camelToSnake(userAttributes, 2) // Snake Case Keys (data, depth)
    userAttributes.birthday = newbirthday */
    // user.location = null
    let response
    try {
      /* userAttributes.gender = userAttributes.gender
        ? userAttributes.gender.value
        : userAttributes.gender
      // user.birthday = user.birthday
      //   ? moment(user.birthday._d).format()
      //   : user.birthday;

      userAttributes.preferred_currency = userAttributes.preferred_currency
        ? userAttributes.preferred_currency.value
        : userAttributes.preferred_currency */
      response = await api.post(`hosts/update_professional_info`, {
        user: userData.user
      })
      if (showPopup) {
        alertModal(
          'Great Job! Profile updated successfully',
          'success',
          'Close'
        )
      }

      const userAttributesResponse = getUserAttributesFromResponse(response)

      dispatch(setUserAttributes(userAttributesResponse))
      // getUser(userAttributes.id)
    } catch (err) {
      // alertModal(err.response, 'error', 'Try Again');
      throw err
    }
  }
}

export function updateHostingSpaceDetail(attributes, showPopup) {
  /* eslint-disable-next-line no-unused-vars, no-console */

  return async (dispatch, getState) => {
    const userData = { ...attributes }

    /* let userAttributes = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      gender: userData.gender,
      birthday: userData.birthday,
      languages: userData.languages,
      phoneCountryCode: userData.phoneCountryCode,
      phoneNumber: userData.phoneNumber,
      location: userData.location ? userData.location : {},
      phoneMetaData: {
        dialCode: userData.phoneCountryCode,
        iso2: 'ae',
        name: 'United Arab Emirates'
      }
    } */

    // user.location = user.location ? user.location : {};

    /* userAttributes.location.city = userData.city ? userData.city : null
    if (userData.countryCode) {
      userAttributes.location.countryCode =
        typeof userData.countryCode === 'string'
          ? userData.countryCode
          : userData.countryCode.value
    }
    const newbirthday = userAttributes.birthday
    userAttributes = camelToSnake(userAttributes, 2) // Snake Case Keys (data, depth)
    userAttributes.birthday = newbirthday */
    // user.location = null
    let response
    try {
      /* userAttributes.gender = userAttributes.gender
        ? userAttributes.gender.value
        : userAttributes.gender
      // user.birthday = user.birthday
      //   ? moment(user.birthday._d).format()
      //   : user.birthday;

      userAttributes.preferred_currency = userAttributes.preferred_currency
        ? userAttributes.preferred_currency.value
        : userAttributes.preferred_currency */
      response = await api.post(`hosts/update_hosting_space_info`, {
        user: userData.user
      })
      if (showPopup) {
        alertModal(
          'Great Job! Profile updated successfully',
          'success',
          'Close'
        )
      }

      const userAttributesResponse = getUserAttributesFromResponse(response)

      dispatch(setUserAttributes(userAttributesResponse))
      // getUser(userAttributes.id)
    } catch (err) {
      // alertModal(err.response, 'error', 'Try Again');
      throw err
    }
  }
}

export function updateHostingFoodPhotos(attributes, showPopup, is_profile_photos) {
  /* eslint-disable-next-line no-unused-vars, no-console */

  return async (dispatch, getState) => {
    const userData = { ...attributes }
    let response
    try {
      response = await api.post(`update_food_photos`, {
        user: userData.user
      })
      if (showPopup) {
        alertModal(
          'Great Job! Profile updated successfully',
          'success',
          'Close'
        )
      }

      const userAttributesResponse = getUserAttributesFromResponse(response)

      dispatch(setUserAttributes(userAttributesResponse))

      if (is_profile_photos && is_profile_photos === 'profile_photos') {
        return response
      }
      // getUser(userAttributes.id)
    } catch (err) {
      // alertModal(err.response, 'error', 'Try Again');
      throw err
    }
  }
}


export function updateProfilePic(attributes) {
  /* eslint-disable-next-line no-unused-vars, no-console */
  return async (dispatch, getState) => {
    let user = { ...attributes }
    // const { id } = getState().auth.currentUser.attributes;
    const { image } = user
    if (typeof image !== 'undefined' && image !== 'object') {
      user = {
        ...user,
        image: image.signed_id ? image.signed_id : undefined
      }
    }
    user = camelToSnake(user, 2) // Snake Case Keys (data, depth)
    let response
    try {
      response = await api.post(`/users/update_profile_picture`, { user })
      /* alertModal(
        'Great Job! Your Profile has been updated.',
        'success',
        'Close'
      ) */
      const userAttributes = getUserAttributesFromResponse(response)

      dispatch(setUserAttributes(userAttributes))
      // getUser(user.id)
    } catch (err) {
      // alertModal(err.response, 'error', 'Try Again');
      throw err
    }
  }
}

export function verifileMobileWithOtp(attributes) {
  /* eslint-disable-next-line no-unused-vars, no-console */
  return async (dispatch, getState) => {
    let user = { ...attributes }
    // const { id } = getState().auth.currentUser.attributes;
    const { image } = user
    if (typeof image !== 'undefined' && image !== 'object') {
      user = {
        ...user,
        image: image.signed_id ? image.signed_id : undefined
      }
    }
    user.location = user.location ? user.location : {}

    // if (user.city) {
    //   user.location.city = user.city;
    // }
    // user.location.city = user.city ? user.city : null

    // if (user.countryCode) {
    //   user.location.countryCode = user.countryCode.value;
    // }
    user.location.city = user.city ? user.city : null
    if (user.countryCode) {
      user.location.countryCode =
        typeof user.countryCode === 'string'
          ? user.countryCode
          : user.countryCode.value
    }

    const newbirthday = user.birthday
    // delete user.city;
    // delete user.countryCode;
    // Snake case out data to match server side
    user = camelToSnake(user, 2) // Snake Case Keys (data, depth)

    user.birthday = newbirthday

    // user.location = null

    let response
    try {
      user.gender = user.gender ? user.gender.value : user.gender
      // user.birthday = user.birthday
      //   ? moment(user.birthday._d).format()
      //   : user.birthday;

      user.preferred_currency = user.preferred_currency
        ? user.preferred_currency.value
        : user.preferred_currency

      response = await api.post(`/users/update_profile`, { user })
      alertModal(
        'Great Job! Profile was uploaded successfully.',
        'success',
        'Close'
      )
      const userAttributes = getUserAttributesFromResponse(response)

      dispatch(setUserAttributes(userAttributes))
      // getUser(user.id)
    } catch (err) {
      // alertModal(err.response, 'error', 'Try Again');
      throw err
    }
  }
}

export function updateUserBankDetails(attributes) {
  /* eslint-disable-next-line no-unused-vars, no-console */
  return async (dispatch, getState) => {
    let curentUser = getState().reduxTokenAuth.currentUser.attributes
    let user = { ...attributes }
    user = camelToSnake(user, 2)
    let response
    try {
      user.gender = user.gender ? user.gender.value : user.gender
      // user.birthday = user.birthday
      //   ? moment(user.birthday._d).format()
      //   : user.birthday;

      user.preferred_currency = user.preferred_currency
        ? user.preferred_currency.value
        : user.preferred_currency

      response = await api.post(`hosts/bank_details`, { user })
      alertModal('Bank detail updated successfully', 'success', 'Close')
      // const userAttributes = getUserAttributesFromResponse(response)
      if (response.data.id) {
        dispatch(setUserAttributes({...curentUser, ...response.data}))
      }
      /* dispatch(setUserAttributes(userAttributes))
      getUser(user.id) */
    } catch (err) {
      // alertModal(err.response, 'error', 'Try Again');
      throw err
    }
  }
}

export function getBankDetail() {
  /* eslint-disable-next-line no-unused-vars, no-console */
  return async (dispatch, getState) => {
    let user = getState().reduxTokenAuth.currentUser.attributes
    try {
      const response = await api.get(`hosts/bank_details`)
      dispatch(setUserAttributes({...user, ...response.data}))
    } catch (err) {
      // alertModal(err.response, 'error', 'Try Again');
      throw err
    }
  }
}

export function updateUserProfessionalDetails(attributes) {
  /* eslint-disable-next-line no-unused-vars, no-console */
  return async (dispatch, getState) => {
    let user = { ...attributes }

    user.specialties = user.specialties.map(data => {
      const option = { id: data.value, name: data.label }
      return option
    })
    user = camelToSnake(user, 2)
    let response
    // eslint-disable-next-line no-useless-catch
    try {
      user.gender = user.gender ? user.gender.value : user.gender
      // user.birthday = user.birthday
      //   ? moment(user.birthday._d).format()
      //   : user.birthday;

      user.preferred_currency = user.preferred_currency
        ? user.preferred_currency.value
        : user.preferred_currency

      response = await api.post(`hosts/update_professional_info`, { user })
      alertModal('Professional detail updated successfully', 'success', 'Close')
      const userAttributes = getUserAttributesFromResponse(response)

      dispatch(setUserAttributes(userAttributes))
      // getUser(user.id)
    } catch (err) {
      // alertModal(err.response, 'error', 'Try Again');
      throw err
    }
  }
}
