import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import Avatar from 'react-avatar'
// import { bindActionCreators } from 'redux';
import renderFileField from './renderFileField'
import { currentUserPropTypes } from '../../../constants/composed-proptypes'

/* Actions */
import * as userActionCreators from '../../../actions/user'
// import * as modalActionCreators from '../../../../actions/modals';
// import * as formActionCreators from '../../../../actions/forms';

const fileTypes = ['image/jpeg', 'image/pjpeg', 'image/png']

function validFileType(file) {
  for (let i = 0; i < fileTypes.length; i += 1) {
    if (file.type === fileTypes[i]) {
      return true
    }
  }
  return false
}

function returnFileSize(number) {
  if (number < 1024) {
    return `${number}bytes`
  }
  if (number >= 1024 && number < 1048576) {
    return `${(number / 1024).toFixed(1)}KB`
  }
  if (number >= 1048576) {
    return `${(number / 1048576).toFixed(1)}MB`
  }
  return `${number}bytes`
}

/* eslint-disable-next-line no-unused-vars */
const renderError = ({ meta: { touched, error } }) => {
  /* eslint-disable-next-line no-unused-expressions */
  touched && error ? <span>{error}</span> : false
}

class AvatarUpload extends Component {
  constructor(props) {
    super(props)
    const { currentUser } = this.props
    /* eslint-disable-next-line camelcase */
    const { avatarUrl } = currentUser.attributes
    const initialAvatarValue =
      /* eslint-disable-next-line camelcase */
      typeof avatarUrl !== 'undefined' ? avatarUrl : ''

    this.state = {
      avatarStyles: {
        backgroundImage: `url(${initialAvatarValue})`,
        uploadingStarted: false
      },
      newlyUploaded: false,
      avatarTextContext: ''
    }
  }

  componentDidMount() {}

  updateImageDisplay() {
    const { currentUser } = this.props
    const input = document.getElementById('fileUpload')
    const curFiles = input.files
    if (this.props.changeAvatar) {
      this.props.avatarUpload(true)
    }
    if (curFiles.length === 0) {
      this.setState({
        avatarTextContext: 'Select a file by clicking the button below.'
      })
    } else if (validFileType(curFiles[0])) {
      const fileTextContent = `File name ${
        curFiles[0].name
      }, file size ${returnFileSize(curFiles[0].size)}.`

      const fileSrc = window.URL.createObjectURL(curFiles[0])
      this.setState({
        avatarStyles: {
          backgroundImage: `url(${fileSrc})`
        },
        newlyUploaded: true,
        avatarTextContext: fileTextContent,
        uploadingStarted: false
      })
    } else {
      const fileTextContent = `File name: ${curFiles[0].name}: Not a valid file type. Update your selection.`
      // this.setState({uploadingStarted:true});
      this.setState({
        avatarTextContext: fileTextContent,
        uploadingStarted: false
      })
    }
  }

  render() {
    const {
      // handleSubmit,
      pristine,
      submitting,
      // nextPage,
      className,
      selectCTA,
      currentUser,
      userActions,
      nextPage,
      goToNext
    } = this.props

    const select = selectCTA || 'Click to Upload a Photo'
    const classSelector = className || 'avatar-upload'
    const { avatarStyles, avatarTextContext, newlyUploaded } = this.state
    const avatarPresent = currentUser && currentUser.attributes && currentUser.attributes.profilePicture && currentUser.attributes.profilePicture.thumb && typeof currentUser.attributes.profilePicture.thumb !== 'undefined' && !currentUser.attributes.profilePicture.thumb.includes('/thumb_default.png')
    return (
      <React.Fragment>
        <div className={classSelector}>
          <div className="preview">
            {newlyUploaded && <div className="chef-or-host__avatar" style={avatarStyles} />}

            {avatarPresent && !newlyUploaded && (
              <div
                className="chef-or-host__avatar"
                style={{
                  backgroundImage: `url(${currentUser.attributes.profilePicture && currentUser.attributes.profilePicture.thumb?currentUser.attributes.profilePicture.thumb:''})`
                }}
              />
            )}

            {!avatarPresent && !newlyUploaded && <Avatar name={currentUser.attributes && currentUser.attributes.fullName?currentUser.attributes.fullName:''} color="#f8a48d" size="135" round />}
          </div>
          <div className="fileInfo">
            <p>{avatarTextContext}</p>
          </div>
          <p>
            <button
              type="button"
              className="btn minWidth200"
              id="btnUpload"
              disabled={this.state.uploadingStarted}
              onClick={() => {
                document.getElementById('fileUpload').click()
              }}
            >
              {select}
            </button>
          </p>

          <Field
            name="image"
            id="fileUpload"
            type="file"
            accept=".jpg, .jpeg, .png"
            component={renderFileField}
            style={{ opacity: 0, height: 0, margin: 0, padding: 0 }}
            // onClickCapture={()=>this.setState({uploadingStarted:true})}

            onChangeCapture={() => this.setState({ uploadingStarted: true })}
            updateImageDisplay={() => this.updateImageDisplay()}
            singleImageUpload={ async (e) => {
              await userActions.profileImageUpload(e, goToNext)
              if(nextPage && goToNext) {
                setTimeout(() => {
                  nextPage()
                }, 1000)
              }
            }}
          />

          {!this.props.isAvatar && this.props.displayAvtarError ? <div className="form-error form-error-div">Please select profile image</div> : ''}
        </div>
      </React.Fragment>
    )
  }
}

AvatarUpload.defaultProps = {
  submitting: false,
  pristine: false
}
AvatarUpload.propTypes = {
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  currentUser: currentUserPropTypes.isRequired,
  userActions: PropTypes.shape({}).isRequired
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser,
  stepForm: state.stepForm.hostRegistration,
  activeFormType: state.signUp.activeFormType,
  signUpPane: state.signUp.signUpPane
})

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(userActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AvatarUpload)
