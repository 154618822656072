import api from '../utils/api'
import alertModal from '../utils/alert'
import { trackSubscription } from '../utils/tracking'

const newsletterSignup = async email => {
  try {
    trackSubscription({ email })
    const response = await api.post(`/mailing_lists`, {
      email
    })
    if (response.data.is_success) {
      alertModal('You are all subscribed!', 'success', 'Continue')
    } else {
      alertModal(response.data.error.detail, 'error', 'Close')
    }
  } catch (err) {
    // alertModal(err.message, 'error', 'Close')
    throw err
  }
}

export default newsletterSignup
