import moment from 'moment'
import { object } from 'prop-types'
import { experienceCut, guestCut, hostCut, scheduleCut } from './normalizeAssociations'
import { snakeToCamel } from './camelSnake'

export function normalizeCuisineTypes(experiences) {
  return experiences.map(experience => ({
    cuisineTypes: experience.cuisine_types || ''
  }))
}

export function normalizeHost(host) {
  return {
    ...hostCut(host),
    description: host.description || '',
    avatarUrl: host.profile_picture && host.profile_picture.thumb ? host.profile_picture.thumb + '?' + new Date().getTime() : '',
    slug: host.slug || '',
    experiences: host.experiences || '',
    isHost: host.is_host || '',
    specialties: host.specialties || []
  }
}
export function normalizeHostProfile(host) {
  let hostingLocationGalleryImages = host.hosting_location && host.hosting_location.gallery_images ? host.hosting_location.gallery_images : []
  return {
    ...hostCut(host),
    description: host.description || '',
    avatarUrl: host.profile_picture && host.profile_picture.thumb ? host.profile_picture.thumb + '?' + new Date().getTime() : '',
    isHost: host.is_host || '',
    specialties: host.specialties || [],
    socialLinks: host.social_links || {},
    slug: host.slug || '',
    culinaryJourney: host.culinary_journey || '',
    locationPhotos: [...host.host_gallery_images, ...hostingLocationGalleryImages] || [],
    foodPhotos: host.food_photos || [],
    featuredExperiences: host.featured_experiences
      ? snakeToCamel(host.featured_experiences, 3)
      : [],
    latestGuestReviews: host.latest_guest_reviews
      ? snakeToCamel(host.latest_guest_reviews, 3)
      : []
  }
}

export function normalizeHosts(hosts) {
  return hosts.map(host => normalizeHost(host))
}

export function normalizeLocations(locations) {
  return locations.map(location => normalizeLocation(location))
}

export function normalizeLocation(location) {
  return {
    id: location.id,
    city: location.city,
    countryCode: location.country_code,
    countryName: location.country_name,
    galleryImages: location.gallery_images,
    neighbourhood: location.neighbourhood,
    destinationLink: location.destination_link,
    postalCode: location.postal_code,
    streetAddress: location.street_address,
    amenities: location.amenities,
    wowElements: location.wow_elements
  }
}

export function normalizeSpecialties(hosts) {
  return hosts.map(host => ({
    cuisineTypes: host.specialties || []
  }))
}

export function normalizeReview(review) {
  return {
    id: review.id || '',
    star: review.star || 0,
    comment: review.comment || '',
    type: review.type,
    reviewedByGuest: review.reviewed_by_guest || false,
    reviewedByHost: review.reviewed_by_host || false,
    datePosted: moment.utc(review.date_posted).format('LL') || '',
    guest: guestCut(review.guest),
    host: hostCut(review.host),
    status: review.status,
    reservation: review.reservation
      ? normalizeReservation(review.reservation)
      : [],
    timePosted: review.time_ago
  }
}

export function normalizeReservation(reservation) {
  // console.log('eservation.booked_date===', reservation, reservation.booking_date, moment.utc('2021-08-16').format('LL'))
  let updatedDate = null
  if(reservation.schedule && reservation.schedule.date) {
    const dateArr = reservation.schedule.date.split('/')
    updatedDate = `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`
  }
  return {
    id: reservation.id || '',
    guest: guestCut(reservation.guest),
    total: reservation.total || '',
    netTotal: reservation.net_total || '',
    currency: reservation.currency || '',
    price: reservation.price_per_seat || '',
    discount: reservation.discount || '',
    coupon: reservation.coupon || '',
    tax: reservation.tax || '',
    date: moment.utc(updatedDate).format('LL') || '',
    bookedDate: moment.utc(reservation.booking_date).format('LL') || '',
    schedule: scheduleCut(reservation.schedule),
    bookingNumber: reservation.booking_number,
    dirtyEventDate: reservation.event_date,
    eventDate: moment.utc(reservation.event_date).format('LL') || '',
    status: reservation.status || '',
    paymentStatus: reservation.payment_status || '',
    seatsBooked: reservation.seats_booked || '',
    experience: experienceCut(reservation.experience),
    experienceId: reservation.experience_id || '',
    reviewedByHost: reservation.reviewed_by_host,
    reviewedByGuest: reservation.reviewed_by_guest,
    receipt: reservation.receipt || {},
    guestReview: (reservation.guest_reviews && reservation.guest_reviews[0])
      ? normalizeReview(reservation.guest_reviews[0])
      : 
      {},
    hostReview: (reservation.host_reviews && reservation.host_reviews[0])
      ? normalizeReview(reservation.host_reviews[0])
      : {}
  }
}

export function normalizeReservations(reservations) {
  return reservations.map(reservation => normalizeReservation(reservation))
}

export function normalizeReviews(reviews) {
  return reviews.map(review => ({
    id: review.id || '',
    star: review.star || 0,
    comment: review.comment || '',
    reviewedByGuest: review.reviewed_by_guest,
    reviewedByHost: review.reviewed_by_host,
    datePosted: review.date_posted || '',
    type: review.type,
    guest: guestCut(review.guest),
    host: hostCut(review.host),
    status: review.status,
    reservation: review.reservation
      ? normalizeReservation(review.reservation)
      : [],
    experience: review.experience ? experienceCut(review.experience) : '',
    timePosted: review.time_ago
  }))
}

export function normalizeExperience(experience) {
  return {
    ...experienceCut(experience),
    
    slug: experience.slug ? experience.slug : null,
    sample: experience.sample ? experience.sample : null,
    start_time: experience.start_time ? experience.start_time : null,
    start_date: experience.start_date ? experience.start_date : null,
    end_time: experience.end_time ? experience.end_time : null,
    end_date: experience.end_date ? experience.end_date : null,
    instant: experience.instant ? experience.instant : false,
    // date: experience.date ? experience.date : null,
    // schedules: normalizeScheduleds(experience.schedules),
    location: experience.location
      ? {
        id: experience.location.id || null,
        amenities: experience.location.amenities || [],
        galleryImages: experience.location.gallery_images || [],
        wowElements: experience.location.wow_elements || [],
        neighbourhood: experience.location.neighbourhood || '',
        streetAddress: experience.location.street_address || '',
        stateCode: experience.location.state_code || '',
        postalCode: experience.location.postal_code || '',
        countryCode: experience.location.country_code || '',
        city: experience.location.city || '',
        destinationLink: experience.location.destination_link || '',
      }
      : {
        streeAddress: '',
        stateCode: '',
        postalCode: '',
        countryCode: '',
        galleryImages: [],
        city: '',
        destinationLink: '',
      },
    host: experience.host
      ? {
        ...hostCut(experience.host),
        specialties: experience.host.specialties || [],
        locationPhotos: experience.host.location_photos || [],
        foodPhotos: experience.host.food_photos || []
      }
      : {
        // email: '',
        fullname: '',
        avatarUrl: '',
        hostReviewCount: '',
        hostRating: 0,
        reviewsReceivedAsHostCount: 0
      },
    menu: experience.menu
      ? {
        id: experience.menu.id,
        mealCategories: experience.menu.meal_categories || [],
        menuItems: experience.menu.menu_items,
        menuAllergens: experience.menu.menu_allergens
      }
      : {
        mealCategories: [],
        menuItems: '',
        menuAllergens: ''
      },
    photos: experience.gallery_images?experience.gallery_images:[],
    notes: experience.notes || [],
    guestReviews: experience.guest_reviews
      ? normalizeReviews(experience.guest_reviews)
      : [],
    hostReviews: experience.host_reviews ? normalizeReviews(experience.host_reviews) : []
  }
}

export function normalizeExperiences(experiences) {
  return experiences.map(experience => normalizeExperience(experience))
}
export function normalizeScheduleds(schedules) {
  return schedules.map(schedule => scheduleCut(schedule))
}

export function normalizeFeatured(featured) {
  return {
    featuredExperiences: normalizeExperiences(featured.featured_experiences),
    featuredHosts: normalizeHosts(featured.featured_hosts),
    // featuredReviews: normalizeReviews(featured.featured_reviews),
    featureGuestReviews: normalizeReviews(featured.featured_guest_reviews)
  }
}

function getValueOrString(item) {
  if (typeof item !== 'undefined') {
    if (item.value) {
      return item.value
    }
    if (typeof item.value === 'undefined') {
      return item
    }
  }

  return undefined
}

function addTimeToDate(date, time) {
  const d = moment.isMoment(date) ? date.utc() : moment.utc(date)
  let newDate = date
  if (time && typeof time === 'string') {
    const timeArray = time.split(':')
    newDate = d.set({
      hour: parseInt(timeArray[0], 10),
      minute: parseInt(timeArray[1], 10)
    })
  }
  return moment.utc(newDate).toISOString()
}

export function normalizeExperienceData(data) {
  const { menuAttributes, cuisineTypesAttributes } = data
  if (typeof data.StartTime !== 'undefined') {
    data.StartTime =
      typeof data.StartTime.value !== 'undefined'
        ? data.StartTime.value
        : data.StartTime
    data.date = addTimeToDate(data.date, data.StartTime)
  }

  if (typeof data.EndTime !== 'undefined') {
    data.EndTime =
      typeof data.EndTime.value !== 'undefined'
        ? data.EndTime.value
        : data.EndTime
    // data.endDate = addTimeToDate(data.endDate, EndTime);
  }

  // if (typeof data.time !== 'undefined') {
  //   const time =
  //     typeof data.time.value !== 'undefined' ? data.time.value : data.time;
  //   data.date = addTimeToDate(data.date, time);
  // }

  const photoArray = []

  return {
    ...data,
    seats:
      typeof data.seats !== 'undefined'
        ? data.seats.value || data.seats
        : undefined,
    currency:
      typeof data.currency !== 'undefined'
        ? data.currency.value || data.currency
        : undefined,
    cuisineTypesAttributes:
      cuisineTypesAttributes &&
      typeof cuisineTypesAttributes !== 'undefined' &&
      typeof cuisineTypesAttributes === 'object' &&
      Object.keys(cuisineTypesAttributes) &&
      cuisineTypesAttributes.length
        ? cuisineTypesAttributes.map(cuisineTypes => ({
          id: cuisineTypes.id || undefined,
          name: cuisineTypes.value || cuisineTypes.name || cuisineTypes
        }))
        : undefined,

    menuAttributes:
      typeof menuAttributes !== 'undefined' &&
      menuAttributes.menuItemsAttributes &&
      menuAttributes.menuItemsAttributes.length > 0
        ? {
          id: menuAttributes.id || undefined,
          mealCategoriesAttributes:
              menuAttributes &&
              typeof menuAttributes.mealCategories !== 'undefined' &&
              typeof menuAttributes.mealCategories === 'object' &&
              menuAttributes.mealCategories.length
                ? menuAttributes.mealCategories.map(option => ({
                  id: option.id || undefined,
                  name: option.value || option.name || option
                }))
                : undefined,
          menuAllergensAttributes:
              typeof menuAttributes.menuAllergensAttributes !== 'undefined' &&
              typeof menuAttributes.menuAllergensAttributes === 'object' &&
              menuAttributes.menuAllergensAttributes.length
                ? menuAttributes.menuAllergensAttributes.map(allergen => ({
                  id: allergen.id || undefined,
                  name: allergen.label || allergen.name || allergen
                }))
                : undefined,
          menuItemsAttributes:
              typeof menuAttributes.menuItemsAttributes !== 'undefined' &&
              typeof menuAttributes.menuItemsAttributes === 'object' &&
              menuAttributes.menuItemsAttributes.length
                ? menuAttributes.menuItemsAttributes.map(menuItem => ({
                  id: menuItem.id || undefined,
                  course: getValueOrString(menuItem.course),
                  title: menuItem.title,
                  description: menuItem.description,
                  photo:
                  menuItem.menu_item_photo && menuItem.menu_item_photo.length &&
                      typeof menuItem.menu_item_photo === 'object'
                    ? menuItem.menu_item_photo[0]
                    : menuItem.menu_item_photo
                }))
                : undefined
        }
        : undefined
  }
}

export function normalizeHostData(data) {
  return {
    ...data,
    birthday:
      /* eslint-disable-next-line no-underscore-dangle */
      typeof data.birthday === 'object' && data.birthday._isAMomentObject
        ? data.birthday.toDate() // Stringifies the moment vaue
        : data.birthday,
    specialties:
      typeof data.specialties !== 'undefined' &&
      typeof data.specialties === 'object' &&
      data.specialties.length
        ? data.specialties.map(specialty => ({
          id: specialty.id || undefined,
          name: specialty.value || specialty.name || specialty
        }))
        : undefined,
    locationPhotos:
      typeof data.locationPhotos !== 'undefined'
        ? data.locationPhotos.map(photo => ({
          ...photo
        }))
        : undefined,
    foodPhotos:
      typeof data.foodPhotos !== 'undefined'
        ? data.foodPhotos.map(photo => ({
          ...photo
        }))
        : undefined,
    location: {
      ...data.location
      // countryCode:
      //   data.location && typeof data.location.countryCode !== 'undefined'
      //     ? data.location.countryCode.value
      //     : null,
    },
    hostingLocation: {
      ...data.hostingLocation,
      countryCode:
        data.hostingLocation &&
        typeof data.hostingLocation.countryCode !== 'undefined'
          ? data.hostingLocation.countryCode.value
          : null
    }
  }
}
