import axios from 'axios'
import Swal from 'sweetalert2/dist/sweetalert2'
import { push } from 'connected-react-router'
import { HOST, authHeaderKeys } from '../constants'
import getHeadersFromStorage from './apiHeaders'
import { store, history } from '../configureStore'

import {
  setAuthHeaders,
  persistAuthHeadersInDeviceStorage
} from './auth-tools'

import { AUTH_TOKEN_FAILED } from '../constants/actionTypes'
/*
 * The function that subscribes to our redux store changes
 * Sets up new api headers for the global axios settings.
 * https://github.com/axios/axios#global-axios-defaults
 * Caveat - this will change the headers for every axios request
 * not just the one used for the api instance.
 */

function setNewApiHeaders() {
  getHeadersFromStorage().then(headers => {
    authHeaderKeys.forEach(key => {
      axios.defaults.headers.common[key] = headers[key]
    })
  })
}

/*
 * Adds a change listener. https://redux.js.org/api/store#subscribe-listener
 * It will be called any time an action is dispatched, and some
 * part of the state tree may potentially have changed. You may then
 * call getState() to read the current state tree inside the callback.
 */

store.subscribe(() => setNewApiHeaders())

/*
 * Setups up a new instance of axios.
 * https://github.com/axios/axios#creating-an-instance
 *
 */
const apiConfig = {
  baseURL: `${HOST}/api/v2`,
  headers: {
    allow_origin: '*',
    'Content-Type': 'application/json'
  }
}

const api = axios.create(apiConfig)

/*
 * Axios Request Interceptor.
 * Before each request, use the values in local storage to set new headers.
 */

api.interceptors.request.use(
  async config => {
    const newConfig = config
    // If url include v2 that is passing from action then remove /v1 from baseURL
    if (newConfig.url.includes('v1')) {
      newConfig.baseURL = newConfig.baseURL.replace('/v2', '')
    }
    const newHeaders = await getHeadersFromStorage()
    // console.log('API Request INTERCEPTOR', newHeaders);
    switch (config.url) {
    // We dont want to set headers for validate token requests.
    // Get them directly via params passed into the request.
    case 'auth/validate_token': {
      newConfig.headers = {
        ...apiConfig.headers
      }
      break
    }
    default: {
      newConfig.headers = {
        ...config.headers, // Spread config headers
        ...newHeaders // Spread new headers
      }
    }
    }

    return newConfig
  },
  // Reject the promise
  error => Promise.reject(error)
)

const setAllHeaders = async headers => {
  await setAuthHeaders(headers)
  await persistAuthHeadersInDeviceStorage(headers)
}

const forceSignOutReset = () => ({
  type: AUTH_TOKEN_FAILED
})

// const fireSessionExpiredAlert = () => {
//   const sweetness = Swal.mixin({
//     confirmButtonClass: 'btn',
//     cancelButtonClass: 'btn btn-danger',
//     buttonsStyling: false,
//     showCancelButton: true,
//     confirmButtonText: 'Login Again'
//   })

//   return sweetness({
//     title: 'Session Expired',
//     text: 'Your session has expired.',
//     type: 'warning',
//     showCancelButton: false,
//     confirmButtonText: 'Login again'
//   }).then(result => {
//     if (result.value) {
//       history.push('/login')
//     }
//   })
// }

const fireNetworkError = error => {
  const sweetness = Swal.mixin({
    confirmButtonClass: 'btn',
    buttonsStyling: false,
    showCancelButton: true
  })

  return sweetness({
    title: 'Error',
    text: error,
    type: 'error',
    showCancelButton: false,
    confirmButtonText: 'Reload Page'
  }).then(result => {
    if (result.value) {
      history.go(0)
    }
  })
}

/*
 * Axios Respose Interceptor.
 * After each response, use the values in local storage to set new headers.
 */

api.interceptors.response.use(
  async response => {
    // Only change headers when access-token is returned in response
    // console.log('API Response INTERCEPTOR', response.headers);
    if (typeof response.headers['access-token'] === 'string') {
      await setAllHeaders(response.headers)
    }

    return response
  },
  error => {
    if (typeof error.response === 'undefined') {
      fireNetworkError(error)
      store.dispatch(forceSignOutReset())
    } else if (error.response.status === 401) {
      // fireSessionExpiredAlert();
      if (localStorage.getItem('client')) store.dispatch(push('/')) // Remove login to blank
      store.dispatch(forceSignOutReset())
    } else if (
      error.response.status === 404
      && typeof error.response.errors !== 'undefined'
      && error.response.data.errors[0] === 'User was not found or was not logged in.'
    ) {
      store.dispatch(forceSignOutReset())
      // fireSessionExpiredAlert();
    } else if (error.response.status !== 401) {
      // Only change headers when access-token is returned in response
      // console.log('API Response INTERCEPTOR', response.headers);
      if (typeof error.response.headers['access-token'] === 'string') {
        setAllHeaders(error.response.headers)
      }
    }

    // Reject the promise
    return Promise.reject(error)
  }
)

// Uncomment (for testing) to use the api in the console
if (typeof window !== 'undefined') {
  window.consoleApi = api
}

export default api
