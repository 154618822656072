import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension'
import createExpirationTransform from 'redux-persist-transform-expire'
// import LogRocket from 'logrocket'

import { persistStore, persistReducer, createTransform } from 'redux-persist'

import storage from 'redux-persist/lib/storage/index'
import rootReducer from './reducers/index'

/* History for Connected Router */
const history = createBrowserHistory({
  basename: '/'
})

/* Add history for middleware
https://github.com/supasate/connected-react-router#step-1 */
const routerHistory = routerMiddleware(history)

/* Add Middlewares here for inclusion */
// const middlewares = [thunk, routerHistory, LogRocket.reduxMiddleware()]
const middlewares = [thunk, routerHistory]

/* Persist Expiration Transforms
* gabceb/redux-persist-transform-expire */
const expireTransform = createExpirationTransform({
  expireKey: 'persistExpiresAt'
})

/* Persist Settings and Persist Reducer */
const persistConfig = {
  key: 'root',
  storage,
  debug: true,
  blacklist: ['router', 'tabs', 'transactions', 'paymentMethods', 'experience', "user", "forgotPassword", "modal"],
  transforms: [expireTransform]
  // whitelist: ['navigation'], // only navigation will be persisted
  // blacklist: ['navigation'] // navigation will not be persisted
}

const persistedReducer = persistReducer(
  persistConfig,
  connectRouter(history)(rootReducer)
)

const composeEnhancers = composeWithDevTools({
  name: 'Main App'
})

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares))
)
const persistor = persistStore(store, null, () => {
  store.getState() // if you want to get restoredState
})

export { store, persistor, history }
