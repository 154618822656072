import moment from 'moment'

const genderOptions = [
  {
    value: 'female',
    label: 'Female'
  },
  {
    value: 'male',
    label: 'Male'
  },
  {
    value: 'prefer_not_to_disclose',
    label: 'Prefer Not To Disclose'
  },
  {
    value: 'not_sure',
    label: 'Not Sure'
  }
]



/* const cuisineTypes = [
  {
    label :"Afghan",
    value :"Afghan"
  },
  {
    label :"African",
    value :"African"
  },
  {
    label :"American",
    value :"American"
  },
  {
    label :"Argentinian",
    value :"Argentinian"
  },
  {
    label :"Armenian",
    value :"Armenian"
  },
  {
    label :"Australian",
    value :"Australian"
  },
  {
    label :"Azerbaijani",
    value :"Azerbaijani"
  },
  {
    label :"Barbecue",
    value :"Barbecue"
  },
  {
    label :"Belgian",
    value :"Belgian"
  },
  {
    label :"Brazilian",
    value :"Brazilian"
  },
  {
    label :"British",
    value :"British"
  },
  {
    label :"Cambodian",
    value :"Cambodian"
  },
  {
    label :"Caribbean",
    value :"Caribbean"
  },
  {
    label :"Chilean",
    value :"Chilean"
  },
  {
    label :"Chinese",
    value :"Chinese"
  },
  {
    label :"Contemporary",
    value :"Contemporary"
  },
  {
    label :"Cuban",
    value :"Cuban"
  },
  {
    label :"Danish",
    value :"Danish"
  },
  {
    label :"Desserts/ Bakery ",
    value :"Desserts/ Bakery "
  },
  {
    label :"Dutch",
    value :"Dutch"
  },
  {
    label :"Eastern Europe",
    value :"Eastern Europe"
  },
  {
    label :"Egyptian",
    value :"Egyptian"
  },
  {
    label :"Emirati",
    value :"Emirati"
  },
  {
    label :"Estonian",
    value :"Estonian"
  },
  {
    label :"Ethiopian",
    value :"Ethiopian"
  },
  {
    label :"Filipino",
    value :"Filipino"
  },
  {
    label :"French",
    value :"French"
  },
  {
    label :"Fusion",
    value :"Fusion"
  },
  {
    label :"Georgian",
    value :"Georgian"
  },
  {
    label :"German",
    value :"German"
  },
  {
    label :"Greek",
    value :"Greek"
  },
  {
    label :"Hawaiian",
    value :"Hawaiian"
  },
  {
    label :"Hungarian",
    value :"Hungarian"
  },
  {
    label :"Indian",
    value :"Indian"
  },
  {
    label :"Indonesian",
    value :"Indonesian"
  },
  {
    label :"International",
    value :"International"
  },
  {
    label :"Iranian",
    value :"Iranian"
  },
  {
    label :"Irish",
    value :"Irish"
  },
  {
    label :"Italian",
    value :"Italian"
  },
  {
    label :"Jamaican",
    value :"Jamaican"
  },
  {
    label :"Japanese",
    value :"Japanese"
  },
  {
    label :"Korean",
    value :"Korean"
  },
  {
    label :"Latin American",
    value :"Latin American"
  },
  {
    label :"Latvian",
    value :"Latvian"
  },
  {
    label :"Lebanese",
    value :"Lebanese"
  },
  {
    label :"Lithuanian",
    value :"Lithuanian"
  },
  {
    label :"Malaysian",
    value :"Malaysian"
  },
  {
    label :"Mexican",
    value :"Mexican"
  },
  {
    label :"Middle Eastern",
    value :"Middle Eastern"
  },
  {
    label :"Moroccan",
    value :"Moroccan"
  },
  {
    label :"Nepalese",
    value :"Nepalese"
  },
  {
    label :"Other",
    value :"Other"
  },
  {
    label :"Pakistani",
    value :"Pakistani"
  },
  {
    label :"Peruvian",
    value :"Peruvian"
  },
  {
    label :"Portuguese",
    value :"Portuguese"
  },
  {
    label :"Russian",
    value :"Russian"
  },
  {
    label :"Scandinavian",
    value :"Scandinavian"
  },
  {
    label :"Seafood",
    value :"Seafood"
  },
  {
    label :"Singaporean",
    value :"Singaporean"
  },
  {
    label :"Spanish",
    value :"Spanish"
  },
  {
    label :"Sri Lankan",
    value :"Sri Lankan"
  },
  {
    label :"Syrian",
    value :"Syrian"
  },
  {
    label :"Thai",
    value :"Thai"
  },
  {
    label :"Tunisian",
    value :"Tunisian"
  },
  {
    label :"Turkish",
    value :"Turkish"
  },
  {
    label :"Ukrainian",
    value :"Ukrainian"
  },
  {
    label :"Vietnamese",
    value :"Vietnamese"
  },
  {
    label :"Yemeni",
    value :"Yemeni"
  },
  {
    label :"Vegetarian",
    value :"Vegetarian"
  },
  {
    label :"Vegan",
    value :"Vegan"
  },
  {
    label :"Plant Based",
    value :"Plant Based"
  }
] */

/* const dishTypes = [
  { value: 'Soup', label: 'Soup', order: 1 },
  { value: 'Salad', label: 'Salad', order: 2 },
  { value: 'Appetizer', label: 'Appetizer', order: 3 },
  { value: 'Amuse Bouche', label: 'Amuse Bouche', order: 4 },
  { value: 'Canape', label: 'Salad', order: 5 },
  { value: 'Starter', label: 'Starter', order: 6 },
  { value: 'Tapas', label: 'Tapas', order: 7 },
  { value: 'Finger Food', label: 'Finger Food', order: 8 },
  { value: 'Mezze', label: 'Mezze', order: 9 },
  { value: 'Side Dish', label: 'Side Dish', order: 10 },
  { value: 'Main Dish', label: 'Main Dish', order: 11 },
  { value: 'Dessert', label: 'Dessert', order: 12 },
  { value: 'Beverage', label: 'Beverage', order: 13 },
  { value: 'Palate Cleanser', label: 'Palate Cleanser', order: 14 }
] */

/* const allergenTypes = [
  {
    value: "None",
    label: "None"
  },
  {
    value: "Peanuts",
    label: "Peanuts"
  },
  {
    value: "Soy",
    label: "Soy"
  },
  {
    value: "Shellfish",
    label: "Shellfish"
  },
  {
    value: "Fish",
    label: "Fish"
  },
  {
    value: "Dairy",
    label: "Dairy"
  },
  {
    value: "Egg",
    label: "Egg"
  },
  {
    value: "Sesame",
    label: "Sesame"
  },
  {
    value: "Tree nuts",
    label: "Tree nuts"
  },
  {
    value: "Wheat",
    label: "Wheat"
  },
  {
    value: "Eggs",
    label: "Eggs"
  }
] */

/* const mealCategories = [
  {
    value: "Vegan",
    label: "Vegan"
  },
  {
    value: "Vegetarian",
    label: "Vegetarian"
  },
  {
    value: "Gluten Free",
    label: "Gluten Free"
  },
  {
    value: "Organic",
    label: "Organic"
  },
  {
    value: "Halal",
    label: "Halal"
  },
  {
    value: "Non-Halal",
    label: "Non-Halal"
  },
  {
    value: "Kosher",
    label: "Kosher"
  },
  {
    value: "Lactose Free",
    label: "Lactose Free"
  },
  {
    value: "BYOW",
    label: "BYOW"
  },
  {
    value: "Non Halal",
    label: "Non Halal"
  },
  {
    value: "Pork",
    label: "Pork"
  },
  {
    value: "Beef",
    label: "Beef"
  }
] */

const languages = require('language-list')()

const cityOptions = [
  {
    value: 'Dubai',
    label: 'Dubai'
  },
  {
    value: 'Sharjah',
    label: 'Sharjah'
  },
  {
    value: 'Abu Dhabi',
    label: 'Abu Dhabi'
  },
  {
    value: 'Thessaloniki',
    label: 'Thessaloniki'
  },
  {
    value: 'London',
    label: 'London'
  }
]

const countryOptions = [
  {
    value: 'AE',
    label: 'United Arab Emirates'
  },
  {
    value: 'GR',
    label: 'Greece'
  },
  {
    value: 'GB',
    label: 'United Kingdom'
  }
]

const backgroundFieldCollection = {
  gender: {
    name: 'Gender',
    placeholder: 'Gender',
    options: genderOptions
  },
  birthday: {
    name: 'birthday',
    placeholder: '08/29/1980',
    value: null // Needs to be a moment obj
  },
  languages: {
    name: 'languages',
    options: languages.getData(),
    autoComplete: '',
    placeholder: 'Languages',
    label: 'Languages (Select more than one)'
  },
  preferredCurrency: {
    name: 'UAE Dirham',
    value: 'AED',
    key: 'AED',
    label: 'د.إ / AED',
    number: '784',
    symbol: 'د.إ'
  }
}

const personalFieldCollection = {
  firstName: {
    name: 'firstName',
    type: 'text',
    autoComplete: '',
    placeholder: 'First Name'
  },
  lastName: {
    name: 'lastName',
    type: 'text',
    autoComplete: '',
    placeholder: 'Last Name'
  }
}

// NOTE: See how we added dot notation to the Name
// this will be sent as a location object
// const addressFieldCollection = {
//   address: {
//     name: 'location.streetAddress',
//     type: 'text',
//     autoComplete: '',
//     // value: '',
//     // placeholder: 'Street Address',
//     placeholder: 'Area/ District (this will show publicly on the website)',
//   },
//   city: {
//     name: 'location.city',
//     options: cityOptions,
//     // value: '',
//     placeholder: 'City',
//   },
//   postalCode: {
//     name: 'location.postalCode',
//     type: 'text',
//     autoComplete: '',
//     // placeholder: 'Postal Code',
//     placeholder:
//       'Building name, Apt, flat or floor number (this will be shared privately with your guests)',
//   },
//   countryCode: {
//     name: 'location.countryCode',
//     placeholder: 'Country',
//     options: [],
//     // value: '',
//     excludeInSet: true,
//   },
// };

const addressFieldCollection = {
  location: {
    address: {
      name: 'location.streetAddress',
      type: 'text',
      autoComplete: '',
      // value: '',
      // placeholder: 'Street Address',
      placeholder: 'Building name, Apt, flat or floor number (this will be shared privately with your guests)'
    },
    city: {
      name: 'location.city',
      options: cityOptions,
      // value: '',
      type: 'text',
      autoComplete: '',
      placeholder: 'City'
    },
    postalCode: {
      name: 'location.postalCode',
      type: 'text',
      autoComplete: '',
      // placeholder: 'Postal Code',
      placeholder: 'Postal Code'
    },
    countryCode: {
      name: 'location.countryCode',
      placeholder: 'Country',
      options: [],
      // value: '',
      excludeInSet: true
    },
    country_code: {
      name: 'location.countryCode',
      placeholder: 'Country Code',
      options: [],
      // value: '',
      excludeInSet: true
    }
  }
}

const hostAddressFields = {
  address: {
    name: 'hostingLocation.streetAddress',
    type: 'text',
    autoComplete: '',
    // placeholder: 'Street Address',
    placeholder: 'Building name, Apt, flat or floor number (this will be shared privately with your guests)'
  },
  city: {
    name: 'hostingLocation.city',
    options: cityOptions,
    value: '',
    placeholder: 'City'
  },
  postalCode: {
    name: 'hostingLocation.postalCode',
    type: 'text',
    autoComplete: '',
    // placeholder: 'Postal Code',
    placeholder: 'Postal Code'
  },
  neighbourhood: {
    name: 'hostingLocation.neighbourhood',
    type: 'text',
    autoComplete: '',
    // placeholder: 'Postal Code',
    placeholder: 'Neighbourhood'
  },
  countryCode: {
    name: 'hostingLocation.countryCode',
    placeholder: 'Country',
    options: countryOptions,
    value: '',
    excludeInSet: true
  },
  destination: {
    name: 'hostingLocation.destinationLink',
    type: 'text',
    autoComplete: '',
    placeholder: 'Location Map Link',
    helperText: "Please paste the Google map 'link to share' for the hosting location. eg: https://goo.gl/maps/xxxxxxxxxxxxx"
  }
}

const email = {
  name: 'email',
  type: 'text',
  autoComplete: 'email',
  placeholder: 'youremail@gmail.com',
  label: 'Email',
  displayLabel: true
}

const city = {
  name: 'city',
  options: cityOptions,
  value: '',
  placeholder: 'City'
}

const countryCode = {
  name: 'countryCode',
  placeholder: 'Country',
  options: countryOptions,
  value: '',
  excludeInSet: true
}

const phoneNumber = {
  name: 'phone_number',
  type: 'text',
  autoComplete: 'tel',
  placeholder: '(xxx) xxx xxxx',
  label: 'Mobile Number',
  displayLabel: true
}

const password = {
  name: 'password',
  type: 'password',
  autoComplete: 'password',
  placeholder: '••••••••',
  label: 'Password',
  displayLabel: true
}

const passwordConfirmation = {
  name: 'passwordConfirmation',
  type: 'password',
  autoComplete: 'new-password',
  placeholder: '••••••••',
  label: 'Password Confirmation',
  displayLabel: true
}

const contactFieldCollection = {
  email: {
    name: 'email',
    type: 'text',
    autoComplete: 'email',
    placeholder: 'youremail@gmail.com',
    label: 'Email',
    displayLabel: true
  },
  phone: {
    name: 'mobileNumber',
    type: 'text',
    autoComplete: 'tel',
    placeholder: '(xxx) xxx xxxx',
    label: 'Mobile Number',
    displayLabel: true
  }
}

const professionalFieldCollection = {
  specialties: {
    name: 'specialties',
    placeholder: 'Cuisine / Specialties',
    // options: cuisineTypes,
    value: null
  },
  cuisine: {
    name: 'cuisineTypes',
    placeholder: 'Cuisine',
    // options: cuisineTypes,
    value: null
  },
  culinaryJourney: {
    name: 'culinaryJourney',
    type: 'textArea',
    autoComplete: '',
    label: 'Bio *',
    placeholder:
      'Our guests want to get to know you!'
  },
  acceptTerms: {
    name: 'acceptTerms',
    type: 'checkbox',
    autoComplete: '',
    placeholder: 'accept terms',
    value: false
  },
  socialLinks: {
    facebook: {
      name: 'socialLinks.facebook',
      type: 'text',
      autoComplete: '',
      value: '',
      placeholder: 'https://www.facebook.com/',
      addon: true,
      addonIcon: 'facebook'
    },
    instagram: {
      name: 'socialLinks.instagram',
      type: 'text',
      autoComplete: '',
      value: '',
      placeholder: 'https://www.instagram.com/',
      addon: true,
      addonIcon: 'instagram'
    },
    twitter: {
      name: 'socialLinks.twitter',
      type: 'text',
      autoComplete: '',
      value: '',
      placeholder: 'https://www.twitter.com/',
      addon: true,
      addonIcon: 'twitter'
    }
  }
}

const menuItemFieldCollection = {
  title: {
    name: 'title',
    type: 'text',
    autoComplete: '',
    placeholder: 'Title'
  },
  course: {
    name: 'course',
    placeholder: 'Course',
    // options: dishTypes,
    value: null
  },
  description: {
    name: 'description',
    type: 'text',
    autoComplete: '',
    placeholder: 'Description'
  },
  allergens: {
    name: 'allergens',
    // placeholder: 'Allergens (Optional)',
    placeholder:
      "Allergens (It's important to let your guests know of any food allergens contained in your meal)",
    // options: allergenTypes,
    value: null,
    label: 'Let your guests know if your meal contains any food allergens'
  },
  mealCategories: {
    name: 'mealCategories',
    type: 'checkbox',
    // options: mealCategories,
    value: null,
    label: 'Check any of the below options that your meal falls under'
  }
}

export {
  // cuisineTypes,
  backgroundFieldCollection,
  personalFieldCollection,
  addressFieldCollection,
  hostAddressFields,
  contactFieldCollection,
  professionalFieldCollection,
  menuItemFieldCollection,
  email,
  phoneNumber,
  password,
  passwordConfirmation,
  city,
  countryCode // ,
  // dishTypes
}
