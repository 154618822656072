import React,{Component} from 'react'
import PropTypes from 'prop-types'
import inputPropTypes from './propTypes'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye,faEyeSlash } from "@fortawesome/free-solid-svg-icons"
const eye = <FontAwesomeIcon icon={faEye} />
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />

class renderField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showPassword: false
    }
  }

  render() {
    const {
      form,
      field,
      input,
      type, // passed by field or input
      // value, // passed by field or input
      name,
      label,
      disabled,
      displayLabel,
      inputType,
      placeholder,
      removeLabelCompletely,
      meta: { touched, error },
      initialValue
    } = this.props
  
    /*

  You can pass in a shape through the 'field' prop,
  
  or use the individual props provided.

  This is what the field shape could look like.

  field: {
    name: 'fullName',
    type: 'text',
    autoComplete: 'name',
    placeholder: 'Full Name'
  },

   */
    const fieldName = field.name || name
    const fieldLabel = field.label || label || field.label || field.placeholder
    const customClass = field.className ?  field.className : ""
    const errored = touched && error
    const inputValidationClass = errored ? 'input--error' : ''
    const labelValidationClass = errored ? 'error' : ''
    const labelClass = displayLabel ? '' : 'label-hidden'
    const renderType = typeof inputType !== 'undefined' ? inputType : 'input'
    const controlType = field.type || input.type || type || ''
    const setPlaceHolder =
    input.placeholder || field.placeholder || placeholder || ''

    const renderInputField = () => {
      if (field.addon) {
        return (
          <div className="input-group">

            { controlType == "password" ? 
              <React.Fragment>
              

                <input
                  // {...field}
                  placeholder={setPlaceHolder}
                  {...input}
                  id={fieldName}
                  disabled={disabled}
                  type={this.state.showPassword ? "text": controlType}
                  className={`${inputValidationClass} ${customClass}`}
            
                />

                <div className="input-group-addon cursor" onClick={()=>{
                  let showPassword = !this.state.showPassword
                  this.setState({showPassword})}
                
                } style={{padding:"10px"}}>
           

                  <i >{this.state.showPassword ? eyeSlash  : eye }</i>
                </div>
        
              </React.Fragment>

              :

              <React.Fragment>
                <div className="input-group-addon">  
                  <i className={`icon icon--${field.addonIcon}`} />
                </div>

                <input
                  // {...field}
                  placeholder={setPlaceHolder}
                  {...input}
                  id={fieldName}
                  disabled={disabled}
                  type={controlType}
                  className={`${inputValidationClass} ${customClass}`}
            
                />
        
              </React.Fragment>

            }
          </div>
          
        )
      }
      return (
        <input
        // {...field}
          placeholder={setPlaceHolder}
          {...input}
          id={fieldName}
          disabled={disabled}
          type={controlType}
          className={`${inputValidationClass}  ${customClass}`}
        />
      )
    }
    return (
      <React.Fragment>
        {fieldLabel &&
        !removeLabelCompletely && (
          <label
            htmlFor={fieldName}
            className={`${labelClass} ${labelValidationClass}`}
          >
            {fieldLabel}
          </label>
        )}
        <React.Fragment>

          {renderType === 'phnNumber' && (
            <input
              // {...field}
              width="100%"
              style={{ width: '100%' }}
              placeholder={setPlaceHolder}
              {...input}
              id={fieldName}
              disabled={disabled}
              //  type="number"
              className={`${inputValidationClass}  ${customClass}`}
              //  maxlength={props.maxNumber}
              onKeyUp={()=>{input.value=input.value.replace(/[^\d]/,'')}}
            />
          )}

          {renderType === 'textArea' && (
            <textarea
              rows="7"
              cols="50"
              width="100%"
              style={{ width: '100%' }}
              // {...field}
              placeholder={setPlaceHolder}
              {...input}
              id={fieldName}
              disabled={disabled}
              className={inputValidationClass}
            />
          )}

          {renderType === 'wideText' && (
            <input
            // {...field}
              width="50%"
              style={{ width: '50%' }}
              placeholder={setPlaceHolder}
              {...input}
              id={fieldName}
              disabled={disabled}
              type={controlType}
              className={`${inputValidationClass}  ${customClass}`}
            />
          )}

          {renderType === 'fullText' && (
            <input
            // {...field}
              width="100%"
              style={{ width: '100%' }}
              placeholder={setPlaceHolder}
              {...input}
              id={fieldName}
              disabled={disabled}
              type={controlType}
              className={`${inputValidationClass}  ${customClass}`}
            />
          )}

          {renderType !== 'textArea' && renderType !== 'wideText' && renderType !== 'fullText' && renderType !== 'phnNumber' && (
            <React.Fragment>{renderInputField()}</React.Fragment>
          )}
          {errored && <div className="form-error form-error-div">{error}</div>}
        </React.Fragment>
      </React.Fragment>
    )}
};

renderField.defaultProps = {
  field: {
    name: '',
    type: '',
    autoComplete: '',
    placeholder: ''
  },
  value: '',
  name: '',
  label: '',
  disabled: false,
  displayLabel: false
}

renderField.propTypes = inputPropTypes

export default renderField
