import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

/* Actions */
import * as userActionCreators from '../../../actions/user'

import seo_meta from '../../../utils/seo-meta.json'
import { fileUpload, updateUser } from '../../../actions/user'

import AvatarUpload from '../../Form/Fields/AvatarUpload'
import SEO from '../../Shared/Web/SEO'

const fileTypes = ['image/jpeg', 'image/pjpeg', 'image/png']

function validFileType(file) {
  for (let i = 0; i < fileTypes.length; i += 1) {
    if (file.type === fileTypes[i]) {
      return true
    }
  }
  return false
}

function returnFileSize(number) {
  if (number < 1024) {
    return `${number}bytes`
  }
  if (number >= 1024 && number < 1048576) {
    return `${(number / 1024).toFixed(1)}KB`
  }
  if (number >= 1048576) {
    return `${(number / 1048576).toFixed(1)}MB`
  }
  return `${number}bytes`
}

class UploadProfileImage extends Component {
  /* eslint-disable-next-line */
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount = () => {
    const { currentUser, nextPage } = this.props

    const avatarPresent =
      typeof currentUser.attributes.avatarUrl !== 'undefined' &&
      !currentUser.attributes.avatarUrl.includes('/thumb_default.png')

    if (avatarPresent) {
      nextPage()
    } else {
    }
  }

  async handleSubmit(e) {
    e.preventDefault()
    const { handleSubmit } = this.props
    await handleSubmit(e)
  }

  render() {
    const { pristine, submitting, nextPage, activeFormType } = this.props
    // this.props.dispatchUpdateUser = userActions.updateUser;
    const {
      title,
      description,
      og_title,
      og_description,
      og_site_name,
      og_locale,
      og_type
    } = seo_meta.upload_profile_pic
    return (
      <React.Fragment>
        <SEO title={title} description={description} />

        <form onSubmit={this.handleSubmit} className="text-center">
          <div className="form-body">
            <AvatarUpload {...this.props} goToNext={true} />
            <p>
              {/* <button
                type="submit"
                disabled={submitting || pristine}
                onClick={data => {
                  // this.closeAndExit();
                }}
                className="btn minWidth200"
              >
                Submit
              </button> */}
              {/* {this.props.pathname !== '/become-a-host' && activeFormType !== 'BECOME_HOST' && this.props.pathname !== '/become-a-host/step-1' ? (
                <React.Fragment>
                  <br/>
                  <button
                    type="button"
                    className="btn btn--text btn--link"
                    onClick={() => nextPage()}
                  >
                  Skip
                  </button>
                </React.Fragment>
              ):''
              } */}
            </p>
          </div>
          {this.props.pathname == '/become-a-host' || activeFormType === 'BECOME_HOST' ? this.props.pathname == '/become-a-host/step-1' ? null :(
            <div className="form-footer">
              <button
                type="button"
                className="btn btn--darker form-footer--right"
                onClick={() => nextPage()}
              >
                Continue
              </button>
            </div>
          ) : null}
        </form>
      </React.Fragment>
    )
  }
}

UploadProfileImage.propTypes = {
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  nextPage: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(userActionCreators, dispatch),
  dispatchUpdateUser: attributes => dispatch(updateUser(attributes))
})

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  currentUser: state.reduxTokenAuth.currentUser,
  stepForm: state.stepForm.registration
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadProfileImage)
