import { generateAuthActions } from 'redux-token-auth'
import {
  authUrl,
  userAttributes,
  userRegistrationAttributes
} from '../constants'

/* Allows us to transform and sanitize submissions.
*  Values here are what the server expects.
*  Attribute / Property names are the ones from the client.
*/
const config = {
  authUrl,
  userAttributes,
  userRegistrationAttributes,
  storage: {
    flushGetRequests: false
  }
}

const {
  registerUser,
  signInUser,
  verifyToken,
  signOutUser,
  verifyCredentials
} = generateAuthActions(config)

export {
  registerUser,
  signInUser,
  signOutUser,
  verifyToken,
  verifyCredentials,
  config
}
