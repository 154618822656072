import React from "react"

const SvgVisaIcon = props => (
  <svg id="visa-icon" viewBox="0 0 65.611 40.987" {...props}>
    <defs>
      <style>{`.visa-2{fill:#0066b2}`}</style>
    </defs>
    <path
      id="Path_274"
      data-name="Path 274"
      d="M65.558 38.791a2.209 2.209 0 0 1-2.2 2.2H2.2a2.209 2.209 0 0 1-2.2-2.2V2.2A2.209 2.209 0 0 1 2.2 0h61.215a2.209 2.209 0 0 1 2.2 2.2v36.6z"
      fill="#f9f9f9"
    />
    <g
      id="Group_123"
      data-name="Group 123"
      transform="translate(13.122 14.115)"
    >
      <g id="Group_122" data-name="Group 122">
        <path
          id="Path_275"
          data-name="Path 275"
          className="visa-2"
          d="M54.789 39.738H51.6L53.587 27.4h3.241z"
          transform="translate(-37.746 -27.191)"
        />
        <path
          id="Path_276"
          data-name="Path 276"
          className="visa-2"
          d="M36.236 27.4L33.2 35.869l-.366-1.83-1.1-5.542s-.1-1.1-1.516-1.1h-5.066l-.052.209a11.3 11.3 0 0 1 3.346 1.412l2.823 10.717h3.346L39.686 27.4z"
          transform="translate(-25.1 -27.191)"
        />
        <path
          id="Path_277"
          data-name="Path 277"
          className="visa-2"
          d="M86.917 39.786h2.928L87.283 27.5h-2.562a1.431 1.431 0 0 0-1.464.941L78.5 39.786h3.346l.68-1.83H86.6zm-3.5-4.391l1.673-4.6.941 4.6z"
          transform="translate(-50.583 -27.239)"
        />
        <path
          id="Path_278"
          data-name="Path 278"
          className="visa-2"
          d="M70.6 30.189l.471-2.666A9.452 9.452 0 0 0 68.192 27c-1.568 0-5.385.68-5.385 4.078 0 3.189 4.444 3.241 4.444 4.862 0 1.673-3.973 1.359-5.28.314l-.471 2.77a9.013 9.013 0 0 0 3.607.68c2.2 0 5.489-1.15 5.489-4.235 0-3.189-4.5-3.5-4.5-4.862.057-1.359 3.141-1.15 4.504-.418z"
          transform="translate(-42.47 -27)"
        />
      </g>
      <path
        id="Path_279"
        data-name="Path 279"
        d="M32.837 34.039l-1.1-5.542s-.1-1.1-1.516-1.1h-5.069l-.052.209a12.846 12.846 0 0 1 4.757 2.4 9.443 9.443 0 0 1 2.98 4.033z"
        transform="translate(-25.1 -27.191)"
        fill="#f9a533"
      />
    </g>
  </svg>
)

export default SvgVisaIcon
