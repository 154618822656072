import TimelineLite from 'gsap/TimelineLite'

const defaultVars = { paused: true, timeScale: 0.5 }

const WSIZE = function(s) {
  const sizes = {
    xs: 375,
    sm: 768,
    md: 1024,
    lg: 1366,
    xl: 1600
  }
  const w = $(window).width()
  return sizes[s] >= w
}

const maxOverheat = false

const transitions = {
  mobileMenu: {
    open(tween, vars) {
      const tl = new TimelineLite($.extend(true, vars || {}, defaultVars))

      tl.fromTo(
        tween.find('.breakbread-mobile-menu-backdrop'),
        0.3,
        {
          opacity: 0
        },
        {
          opacity: 1,
          ease: Power2.easeInOut
        }
      )

      tl.fromTo(
        tween.find('.breakbread-mobile-menu-box'),
        0.4,
        {
          x: '-100%'
        },
        {
          x: '0%',
          ease: Power2.easeInOut
        }
      )

      /* Remove transform matrix */
      tl.add(() => {
        tween
          .find('.breakbread-mobile-menu-form .row')
          .css('transform', 'none')
      })

      return tl
    },
    close(tween, vars) {
      const tl = new TimelineLite($.extend(true, vars || {}, defaultVars))

      tl.fromTo(
        tween.find('.breakbread-mobile-menu-backdrop'),
        0.3,
        {
          opacity: 1
        },
        {
          opacity: 0,
          ease: Power2.easeInOut
        }
      )

      tl.fromTo(
        tween.find('.breakbread-mobile-menu-box'),
        0.4,
        {
          x: '0%'
        },
        {
          x: '-100%',
          ease: Power2.easeInOut
        },
        '-=0.3'
      )

      tl.fromTo(
        tween.find('.breakbread-mobile-menu-inner'),
        0.2,
        {
          opacity: 1
        },
        {
          opacity: 0,
          ease: Power2.easeInOut
        },
        '-=0.4'
      )

      tl.set(tween.find('.breakbread-mobile-menu-inner'), {
        opacity: 1
      })

      return tl
    }
  }
}

export default transitions
