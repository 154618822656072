import React from 'react'
import PropTypes from 'prop-types'
import DropZone from 'react-dropzone'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as userActionCreators from '../../../actions/user'

const handleImageMultiUpload = async (fileArray, props) => {
  const { userActions, input, parentComponent, locationId, experienceId } = props
  userActions.imageMultiUpload(fileArray, input, parentComponent, locationId, experienceId)
}

const handleRemove = ({ index, input, value, props }) => {
  const id = value[index] && value[index].id?value[index].id:null
  const newFiles = value
  newFiles.splice(index, 1)
  /**
   * A function to call when the form field is changed. It expects to either receive the
   *  React SyntheticEvent or the new value of the field.
   */

  input.onChange([...newFiles]) // Avoid mutation by using spread
  const { userActions, parentComponent, locationId, experienceId } = props
  if(parentComponent === 'profilePhotos' && id) {
    userActions.deleteGalleryImage(id)
    /* experiencePhotos
experienceId */
  } else if (parentComponent === 'locationPhotos' && id && locationId) {
    userActions.deleteHostGalleryImage(id, locationId)
  } else if (parentComponent === 'experiencePhotos' && id && experienceId) {
    userActions.deleteExperienceGalleryImage(id, experienceId)
  }
}
const renderImagePreview = (input, removeFunction, isMenuItem, props) => {
  let { value } = input
  if (typeof value[0] === 'string') {
    value = [
      {
        preview: value
      }
    ]
  }
  if (typeof value === 'object' && !value.length) {
    value = [value]
  }
  const menuItemClass = isMenuItem
    ? ''
    : 'grid--item grid__item small--one-whole medium-down--one-half medium--one-half large-up--one-fifth'

  return value.map((file, index) => {
    const { filename, preview, byteSize, small, id } = file
    return (<React.Fragment key={index}>
      {preview?(<div className={menuItemClass} key={preview}>
        <img
          style={{ display: 'block', margin: 'auto', paddingTop: '10%' }}
          src={preview}
          alt={filename}
          height="auto"
          width="100%"
        />
        <span style={{ textAlign: 'center' }} key="imageDetails">
          {filename}
        </span>
        <button
          type="button"
          onClick={() =>
            typeof removeFunction !== 'undefined'
              ? removeFunction(index)
              : handleRemove({ index, input, value })
          }
          className="btn btn--small btn--link"
        >
          Remove
        </button>
      </div>):(<React.Fragment>{small?(<div className={menuItemClass} key={small}>
        <img
          style={{ display: 'block', margin: 'auto', paddingTop: '10%' }}
          src={small}
          alt={small}
          height="auto"
          width="100%"
        />
        <span style={{ textAlign: 'center' }} key="imageDetails">
          {small.split('/')[small.split('/').length-0]}
        </span>
        <button
          type="button"
          onClick={() =>
            typeof removeFunction !== 'undefined'
              ? removeFunction(index)
              : handleRemove({ index, input, value, props })
          }
          className="btn btn--small btn--link"
        >
          Remove
        </button>
      </div>):''}</React.Fragment>)}
    </React.Fragment>
      
    )
  })
}

const renderExistingImagePreview = (imageFile, isMenuItem) => {
  const menuItemClass = isMenuItem
    ? ''
    : 'grid--item grid__item small--one-whole medium-down--one-half medium--one-half la' +
      'rge-up--one-third'
  return imageFile.map(image => (
    <div className={menuItemClass} key={image}>
      <img
        style={{ display: 'block', margin: 'auto', paddingTop: '10%' }}
        src={image}
        alt={image}
        height="auto"
        width="100%"
      />
    </div>
  ))
}

const renderFileDropField = props => {
  const {
    input,
    label,
    multiple,
    removeFunction,
    isMenuItem,
    imageFile,
    parentComponent,
    meta: { error, touched }
  } = props
  const allowMultiple = multiple || true
  let dropzoneRef // used for clicking on button to open file dialog
  return (
    <React.Fragment>
      {isMenuItem ? null : <label>{label}</label>}
      <DropZone
        ref={node => {
          dropzoneRef = node
        }}
        accept="image/jpeg, image/png, image/gif, image/bmp"
        className="dropzone"
        activeClassName="dropzone--active"
        disableClick
        multiple={allowMultiple}
        onDrop={filesToUpload => {
          handleImageMultiUpload(filesToUpload, props)
        }}
      >
        {input.value && (input.value.length > 0 || input.value.url) ? (
          <React.Fragment>
            <div className={isMenuItem ? null : 'grid grid--uniform'}>{renderImagePreview(input, removeFunction, isMenuItem, props)}</div>

            <button type="button" className="btn" onClick={() => dropzoneRef.open()}>
              Click to Upload
            </button>
            <p className="dropzone--instructions">or Drag and Drop</p>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <button type="button" className="btn" onClick={() => dropzoneRef.open()}>
              Click to Upload
            </button>
            <p className="dropzone--instructions">or Drag and Drop</p>

            {isMenuItem ? <label className="pull-right">{label}</label> : null}
          </React.Fragment>
        )}
      </DropZone>
      {touched && error && <span className="form-error">{error}</span>}
    </React.Fragment>
  )
}
renderFileDropField.defaultProps = {
  label: '',
  multiple: true
}
renderFileDropField.propTypes = {
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({}).isRequired,
  multiple: PropTypes.bool
  //removeFunction: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(userActionCreators, dispatch)
})

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  currentUser: state.reduxTokenAuth.currentUser,
  stepForm: state.stepForm.hostRegistration,
  form: state.form
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(renderFileDropField)
