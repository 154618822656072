import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const adaptFileEventToValue = (
  delegate,
  updateImageDisplay,
  singleImageUpload
) => async e => {
  const file = e.target.files[0]
  const image = await singleImageUpload(file)
  delegate(image)
  updateImageDisplay()
}


const renderFileField = ({
  input: { value: omitValue, onChange, onBlur, ...inputProps },
  // meta: omitMeta,
  meta: { touched, error },
  updateImageDisplay,
  singleImageUpload,
  ...props
}) => {
  let errored = touched && error
  return (
    <Fragment>
      <input
        onChange={adaptFileEventToValue(
          onChange,
          updateImageDisplay,
          singleImageUpload
        )}
        type="file"
        {...props.input}
        {...props}
      />

      {errored && <div className="form-error form-error-div">{error}</div>}
    </Fragment>

  )

}



renderFileField.defaultProps = {
  field: {
    name: '',
    type: '',
    autoComplete: '',
    placeholder: ''
  }
}

renderFileField.propTypes = {
  updateImageDisplay: PropTypes.func.isRequired,
  singleImageUpload: PropTypes.func.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    autoComplete: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool
  }),
  input: PropTypes.shape({}).isRequired
}

export default renderFileField
