import qs from 'qs'
import api from '../../utils/api'
import { getUserAttributesFromResponse } from '../../utils/auth-tools'

import { FETCH_CURRENT_USER_START } from '../../constants/actionTypes'

import { camelToSnake } from '../../utils/camelSnake'

import { setUserAttributes } from './update'

/* eslint-disable-next-line no-unused-vars */
import { normalizeProfile } from '../../utils/normalization'

// import alertModal from '../../utils/alert';
export function getUserStarted() {
  return {
    type: FETCH_CURRENT_USER_START
  }
}

function getUser(userId) {
  // console.log('HOST ID ', hostId);
  /* eslint-disable-next-line no-unused-vars */
  return async (dispatch, getState) => {
    dispatch(getUserStarted())
    try {
      const response = await api.get(`/users/${userId}`)
      const userAttributes = getUserAttributesFromResponse(response)
      dispatch(setUserAttributes(userAttributes))
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
      throw err
    }
  }
}

export { getUser }
