const trackTransaction = ({ transactionId, transactionTotal, transactionTax, transactionShipping }) => {
  window.dataLayer.push({
    event: 'purchase',
    transactionId,
    transactionTotal,
    transactionTax,
    transactionShipping
  })
}

const trackSubscription = ({ email }) => {
  window.dataLayer.push({
    event: 'subscribe',
    email
  })
}

const trackSearchExperiences = ({ cuisine, city, startDate, endDate, page }) => {
  window.dataLayer.push({
    event: 'search',
    selected_type: cuisine,
    selected_city: city,
    start_date: startDate,
    end_date: endDate,
    page_number: page
  })
}

const trackCTAClick = ({ buttonCTA }) => {
  if (buttonCTA === 'Explore Experiences') {
    window.dataLayer.push({
      event: 'click_explore_experiences'
    })
  }

  if (buttonCTA === 'Explore all Experiences') {
    window.dataLayer.push({
      event: 'click_explore_all_experiences'
    })
  }

  if (buttonCTA === 'Browse All Hosts') {
    window.dataLayer.push({
      event: 'click_browse_all_hosts'
    })
  }
}

const trackSignIn = () => {
  window.dataLayer.push({
    event: 'sign_in'
  })
}

const trackSignUp = ({ email }) => {
  window.dataLayer.push({
    event: 'sign_up',
    email
  })
}

const trackGetStartedHostApplication = ({ status }) => {
  if (status === 'not_user') {
    window.dataLayer.push({
      event: 'click_get_started_host_no_user'
    })
  }
  if (status === 'user') {
    window.dataLayer.push({
      event: 'click_get_started_host'
    })
  }
}

const trackHostApplication = ({ stepNumber }) => {
  let stepName
  switch (stepNumber) {
  case 1:
    stepName = 'Sign Up'
    break
  case 2:
    stepName = 'Verify Phone Number'
    break
  case 3:
    stepName = 'Upload Profile Picture'
    break
  case 4:
    stepName = 'Personal Info'
    break
  case 5:
    stepName = 'Professional Info'
    break
  case 6:
    stepName = 'Hosting Space'
    break
  case 7:
    stepName = 'Profile Photos'
    break
  case 8:
    stepName = 'Create A Sample Menu'
    break
  default:
    stepName = 'Not Defined'
  }

  window.dataLayer.push({
    event: 'become_host',
    step: stepName,
    step_number: stepNumber
  })
}

const trackSelectExperienceDate = () => {
  window.dataLayer.push({
    event: 'select_experience_date'
  })
}

const trackSelectExperienceSeats = ({ seats }) => {
  window.dataLayer.push({
    event: 'select_experience_seats',
    seats
  })
}

const trackBookNowButton = () => {
  window.dataLayer.push({
    event: 'click_book_now'
  })
}

const trackCompleteCheckout = () => {
  window.dataLayer.push({
    event: 'complete_checkout'
  })
}

module.exports = {
  trackTransaction,
  trackSubscription,
  trackSearchExperiences,
  trackCTAClick,
  trackSignIn,
  trackSignUp,
  trackGetStartedHostApplication,
  trackHostApplication,
  trackSelectExperienceDate,
  trackSelectExperienceSeats,
  trackBookNowButton,
  trackCompleteCheckout
}
