import React from 'react'
import { connect } from 'react-redux'
import { showCreateExperienceModal } from '../../../actions/modals'

const CreateExperienceLink = ({ className, children, showModal, item }) => (
  <span
    role="button"
    tabIndex={0}
    className={`${className}`}
    onKeyDown={() => showModal({ show: true, item })}
    onClick={() => showModal({ show: true, item })}
  >
    {children}
  </span>
)

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = dispatch => ({
  showModal: async state => {
    dispatch(showCreateExperienceModal(state))
  }
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateExperienceLink)
