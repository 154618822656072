const Util = require('util')

// snakelize a string formed in underscore
function snakelize(key) {
  const separator = '_'
  const split = /(?=[A-Z])/

  return key
    .split(split)
    .join(separator)
    .toLowerCase()
}

// camelize a string formed in underscore
function camelize(key) {
  if (Util.isNumber(key)) {
    return key
  }
  const newKey = key.replace(
    /[_\s]+(.)?/g,
    (match, ch) => (ch ? ch.toUpperCase() : '')
  )
  // Ensure 1st char is always lowercase
  return newKey.substr(0, 1).toLowerCase() + newKey.substr(1)
}

// camelize/snakelize keys of an object
// @param {number} depth to which level of keys should it process
function processKeys(obj, processer, depth) {
  if (depth === 0 || !Util.isObject(obj)) {
    return obj
  }
  // Check to see is object is array and process accordingly
  if (Array.isArray(obj)) {
    return obj.map(item => processKeys(item, processer, depth - 1))
  }
  const result = {}
  const keys = Object.keys(obj)

  for (let i = 0; i < keys.length; i += 1) {
    result[processer(keys[i])] = processKeys(
      obj[keys[i]],
      processer,
      depth - 1
    )
  }

  return result
}

/**
 * @param {Object|String} data string or keys of object are named in form of snake
 * @param {number} depth to which level of keys should it process
 * @return {Object|String} string or keys of object are named in form of camel case
 */
exports.snakeToCamel = (data, depth) => {
  let newDepth = depth
  if (Util.isObject(data)) {
    if (typeof newDepth === 'undefined') {
      newDepth = 1
    }
    return processKeys(data, camelize, newDepth)
  }
  return camelize(data)
}

/**
 * @param {Object|String} data string or keys of object are named in form of camel case
 * @param {number} depth to which level of keys should it process
 * @return {Object|String} string or keys of object are named in form of snake
 */
exports.camelToSnake = (data, depth) => {
  let newDepth = depth
  if (Util.isObject(data)) {
    if (typeof newDepth === 'undefined') {
      newDepth = 1
    }
    return processKeys(data, snakelize, newDepth)
  }
  return snakelize(data)
}
